import { useLocation, useNavigate } from "react-router-dom";
import { getQuery, useQuery } from "../../../DecisionGraph/Utilities/URLQueryParams";
import { useIsSmallScreen } from "./IsSmallScreen";
import { useContext, useRef } from "react";
import { PinnedJSONFormsContext, SelectedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { SELECTED_TAB_IS_NOTE, SELECTED_TAB_PARAMETER } from "./NotePage";
import { useBulkUploadMenuItem } from "../../../DecisionGraph/ActionComponents/BulkUpload";
import { useNewNoteAction } from "../../../DecisionGraph/ActionComponents/NewNoteAction";
import { CloseOutlined, ClusterOutlined, DeleteOutlined, FileTextOutlined, FolderOutlined, OrderedListOutlined, PlusSquareOutlined, PushpinFilled, QuestionCircleOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import { useDeleteSelectedNotesAction } from "../../../DecisionGraph/ActionComponents/DeleteNoteAction";
import { useSoundTrackInDrawer } from "../../../DecisionGraph/Utilities/Sound/MusicAndSpeechDrawer";
import { HELP_URL } from "../../../DecisionGraph/ActionComponents/OpenHelpAction";
import Sider from "antd/es/layout/Sider";
import { Avatar, Image, Menu, Tooltip } from "antd";
import { getAuth } from "firebase/auth";
import TagTree from "../../NotesTree/TagTree";
import SearchSidebar from "../../../DecisionGraph/Sidebars/SearchSidebar";
import { ReplicaVoiceSidebar } from "../../../DecisionGraph/Utilities/Sound/ReplicaVoiceSidebarAndModal";
import { DraftJSOutline } from "../DraftJSEditor/DraftJSPluginsEditor/DraftJSOutlineSidebar";

export const SIDEBAR_DISPLAYED_PARAMETER="sidebarDisplayed";
export const SIDEBAR_DISPLAYED_VOICES="Voices";

// Button menu keys
const MENU_KEY_NEW_NOTE = "newNote";
const MENU_KEY_DELETE_NOTE = "deleteNote";

// Sidebar menu keys
const MENU_KEY_LIST_OF_NOTES = "listOfNotes";
const MENU_KEY_SEARCH = "search";
const MENU_KEY_VOICES = "listOfVoices";
const MENU_KEY_OUTLINE = "outline";

const INCLUDE_RELATED_SIDEBAR=false;

export const SIDER_FONT_COLOR = "gray";

export function siderItemPaddingLeft(siderCollapsed:boolean) {
    return siderCollapsed?"10px":"24px";
}

export function useSidebar() {
    const isSmallScreen = useIsSmallScreen();
    const query = useQuery();

    const navigate = useNavigate();
    const location = useLocation();
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const doc_id = selectedJsonFormsContext.note?.id;
    const pinnedJsonFormsContext = useContext(PinnedJSONFormsContext);

  
    // Visibility of panels from query:
    const sidebarDisplayed = query.get(SIDEBAR_DISPLAYED_PARAMETER);
    const listOfNotesVisible = !sidebarDisplayed || sidebarDisplayed==="Notes";
    const searchVisible = sidebarDisplayed==="Search";
    const listOfVoicesVisible = sidebarDisplayed===SIDEBAR_DISPLAYED_VOICES;
    function setListOfNotesVisible() {
      const freshQuery = getQuery();
      freshQuery.set(SIDEBAR_DISPLAYED_PARAMETER,"Notes");
      navigate(location.pathname+"?"+freshQuery.toString(), {replace:true});
    }
    function setSearchVisible() {
      const freshQuery = getQuery();
      freshQuery.set(SIDEBAR_DISPLAYED_PARAMETER,"Search");
      navigate(location.pathname+"?"+freshQuery.toString(), {replace:true});
      setTimeout(()=>{
        searchRef?.current?.focus();
      },200);
    }
    function setListOfVoicesVisible() {
      const freshQuery = getQuery();
      freshQuery.set(SIDEBAR_DISPLAYED_PARAMETER,SIDEBAR_DISPLAYED_VOICES);
      navigate(location.pathname+"?"+freshQuery.toString(), {replace:true});
    }
    function setNoSidebarVisible() {
      const freshQuery = getQuery();
      freshQuery.set(SIDEBAR_DISPLAYED_PARAMETER,"None");
      navigate(location.pathname+"?"+freshQuery.toString(), {replace:true});
    }
    function setOutlineVisible() {
      const freshQuery = getQuery();
      freshQuery.set(SIDEBAR_DISPLAYED_PARAMETER,MENU_KEY_OUTLINE);
      navigate(location.pathname+"?"+freshQuery.toString(), {replace:true});
    }
  
    const siderCollapsedFromQueryStr = query.get("siderCollapsed");
    const isSiderCollapsed = siderCollapsedFromQueryStr===null?false:siderCollapsedFromQueryStr==="true";
    function setSiderCollapsed(value:boolean) {
      const freshQuery = getQuery();
      freshQuery.set("siderCollapsed",value.toString());
      navigate(location.pathname+"?"+freshQuery.toString(),{replace:true}); // changing sidebar visibility does not count as affecting the back button.
    }
    function toggleSiderCollapsed() {
      setSiderCollapsed(!isSiderCollapsed);
    }
    const selectedTabFromQuery = query.get(SELECTED_TAB_PARAMETER);
    let selectedTab = selectedTabFromQuery || "";
    
    const searchRef = useRef<HTMLInputElement>();
    function focusSearch() {
      // Set focus to the search box:
      // console.log("NotePage.focusSearch() called. searchVisible=",searchVisible);
      if (searchVisible) {
        // Just focus it:
        setTimeout(()=>{
          searchRef?.current?.focus();
        },200);
        // setNoSidebarVisible();
        return;
        // console.error("This is not implemented... We don't know how to focus the search bar if it's already visible.");
      }
      setSearchVisible();
    }
  
    const isOutlinableContent = selectedTab===SELECTED_TAB_IS_NOTE;
    const outlineVisible = sidebarDisplayed===MENU_KEY_OUTLINE; // when the tab is not the note, we can't show the outline. But, auto-hiding it makes the screen jitter. So we won't auto-hide it after the user has clicked, it'll just be blank. 
    
    const siderTopMenuItems = [];
  
    const {bulkUploadMenuItem, bulkUploadComponent} = useBulkUploadMenuItem();
    {
      const noteMenuItems = [];
      const onNewNoteClicked = useNewNoteAction(doc_id);
      noteMenuItems.push({key:MENU_KEY_NEW_NOTE,label:"New Note",icon:<PlusSquareOutlined />, onClick:()=>onNewNoteClicked()});
      noteMenuItems.push({key:MENU_KEY_LIST_OF_NOTES,label:"List",icon:<FolderOutlined />});
      noteMenuItems.push({key:MENU_KEY_SEARCH,label:"Search",icon:<SearchOutlined />});
      const deleteSelectedNotesAction = useDeleteSelectedNotesAction();
      noteMenuItems.push(bulkUploadMenuItem);
  
      const selectedNoteIDs:string[] = query.getAll("notes");
      const oneOrManyNotesAreSelected = doc_id || selectedNoteIDs.length>0;
      if (oneOrManyNotesAreSelected) {
        // TODO this looks exactly the same as the example on https://ant.design/components/menu#examples -- why doesn't it work? There have been lots of changes since version 5.14.0 so we should try again after an upgrade.
        // I'm finding it doesn't work here nor even in the top menu so it could be due to an old version of ant design
        // noteMenuItems.push({type:"divider"});
        // TODO pick a standard and be consistent: decide whether to hide irrelevant menu items or to disable them
        if (doc_id) {
          noteMenuItems.push({key:MENU_KEY_OUTLINE,label:"Outline",icon:<OrderedListOutlined />, disabled:!isOutlinableContent})
        }
        // Delete can operate on one or manay notes:
        noteMenuItems.push({key:MENU_KEY_DELETE_NOTE,label:"Delete",icon:<DeleteOutlined />, onClick:()=>deleteSelectedNotesAction()});
      }

      siderTopMenuItems.push({key:"notesSubMenu",label:"Notes",icon:<FileTextOutlined />, children:noteMenuItems});
    }
    const {soundtrackMenuItem, soundtrackComponent} = useSoundTrackInDrawer();
    siderTopMenuItems.push(soundtrackMenuItem);
  
    if (INCLUDE_RELATED_SIDEBAR)
      siderTopMenuItems.push({key:"showRelated",label:pinnedJsonFormsContext.hasNote?(pinnedJsonFormsContext.note?pinnedJsonFormsContext.note.doc_name:"Pinned"):"Related",icon:pinnedJsonFormsContext.hasNote?<PushpinFilled/>:<ClusterOutlined />, disabled:false});
    siderTopMenuItems.push({key:MENU_KEY_VOICES,label:"Voices",icon:<UserOutlined />, disabled:false});
  
    if (isSmallScreen) {
      // TODO this is a bit of a hack, we need it only because we can't always see the hide button -- e.g. when sidebar is showing
      siderTopMenuItems.push({key:"hideSidebar",label:"Close",icon:<CloseOutlined />, disabled:false, onClick:()=>setSiderCollapsed(true)});
    }
  
    const siderBottomMenuItems = [];
    siderBottomMenuItems.push({key:"help",label:"Help",icon:<QuestionCircleOutlined />, disabled:false, onClick:()=>window.open(HELP_URL,"_blank")});
  
  
    let siderMenuItemSelectedKey = "";
    if (listOfNotesVisible)
      siderMenuItemSelectedKey=MENU_KEY_LIST_OF_NOTES;
    else if (searchVisible)
      siderMenuItemSelectedKey=MENU_KEY_SEARCH;
    else if (listOfVoicesVisible)
      siderMenuItemSelectedKey=MENU_KEY_VOICES;
    else if (outlineVisible)
      siderMenuItemSelectedKey=MENU_KEY_OUTLINE;
  
    function onSiderMenuItemClick({key}:{key:string}) {
      // console.log('click ', e);
      // On a mobile device, we also hide the sider menu as soon as the user makes any selection.
      if (isSmallScreen)
        setSiderCollapsed(true);
  
      // If the user clicks a sidebar that's already visible, we'll hide it.
      if (key===MENU_KEY_LIST_OF_NOTES) {
        if (listOfNotesVisible)
          setNoSidebarVisible();
        else
          setListOfNotesVisible();
      } else if (key===MENU_KEY_SEARCH) {
        if (searchVisible)
          setNoSidebarVisible();
        else
          setSearchVisible();
      } else if (key===MENU_KEY_VOICES) {
        if (listOfVoicesVisible)
          setNoSidebarVisible();
        else
          setListOfVoicesVisible();
      } else if (key===MENU_KEY_OUTLINE) {
        if (outlineVisible)
          setNoSidebarVisible();
        else
          setOutlineVisible();
      }
    };

    const auth = getAuth();
    const photoURL = auth.currentUser?.photoURL;

    const siderComponent =
    <Sider collapsible={true} className="noprint" key={"siderComponent"}
        /* On narrow layout mobile screens, we'd like to make the collapsedWidth 0 to take up even less horizontal space.
        This triggers on mobile & tablet only, when in portrait layout.*/
        collapsedWidth={isSmallScreen?0:undefined}
        collapsed={isSiderCollapsed}
        // Once we put the zero width trigger in a better place, we'll turn this on to hide the trigger.
        zeroWidthTriggerStyle={isSmallScreen?{display:"none"}:{}} // This hides the trigger button that would otherwise be visible when the sidebar is collapsed.
        onCollapse={(collapsed:boolean, type) => {
        setSiderCollapsed(collapsed);
        }}>
        {!isSmallScreen && !isSiderCollapsed && <h1 style={{color:SIDER_FONT_COLOR,paddingLeft:siderItemPaddingLeft(isSiderCollapsed)}}>AI Got This</h1>}
        <Menu theme="dark" mode="inline" items={siderTopMenuItems} onClick={onSiderMenuItemClick} selectedKeys={[siderMenuItemSelectedKey]}/>
        {/* This flexible space makes anything below show at the bottom: */}
        <div
            key="bottom-spacer"
            style={{ marginTop: 'auto', display: 'hidden' }}
        />
        <Menu theme="dark" mode="inline" items={siderBottomMenuItems} onClick={onSiderMenuItemClick} selectedKeys={[siderMenuItemSelectedKey]}/>
        <Tooltip title={auth.currentUser?.email+"  "+auth.currentUser?.uid} key="tooltip">
        <div style={{width:"100%", alignItems:"center", color:"gray",paddingLeft:isSiderCollapsed?"20px":"24px",paddingRight:isSiderCollapsed?"0px":"24px",display:"flex"}}>
            <Avatar
            src={photoURL && <Image src={photoURL}/>}
            key="avatarInHeader"
            />
            {!isSiderCollapsed && <span style={{paddingLeft:"24px",color:"gray", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
            {auth.currentUser?.email}
            </span>}
        </div>
        </Tooltip>
    </Sider>;

    let sidebarComponent;
    if (listOfNotesVisible)
        sidebarComponent = <TagTree key="sidebarComponent"/>;
    else if (searchVisible)
        sidebarComponent = <SearchSidebar searchRef={searchRef} key="sidebarComponent"/>;
    else if (listOfVoicesVisible)
        sidebarComponent = <ReplicaVoiceSidebar key="sidebarComponent"/>;
    else if (outlineVisible)
        sidebarComponent = selectedJsonFormsContext.noteEditorRef.current?.editorState && selectedJsonFormsContext.noteEditorRef.current?.setEditorState?
        <DraftJSOutline editorState={selectedJsonFormsContext.noteEditorRef.current?.editorState} setEditorState={selectedJsonFormsContext.noteEditorRef.current?.setEditorState} key="sidebarComponent"/>:"";

    
    const siderComponents = [siderComponent];
    if (!siderComponent.key) {
      console.error("The siderComponent must have a key.");
    }
    if (bulkUploadComponent) {
      if (!bulkUploadComponent.key) {
        console.error("The bulkUploadComponent must have a key.");
      }
      siderComponents.push(bulkUploadComponent);
    }
    if (soundtrackComponent) {
      if (!soundtrackComponent.key) {
        console.error("The soundtrackComponent must have a key.");
      }
      siderComponents.push(soundtrackComponent);
    }
    return {siderComponents, sidebarComponent, focusSearch, setListOfNotesVisible, setOutlineVisible, setNoSidebarVisible, toggleSiderCollapsed};
}