import React, { useContext, useEffect, useRef, useState } from 'react';
import { Configure, Hits, Menu, useInstantSearch, useSearchBox, UseSearchBoxProps } from 'react-instantsearch';
import { useLinkToNoteId } from '../Utilities/NavigateTo';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { NotesContext } from '../../Notes/Data/NotesContext';
import { getNoteNameAndEmoji_multipurpose } from '../../Notes/NotesTree/TreeUtilities/CreateTagTreeNodes';
import { NoteHierarchyContext } from '../../Notes/UIs/NoteInformationComponents/NoteHierarchyProvider';

/********
Known bugs:

When a new note is added, it takes a while before we can select it.
Does not affect the problem: Refreshing the page does not work around this.
Workaround: Open another type of modal. e.g. if you were using a full note insert, switch to a hyperlink, then back to full note, and it's visible immediately.

When filters is used
e.g. on the Battle tab in BattleNoteTypeEditor. Configurations affect all algolia panels even if it's not directly in the tree under it. Workaround is to do "{isAddEnemyModalOpen && <SearchModal ..." in the caller.

 */

const DEBUG = true; // currently debugging an issue that's either latency or a bug

interface CustomSearchBoxProps extends UseSearchBoxProps {
  searchRef: React.MutableRefObject<HTMLInputElement | undefined>
  onSubmit: (hit:any)=>void
};
type CallbackFunction = (doc_id:string,doc_name:string)=>void;

/*****
 * CustomSearchBox implements a search box with several enhancements:
 * 1. It can be auto focused on mount.
 * 2. It can auto submit when the user presses enter.
 */
function CustomSearchBox(props: CustomSearchBoxProps) {
  const { query, refine } = useSearchBox(props);
  const { status, results } = useInstantSearch();
  const [inputValue, setInputValue] = useState(query);
  const inputRef = props.searchRef;

  const isSearchStalled = status === 'stalled';

  function setQuery(newQuery: string) {
    setInputValue(newQuery);

    refine(newQuery);
  }

  return (
    <div>
      <form
        action=""
        role="search"
        noValidate
        onSubmit={(event) => {
          DEBUG && console.log("[AlgoliaSearchUI] CustomSearchBox onSubmit:");
          if (results.hits.length===0) {
            DEBUG && console.log("[AlgoliaSearchUI] CustomSearchBox onSubmit: No results, so not submitting.");
            return; // don't do anything if there's no results.
          }
          if (!props.onSubmit) {
            DEBUG && console.log("[AlgoliaSearchUI] CustomSearchBox onSubmit: No onSubmit function, so not submitting.");
            return; // don't do anything if there's no onSubmit function.
          }
          DEBUG && console.log("[AlgoliaSearchUI] CustomSearchBox onSubmit: Submitting first result.");
          props.onSubmit(results.hits[0]); // submit the first result.
          // Clear the search box:
          setQuery('');

          event.preventDefault();
          event.stopPropagation();
          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        onReset={(event) => {
          event.preventDefault();
          event.stopPropagation();

          setQuery('');

          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <input
          className="searchInputBox"
          // @ts-ignore
          ref={inputRef}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder="Search for notes"
          spellCheck={false}
          maxLength={512}
          type="search"
          value={inputValue}
          onChange={(event) => {
            setQuery(event.currentTarget.value);
          }}
          autoFocus
        />
        {/*We auto search, so no point in showing this: <button type="submit"><SearchOutlined/></button> */}
        <button type="reset" hidden={inputValue.length === 0 || isSearchStalled}><CloseOutlined /></button>
        <span hidden={!isSearchStalled}>Searching…</span>
      </form>
    </div>
  );
}

export function SearchNotesResult_PrintALink({ hit: noteSearchResult }:{hit:any}) {
    const linkToNote = useLinkToNoteId();
    return (
      <article>
        <p>{linkToNote(noteSearchResult.objectID,noteSearchResult.doc_name)}</p>
      </article>
    );
}

// export function SearchNotesResult_InsertLinkIntoEditor({ hit: noteSearchResult }:{hit:any}) {
//     const linkToNote = useLinkToNoteId();
//     return (
//       <article>
//         <p>{linkToNote(noteSearchResult.objectID,noteSearchResult.doc_name)}</p>
//       </article>
//     );
// }
function useSearchNotesResult_InsertLinkIntoEditor(callback:CallbackFunction) {
  const notesContext = useContext(NotesContext);    
  const {extensions} = useContext(NoteHierarchyContext);
  async function onClick(hit:any) {
    // BUG this doesn't seem to be getting triggered quite as often as we think it should. Or, loading the note is slow?
    DEBUG && console.log("[AlgoliaSearchUIs] > useSearchNotesResult_InsertLinkIntoEditor > onClick: starting to load note ",hit);
    const note = await notesContext.getNote(hit.objectID);
    DEBUG && console.log("[AlgoliaSearchUIs] > useSearchNotesResult_InsertLinkIntoEditor > onClick: note loaded: ",note);
    if (!note) {
      console.error("[useSearchNotesResult_InsertLinkIntoEditor] Note not loaded from ID that was in Algolia: ",hit.objectID);
      callback(hit.objectID,hit.doc_name);
    } else {
      const {note_title} = getNoteNameAndEmoji_multipurpose(note, notesContext, extensions);
      callback(note.id,note_title);
    }
  }

  return (props:any) => {
      return (
          <article>
              <p><Button type="dashed" onClick={()=>onClick(props.hit)}>{props.hit.doc_name}</Button></p>
          </article>
      );
  }
}

export default function SearchPanel({searchRef, hitComponent, callback}:{searchRef:React.MutableRefObject<HTMLInputElement | undefined>, hitComponent:any, callback:CallbackFunction}) {
    // Also see NotePageContext, which has <InstantSearch/>. This builds on <InstantSearch/>.

    function onSubmit(hit:any) {
      callback(hit.objectID,hit.doc_name);
    }

    return <div className="RelatedNotes" style={{padding: "10px"}}>
            {/* Someday we'd like to search other things, not just notes, in this same panel, but until then, make it clear what we can search. (no voices, no music) */}
            <h3>Search Notes</h3>
            <CustomSearchBox searchRef={searchRef} onSubmit={onSubmit}/>
            <Menu attribute="type" />
            <Hits hitComponent={hitComponent} style={{overflowY:"auto", height:"65vh"}}/>
        </div>;
}

export function SearchModal({isSearchModalOpen, setSearchModalOpen, callback,filters,title="Hyperlink to a note"}:{isSearchModalOpen:boolean, setSearchModalOpen:React.Dispatch<React.SetStateAction<boolean>>, callback:CallbackFunction,filters?:string,title?:string}) {
    const searchRef = useRef<HTMLInputElement>();
    const searchNotesResult = useSearchNotesResult_InsertLinkIntoEditor(callback);

    // Each time we switch to isSearchModalOpen=true, we want to focus the search box.
    function focusSearch() {
        searchRef?.current?.focus();
    }
    useEffect(()=>{
      if (isSearchModalOpen) {
        // console.log("[AlgoliaSearchUI SearchModal] Initial load, focusing search box");
        setTimeout(focusSearch, 50);
      }
    },[isSearchModalOpen]);

    return <div key="searchModal">
      {/* @ts-ignore the "filters" parameter functions perfectly and even shows up as the proper way in the doc at https://www.algolia.com/doc/api-reference/widgets/configure/react/#widget-param-searchparameters */}
      <Configure filters={filters} key="searchModelConfigure"/>
      <Modal
            title={title}
            open={isSearchModalOpen}
            onOk={()=>setSearchModalOpen(false)}
            onCancel={()=>setSearchModalOpen(false)}
            width="100vw"
            style={{ top: 20}}
            maskClosable={false}
            footer={[
                <Button key="back" onClick={()=>setSearchModalOpen(false)}>Cancel</Button>
                // <Button key="submit" type="primary" onClick={insertLink} disabled={!isNewBattleInputValid()}>New</Button>,
            ]}
            key="searchModelModal"
        >
            <SearchPanel searchRef={searchRef} hitComponent={searchNotesResult} callback={callback}/>
        </Modal>
    </div>;
}