import { useEffect, useState } from 'react'
import { doc, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { FIREBASE_FIRESTORE } from '../../AppBase/App';

export const TagTreeState_doc_name = "TagTreeState";

// When loading is not complete, currentUser is null, which wreaks havoc with hooks.
// This is complicated: https://github.com/FirebaseExtended/reactfire/issues/423
// export function useUserTagTreeStateDocRef() {
//   //@ts-ignore it's never null because of FirestoreLogin wrapper.
//   const uid = getAuth().currentUser.uid;
//   const tagTreeStateRef = doc(FIREBASE_FIRESTORE, "ByUserData", uid, "UIState", TagTreeState_doc_name);
//   return tagTreeStateRef;  
// }

// export function useUserTagTreeState():[TagTreeState|undefined,{saveTagTreeState:(doc_data: any) => Promise<void>}] {
//   const tagTreeStateRef = useUserTagTreeStateDocRef();
//   const [tagTreeState, setTagTreeState] = useState<TagTreeState | undefined>(undefined);

//   // Create handlers
//   async function saveTagTreeState(doc_data:any) {
//     if (!tagTreeStateRef)
//       return; // should not be reachable.
//     const fullDocToSave = {doc_data};
//     await setDoc(tagTreeStateRef, fullDocToSave);
//   }
//   const handlers = {saveTagTreeState:useCallback(saveTagTreeState, [tagTreeStateRef])};

//   useEffect(() => {
//     async function fetchTagTreeState() {
//       const tagTreeStateDoc = await getDoc(tagTreeStateRef);
//       setTagTreeState(tagTreeStateDoc.exists() ? tagTreeStateDoc.data() as TagTreeState : undefined);
//     }
//     fetchTagTreeState();
//   }, [tagTreeStateRef]);

//   // return array of [data, handlers] to match hooks like useState
//   return [tagTreeState, handlers];
// }

const DEBUG_USE_USERDATA = false;
export function useUserData() {
  const [userData, setUserData] = useState<{isLoading: boolean, hasAuthenticationId: boolean, internetIsDown: boolean, isMember: boolean}>({isLoading: true, hasAuthenticationId: false, internetIsDown: false, isMember: false});
  useEffect(() => {
    let unsubscribeSnapshotOuter = undefined as undefined | (()=>void);
    const unsubscribeAuthState = getAuth().onAuthStateChanged(function authStateChanged(user) {
      if (DEBUG_USE_USERDATA) console.log("[useUserData] > onAuthStateChanged", user);
      if (!user) {
        if (DEBUG_USE_USERDATA) console.log("[useUserData] > authentication info has loaded, but we don't have a user yet. This is a signal to ask the user to log in.");
        setUserData({isLoading: false, hasAuthenticationId: false, internetIsDown: false, isMember: false});
        return;
      }
      const uid = user?.uid;
      if (DEBUG_USE_USERDATA) console.log("[useUserData] > got authentication info (user is logged in)", uid);
      const ref = doc(FIREBASE_FIRESTORE, "ByUserData", uid);
      if (DEBUG_USE_USERDATA) console.log("[useUserData] > starting to load user info", ref);

      if (unsubscribeSnapshotOuter)
        unsubscribeSnapshotOuter();
      unsubscribeSnapshotOuter = onSnapshot(ref, (userDoc) => {
        if (!userDoc.exists()) {
          console.error("[useUserData] > userInfo came back as not existing. The internet may be down causing false negatives here.", userDoc);
          setUserData({isLoading: false, hasAuthenticationId:true, internetIsDown: true, isMember: false});
          return;
        }
        const userInfo = userDoc.data();
        if (DEBUG_USE_USERDATA) console.log("[useUserData] > got user info", userInfo);
        setUserData({isLoading: false, hasAuthenticationId:true, internetIsDown: false, isMember: userInfo?.member});
      }, (error) => {
        console.error("[useUserData] > error getting user info, assumed due to internet being down. ", error);
        setUserData({isLoading: false, hasAuthenticationId:true, internetIsDown: true, isMember: false});
      });

      // return () => unsubscribeSnapshot();
    });

    return () => unsubscribeAuthState();
  }, []);
  return userData;
}