import React from 'react';
import SearchPanel, { SearchNotesResult_PrintALink } from '../AlgoliaSearch/AlgoliaSearchUIs';
import { useNavigateToNote } from '../Utilities/NavigateTo';

export default function SearchSidebar({searchRef}:{searchRef:React.MutableRefObject<HTMLInputElement | undefined>}) {
  const navigateToNote = useNavigateToNote();
  function callback(noteId:string,noteTitle:string) {
    navigateToNote(noteId);
  }

  return <SearchPanel searchRef={searchRef} hitComponent={SearchNotesResult_PrintALink} callback={callback}/>;

}