import { ContentState, EditorState, KeyBindingUtil, convertFromRaw, convertToRaw } from "draft-js"
import { useMemo, useState } from "react";
import Editor from '@draft-js-plugins/editor';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import "../Notes/UIs/DraftJSEditor/DraftJSPluginsEditor/editorStyles.css";
import "../Notes/UIs/DraftJSEditor/WYSIWYGEditor.css";
import { InstantSearch } from "react-instantsearch";
import { AlgoliaSearchClient } from "../DecisionGraph/AlgoliaSearch/AlgoliaSearchClient";
import { useStateLSBoolean } from "../DecisionGraph/Utilities/StateWithLocalCache";
import { useBasicHandleKeyCommand, useBasicKeyBindingFn } from "../Notes/UIs/DraftJSEditor/DraftKeyboardBindings";
import { BLOCK_RENDER_MAP, blockStyleFn, useBlockRendererFn } from "../Notes/UIs/DraftJSEditor/BlockEditing/BlockRendererAndStyleFns";
import { useDraftExtensions } from "../Notes/UIs/DraftJSEditor/DraftJSPluginsEditor/DraftJsToolbarAndPlugins";
import OneLineDraftEditor from "../DecisionGraph/ChatEditor/UI/OneLineDraftJS";


const DEFAULT_DRAFT_DOCUMENT = {
  "blocks": [
      {
          "key": "cjp98",
          "text": "placeholder text for a few lines of text lorem ipsom dolor est placeholder text for a few lines of text lorem ipsom dolor est placeholder text for a few lines of text lorem ipsom dolor est placeholder text for a few lines of text lorem ipsom dolor est placeholder text for a few lines of text lorem ipsom dolor est",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
      },
  ],
  "entityMap": {}
};


export default function NoToolbarDraftJSPluginsTestPage() {
    
    return <InstantSearch searchClient={AlgoliaSearchClient} indexName="AIGotThisNotes" future={{preserveSharedStateOnUnmount: true}} routing={true} >
         <OneLineDraftEditor initialContent={convertFromRaw(DEFAULT_DRAFT_DOCUMENT)} onSubmit={(contentState:ContentState)=>{alert("it's submitted!")}} autoFocus={true}/>
    </InstantSearch>;
}

// export default function NoToolbarDraftJSPluginsTestPage() {
//     const [editorState, setEditorState] = useState(() => EditorState.createWithContent(convertFromRaw(DEFAULT_DRAFT_DOCUMENT)));
//     const [editable,setEditable] = useStateLSBoolean("draftEditor_isEditable",true);

//     const basicKeyBindingFn = useBasicKeyBindingFn(editorState);
//     const basicHandleKeyCommand = useBasicHandleKeyCommand(editorState, setEditorState);
//     const blockRenderFn = useBlockRendererFn(setEditorState, editorState, editable);

//     const {headerComponents, plugins, extendedHandleKeyCommand} = useDraftExtensions(setEditorState,editorState, editable, basicHandleKeyCommand, setEditable, undefined, false);

//     function submitOnReturnKeyBindingFn(e: React.KeyboardEvent<Element>): string | null {
//         if (!KeyBindingUtil.hasCommandModifier(e) && e.key==="Enter") {
//             e.preventDefault();
//             // Can we handle the submit here? Or do we need to build out a handleKeyCommand?
//             alert("would submit");
//             return "submit";
//         }
//         const toReturn = basicKeyBindingFn(e);
//         return toReturn;
//       }

//     // Print block map, if needed, e.g. to edit and save
//     useMemo(()=>{
//       const doc_data_to_save=convertToRaw(editorState.getCurrentContent());
//       console.log("[DraftJSPluginsTestPage] Editor state changed to ",doc_data_to_save);
//     },
//     [editorState]);

//     return <InstantSearch searchClient={AlgoliaSearchClient} indexName="AIGotThisNotes" future={{preserveSharedStateOnUnmount: true}} routing={true} >
//           {headerComponents.map((component,i)=><div key={i}>{component}</div>)}
//         <div className="editor inline-editor-main">
//         <Editor
//             editorState={editorState}
//             onChange={setEditorState}
//             blockRendererFn={blockRenderFn}
//             blockRenderMap={BLOCK_RENDER_MAP}
//             blockStyleFn={blockStyleFn}
//             plugins={plugins}

//             handleKeyCommand={extendedHandleKeyCommand}
//             keyBindingFn={submitOnReturnKeyBindingFn}
//             readOnly={!editable}
//         />
//         </div>
//         </InstantSearch>
// }