import { JSONFormsSchema } from "../../NoteType";
import { Firestore, doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";
import { saveContentBackup } from "../../NoteDBHooks";
import { SAVE_DELAY } from "../../FirestoreNoteClient";
import { debounce, set } from "lodash";
import { FIREBASE_FIRESTORE } from "../../../../AppBase/App";
import { useState, useEffect } from "react";

export function getRef_JSONFormsSchema(firestore:Firestore, note_id:string) {
    return doc(firestore, "Notes", note_id, "Content", "JSONFormsSchema");;
}

export async function getJSONFormsSchema(firestore:Firestore, note_id:string):Promise<JSONFormsSchema | undefined> {
    const ref = getRef_JSONFormsSchema(firestore, note_id);
    const objectDoc = await getDoc(ref);
    if (!objectDoc.exists()) {
      return undefined;
    } else {
      return objectDoc.data() as JSONFormsSchema;
    }
}

export function useJSONFormsSchema(note_id?:string):JSONFormsSchema | undefined {
    const [jfs, setJfs] = useState<JSONFormsSchema | undefined>(undefined);
    
    useEffect(() => {
        if (!note_id) {
            setJfs(undefined);
            return;
        }
        const ref = getRef_JSONFormsSchema(FIREBASE_FIRESTORE, note_id);
        const unsubscribe = onSnapshot(ref, (snapshot) => {
            setJfs(snapshot.exists() ? snapshot.data() as JSONFormsSchema : undefined);
        });
        return () => unsubscribe();
    }, [note_id]);

    return jfs;
}

/* For saving types, the schemas */
export function useSaveJSONFormsSchemaCallback() {
    function save(note_id:string, jsonFormsSchema:JSONFormsSchema) {
        const ref = getRef_JSONFormsSchema(FIREBASE_FIRESTORE, note_id);
        setDoc(ref, jsonFormsSchema, { merge: false });
        saveContentBackup(jsonFormsSchema, FIREBASE_FIRESTORE, note_id, "JSONFormsSchema");
    }
    // ⚠ WARNING TODO: debounce does not distinguish between different IDs, which means it's possible to lose edits from quick changes.
    // We need to fix that.
    return debounce(save, SAVE_DELAY);
}
