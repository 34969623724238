import { ReactElement, useContext, useState } from 'react';

import { JsonForms } from '@jsonforms/react';
import {
  materialRenderers,
  materialCells,
} from '@jsonforms/material-renderers';
import { ErrorBoundary } from "react-error-boundary";

import { Alert, Select, Spin, Tabs } from 'antd';

import { Note } from '../../Notes/Data/NoteType';
import { getDuplicatesErrorMessage, rebuildMatchingPropertiesNamedInSchema } from '../../JSONEditing/JSONSchemaBasedEditors/JSONSchemaHelpers';
import { RawJSONEditor } from '../../JSONEditing/RawJSONEditing/RawJSONEditor';
import { isCurrentlyLoadingAnyTypes, useContentBackup } from '../../Notes/Data/NoteDBHooks';
import { NotesContext } from '../Data/NotesContext';
import { CONTENT_TYPE_JSONFORMS_OBJECT, JSONFormsObject } from '../Data/Actions/JSONFormsObject/LoadAndSaveJSONFormsObject';
import { SelectedJSONFormsContext } from '../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext';
import { NoteHierarchyContext } from '../UIs/NoteInformationComponents/NoteHierarchyProvider';
import { getNoteTypeWithSchemaFor } from '../../Extensions/ExtensionsFramework/ExtensionsSchemas';
import { JSONSchemaType } from 'ajv';

// import { rankWith, scopeEndsWith } from '@jsonforms/core';
// import RatingControl from './RatingControl';

const { Option } = Select;

// const RatingScaleJsonFormsTester = rankWith(
//   3, //default is 2, this prioritizes just slightly over the default.
//   scopeEndsWith('rating')
// );
// function GenerateTypeJsonFormsTester(type:string) {
//     return rankWith(
//         3, //default is 2, this prioritizes just slightly over the default.
//         scopeEndsWith(type)
//     );
// };

function ObjectHistoryTab({doc_id,jsonSchema}:{doc_id:string,jsonSchema?:JSONSchemaType<any>}) {
    const [selectedBackup, setSelectedBackup] = useState("");
    const backupsList = useContentBackup(doc_id,CONTENT_TYPE_JSONFORMS_OBJECT);
    
    let selectedBackupValue=null;
    if (backupsList && selectedBackup) {
        const arr = backupsList.filter(function(backup){return backup.id===selectedBackup});
        if (arr.length>0)
            selectedBackupValue=arr[0];
    }

    // Load history
    return<>
        {!backupsList && <Spin/>}
        {backupsList && backupsList.length===0 && "No backups yet -- this is the first version."}
        {backupsList && backupsList.length>0 &&
            <>Backup Date: <Select value={selectedBackup}  style={{ minWidth: '200px' }}
                onChange={function(choice:string){setSelectedBackup(choice)}}>
                {backupsList.map(function(backup:any){
                    // id will be the date, in JavaScript format
                    const date = new Date(Date.parse(backup.id));
                    return <Option value={backup.id}>{date.toLocaleString()}</Option>
                })}
            </Select></>}
        {selectedBackup && <><br/>
            <RawJSONEditor key={selectedBackup} jsonStr={JSON.stringify(selectedBackupValue)} schema={jsonSchema} isSchema={false} showSaveButton={false}/>
        </>}    
    </>
}

/*****************
 * JSONFormsEditorV2
 * 
 * Very similar to the same code that's embedded in OldGenericObjectEditor.
 * 
 * Before calling this:
 * - Make sure there is a note type with a schema.
 */
export function JSONFormsEditorV2() {
    const notesContext = useContext(NotesContext);
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);

    const {extensions, isLoaded} = useContext(NoteHierarchyContext);

    // All hooks above
    if (!isLoaded)
        return <Spin/>;

    const noteType = getNoteTypeWithSchemaFor(extensions,selectedJsonFormsContext.note);
    if (!noteType) {
        console.error("JSONFormsEditorV2: No schema found for note type. We should not be able to reach this.",selectedJsonFormsContext.note);
        debugger;
        return <>No schema found for note type. Please report this to the developers and they will work on fixing it.</>;
    }

    let errorMessages = "";
    let duplicatesMessage = "";

    const notesOfTypeMap:any = {}; // Used for tracking the IDs selected for new notes, used when saving.
    let didntFindNotesForAType = false;

    function handleMatchFunc(propertyName:string, jsonSchemaProperties:any,
        actualValue:any, path:string, parentArrayItemProperties:any | undefined): any {
        // Check to make sure it's valid.
        if (typeof(jsonSchemaProperties)==="object") {
            if (jsonSchemaProperties["type"]==="string") {
                // Good!
            } else {
                    errorMessages+="Warning: The type of property '"+propertyName+"' should be a string. We'll auto change it for you.";
                // We can handle this... But worth a warning.
                jsonSchemaProperties["type"] = "string"; // Correction in case the user entered it wrong.
            }
        } else {
            errorMessages+="Warning: Corrupted object structure. '"+propertyName+"' should be an object, not a "+typeof(jsonSchemaProperties);
            return;
        }

        // We found one! Now we set the enums.
        // Find the data:

        const notesOfType = notesContext.loadedNotes.filter(function(note:Note){return note.type===propertyName});
        notesContext.loadNotesOfType(propertyName); // This will help in a future render.
        if (notesOfType.length===0) {
            didntFindNotesForAType = true;
            return jsonSchemaProperties;
        }

        const selectableNotes = notesOfType && notesOfType.filter((note:Note)=>{
            // Just filter out the current note, we don't list ourselves.
            // (there might be some case where we need this, if that ever happens, we'll change it)
            return note.id!==note.id;
        }).map(
            function(note:Note){
                // TODO -- how can we also include the ID? Do we just assume there's only single IDs or show an error?
                return note.doc_name;
                // return note.doc_name+" ("+note.id+")";
            });
        notesOfTypeMap[propertyName]=notesOfType;
        const relatedNotes = actualValue && notesOfType && notesOfType.filter(
            function(note:Note) {
                return note.doc_name===actualValue;
            });
        if (relatedNotes) {
            // Remove the known one, we don't need to display it to the user since it's alread in a column.
            const newProperties = {...parentArrayItemProperties};
            delete newProperties[propertyName];
            // for (const relatedNote of relatedNotes) {
            //     relationships.push({type:propertyName,name:actualValue,doc_id:relatedNote.id,source:path,additionalColumns:newProperties});
            // }
        }

        // Check selectableNotes for duplicates:
        duplicatesMessage=getDuplicatesErrorMessage(selectableNotes,"For type "+propertyName,duplicatesMessage);

        if (selectableNotes.length===0) {
            // It may be 0 when loading. But this causes an error in JSON Forms, which expects to have at least 1 item in each enum.
            // So we put in a placeholder, saying "Loading..."
            // TODO show loading only if we're actually loading.
            if (isCurrentlyLoadingAnyTypes())
                jsonSchemaProperties["enum"] = ["... Loading..."];
            else
                jsonSchemaProperties["enum"] = ["No types available"];
        } else {
            const uniqueSelectableRelationships = new Set(selectableNotes);
            jsonSchemaProperties["enum"] = Array.from(uniqueSelectableRelationships).sort();
        }
        return jsonSchemaProperties;
    }
    const jsonSchema = rebuildMatchingPropertiesNamedInSchema(noteType.schema,selectedJsonFormsContext.jsonFormsObject,
    // const jsonSchema = rebuildMatchingPropertiesNamedInSchema(selectedJsonFormsContext.jsonSchema,selectedJsonFormsContext.jsonFormsObject,
        selectedJsonFormsContext.findMatchFunc_isNoteOfType,handleMatchFunc);

    if (duplicatesMessage.length>0)
        errorMessages+=duplicatesMessage+" Rename them before using.   💡 Tip: Control-E to search for the name.";

    //@ts-ignore
    function fallbackRender({ error, resetErrorBoundary }) {
        // Call resetErrorBoundary() to reset the error boundary and retry the render.
    
        return (
        <div role="alert">
            <p>There's an error with this object or schema. Please edit in the "Raw JSON" tab.</p>
            <pre style={{ color: "red" }}>{error.message}</pre>
        </div>
        );
    }

    return <>{duplicatesMessage && <Alert
        message="Warning"
        description={errorMessages}
        type="warning"
        showIcon
        closable
        />}
    {/* If there are any missing note types, we can't render the JsonForms as the schema may be invalid or will error.
    We have to wait until we get at least 1 of each type. */}
    {didntFindNotesForAType && <Spin/>}
    {!didntFindNotesForAType && <ErrorBoundary fallbackRender={fallbackRender}>
            <JsonForms
            key={selectedJsonFormsContext.note.id}
            schema={jsonSchema}
            uischema={noteType.jsonFormsUISchema}
            data={selectedJsonFormsContext.jsonFormsObject}
            renderers={materialRenderers}
            cells={materialCells}
            onChange={function onFormEdited({ data }){
                if (data) {
                    selectedJsonFormsContext.saveJSONFormsObject(data as JSONFormsObject);
                }
            }}
    /></ErrorBoundary>
    }</>;
}

export default function OldGenericObjectEditor({promptContextTab}:{promptContextTab?:ReactElement}) {
    const notesContext = useContext(NotesContext);
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const note = selectedJsonFormsContext.note;
    const doc_id = note?.id;
    const type = note?.type;
    const jsonFormsObject = selectedJsonFormsContext.jsonFormsObject;
    let jsonSchema = selectedJsonFormsContext.jsonSchema;
    const formUiSchemaObj = selectedJsonFormsContext.jsonFormsUISchema;
    const findMatchFunc_isNoteOfType = selectedJsonFormsContext.findMatchFunc_isNoteOfType;
    const isLoaded = selectedJsonFormsContext.isJSONFormsLoaded;
    const saveJSONFormsObject = selectedJsonFormsContext.saveJSONFormsObject;
    // console.log("🌟 GenericObjectEditor, on '"+note.doc_name+"', we found object",jsonFormsObject);

    // const navigateToNote = useNavigateToNote();
    
    if (!jsonSchema) {
        // TODO link to type note
        return <><br/><b>💡 Tip:</b> Go to the note "{type}" to create fields for this.</>;
    }

    // Register custom renderers in JSONForms:
    // const renderers = [
    //     ...materialRenderers,
    //     // { tester: RatingScaleJsonFormsTester, renderer: RatingControl },
    // ];
    let errorMessages = "";
    let duplicatesMessage = "";

    // const relationships:any[] = [];
    const notesOfTypeMap:any = {}; // Used for tracking the IDs selected for new notes, used when saving.
    let didntFindNotesForAType = false;

    function handleMatchFunc(propertyName:string, jsonSchemaProperties:any,
        actualValue:any, path:string, parentArrayItemProperties:any | undefined): any {
        // Check to make sure it's valid.
        if (typeof(jsonSchemaProperties)==="object") {
            if (jsonSchemaProperties["type"]==="string") {
                // Good!
            } else {
                    errorMessages+="Warning: The type of property '"+propertyName+"' should be a string. We'll auto change it for you.";
                // We can handle this... But worth a warning.
                jsonSchemaProperties["type"] = "string"; // Correction in case the user entered it wrong.
            }
        } else {
            errorMessages+="Warning: Corrupted object structure. '"+propertyName+"' should be an object, not a "+typeof(jsonSchemaProperties);
            return;
        }

        // We found one! Now we set the enums.
        // Find the data:

        const notesOfType = notesContext.loadedNotes.filter(function(note:Note){return note.type===propertyName});
        notesContext.loadNotesOfType(propertyName); // This will help in a future render.
        if (notesOfType.length===0) {
            didntFindNotesForAType = true;
            return jsonSchemaProperties;
        }

        const selectableNotes = notesOfType && notesOfType.filter((note:Note)=>{
            // Just filter out the current note, we don't list ourselves.
            // (there might be some case where we need this, if that ever happens, we'll change it)
            return note.id!==doc_id;
        }).map(
            function(note:Note){
                // TODO -- how can we also include the ID? Do we just assume there's only single IDs or show an error?
                return note.doc_name;
                // return note.doc_name+" ("+note.id+")";
            });
        notesOfTypeMap[propertyName]=notesOfType;
        const relatedNotes = actualValue && notesOfType && notesOfType.filter(
            function(note:Note) {
                return note.doc_name===actualValue;
            });
        if (relatedNotes) {
            // Remove the known one, we don't need to display it to the user since it's alread in a column.
            const newProperties = {...parentArrayItemProperties};
            delete newProperties[propertyName];
            // for (const relatedNote of relatedNotes) {
            //     relationships.push({type:propertyName,name:actualValue,doc_id:relatedNote.id,source:path,additionalColumns:newProperties});
            // }
        }

        // Check selectableNotes for duplicates:
        duplicatesMessage=getDuplicatesErrorMessage(selectableNotes,"For type "+propertyName,duplicatesMessage);

        if (selectableNotes.length===0) {
            // It may be 0 when loading. But this causes an error in JSON Forms, which expects to have at least 1 item in each enum.
            // So we put in a placeholder, saying "Loading..."
            // TODO show loading only if we're actually loading.
            if (isCurrentlyLoadingAnyTypes())
                jsonSchemaProperties["enum"] = ["... Loading..."];
            else
                jsonSchemaProperties["enum"] = ["No types available"];
        } else {
            const uniqueSelectableRelationships = new Set(selectableNotes);
            jsonSchemaProperties["enum"] = Array.from(uniqueSelectableRelationships).sort();
        }
        return jsonSchemaProperties;
    }
    jsonSchema = rebuildMatchingPropertiesNamedInSchema(jsonSchema,jsonFormsObject,
        selectedJsonFormsContext.findMatchFunc_isNoteOfType,handleMatchFunc);

    if (duplicatesMessage.length>0)
        errorMessages+=duplicatesMessage+" Rename them before using.   💡 Tip: Control-E to search for the name.";

    //@ts-ignore
    function fallbackRender({ error, resetErrorBoundary }) {
        // Call resetErrorBoundary() to reset the error boundary and retry the render.
    
        return (
        <div role="alert">
            <p>There's an error with this object or schema. Please edit in the "Raw JSON" tab.</p>
            <pre style={{ color: "red" }}>{error.message}</pre>
        </div>
        );
    }

    const tabItems = [
        {
          label: 'Edit',
          key: '1',
          children: <>{duplicatesMessage && <Alert
                message="Warning"
                description={errorMessages}
                type="warning"
                showIcon
                closable
                />}
            {/* If there are any missing note types, we can't render the JsonForms as the schema may be invalid or will error.
            We have to wait until we get at least 1 of each type. */}
            {didntFindNotesForAType && <Spin/>}
            {!didntFindNotesForAType && <ErrorBoundary fallbackRender={fallbackRender}>
                    <JsonForms
                    key={doc_id}
                    schema={jsonSchema}
                    uischema={formUiSchemaObj}
                    data={jsonFormsObject}
                    renderers={materialRenderers}
                    cells={materialCells}
                    onChange={function onFormEdited({ data }){
                        if (data) {
                            saveJSONFormsObject(data as JSONFormsObject);
                        }
                    }}
            /></ErrorBoundary>
            }</>,
        },
        // {
        //     label: 'Relationships',
        //     key: '2',
        //     children: <>        {!relationships && "This note has no relationships to anything else."}
        //     {relationships && <Table dataSource={relationships} pagination={false} columns={[
        //         {title: 'Name',dataIndex: 'name',key: 'name',sorter:function(a,b){return a.name.localeCompare(b.name);},
        //         render: (_, record) => (
        //                 <Button type="link" onClick={function(e:any){
        //                     e.preventDefault();
        //                     navigateToNote(record.doc_id);
        //                 }}>{record.name}</Button>
        //             ),},
        //         {title: 'Type',dataIndex: 'type',key: 'type',
        //             sorter:function(a,b){return a.type.localeCompare(b.type);},
        //             defaultSortOrder:"ascend"},
        //         {title: 'Where',dataIndex: 'source',key: 'source',
        //             sorter:function(a,b){return a.source.localeCompare(b.source);}},
        //         {title: 'Relationship',dataIndex: 'additionalColumns',key: 'additionalColumns',
        //             render:function(text, record, index) {
        //                 const toPrint = JSON.stringify(record.additionalColumns);
        //                 return toPrint.substring(1,toPrint.length-1);
        //             }},
                    
        //         // {title: 'Note ID',dataIndex: 'doc_id',key: 'doc_id'},
        //         ]}/>}</>
        // },
        {
            label: 'Raw JSON',
            key: '3',
            children: <>{jsonFormsObject && 
                <RawJSONEditor jsonStr={JSON.stringify(jsonFormsObject)} schema={jsonSchema} isSchema={false}
                    showSaveButton={true} 
                    saveFunc={(jsonObject:object)=>saveJSONFormsObject(jsonObject as JSONFormsObject)}
                    key={doc_id}/>}
                    </>
        },
        {
            label: 'History',
            key: 'history',
            children:<ObjectHistoryTab doc_id={doc_id} jsonSchema={jsonSchema}/>
        }
    ];
    if (promptContextTab)
        tabItems.push({
                label: 'Prompt Context',
                key: '4',
                children: promptContextTab
            });

    return <>
        {!isLoaded && <Spin/>}
        {isLoaded && <Tabs defaultActiveKey="1" destroyInactiveTabPane={true} items={tabItems}/>
    }</>;
}

export function ExtensionSupportedObjectEditor() {
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const note = selectedJsonFormsContext.note;
    const doc_id = note?.id;
    const jsonFormsObject = selectedJsonFormsContext.jsonFormsObject;
    const isLoaded1 = selectedJsonFormsContext.isJSONFormsLoaded;
    const saveJSONFormsObject = selectedJsonFormsContext.saveJSONFormsObject;

    const {extensions, isLoaded: isLoaded2} = useContext(NoteHierarchyContext);

    if (!isLoaded1 || !isLoaded2) {
        return <Spin/>;
    }

    const noteType = getNoteTypeWithSchemaFor(extensions,note);
    const hasSchema = noteType?.schema;
    const tabItems = [];

    if (hasSchema) {
        tabItems.push({
            label: 'Edit',
            key: '1',
            children: <JSONFormsEditorV2/>
        });
        tabItems.push({
            label: 'Raw JSON',
            key: '3',
            children: <>{jsonFormsObject && 
                <RawJSONEditor jsonStr={JSON.stringify(jsonFormsObject)} schema={noteType.schema} isSchema={false}
                    showSaveButton={true} 
                    saveFunc={(jsonObject:object)=>saveJSONFormsObject(jsonObject as JSONFormsObject)}
                    key={doc_id}/>}
                    </>
        });
        tabItems.push({
            label: 'JSON History',
            key: 'history',
            children:<ObjectHistoryTab doc_id={doc_id} jsonSchema={noteType?.schema}/>
        });    
    }
    if (tabItems.length===0) {
        return <>COMING SOON: Note backups</>;
    }

    return <>
        <Tabs defaultActiveKey="1" destroyInactiveTabPane={true} items={tabItems}/>
    </>;
}