import { Functions, httpsCallable } from "firebase/functions";
import { FIREBASE_FUNCTIONS } from "../../AppBase/App";


export async function callIdeaGeneratorAI(noteType:string, ideaCommand:string) {
    /*
     Tips: Prefilled bullets reduces chance of getting something strange. 1. is better than "*" because "*" tends to create markdown subtext.
     */
    const instruction = "Fill in the blanks, in the list with "+ideaCommand;
    const object = "Ideas for ...\n1. \n\n2. \n\n3. \n\n";

    // console.log("'Ideas' command: "+instruction);

    const remoteCommandEditText = httpsCallable(FIREBASE_FUNCTIONS, "openaifunctions_callableCommandEditText");
    const commandResponse = await remoteCommandEditText({
        object,
        instruction});

    //@ts-ignore
    if (commandResponse.data.choices.length>0) {
        //@ts-ignore
        const text = commandResponse.data.choices[0].text as string;
        console.log(text);
        return text;
    }
    return null;
}

export function useCallIdeaGeneratorAI() {
    return function(noteType:string, ideaCommand:string) {
        return callIdeaGeneratorAI(noteType, ideaCommand);
    }
}