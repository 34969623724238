import { ChatCompletionMessageParam } from "openai/resources";
import { ChatCompletionCreateParams, ChatCompletionMessageToolCall, ChatCompletionTool, ChatCompletionToolChoiceOption } from "openai/resources/chat/completions";

export type StreamingCallback = (fullOutput: string, newOutput: string, isDone: boolean) => void;
export type ChatStreamErrorCallback = (error: string) => void;
export type ChatAPIOptions = /*ChatCompletionCreateParamsBase &*/ {
    // from the ChatCompletionCreateParamsBase:
    temperature?: number;
    max_tokens?: number;
    tool_choice?: ChatCompletionToolChoiceOption;
    tools?: Array<ChatCompletionTool>;
    response_format?: ChatCompletionCreateParams.ResponseFormat,

    // Just for us:
    abortController?: AbortController;
};
export const DEFAULT_CHAT_API_OPTIONS: ChatAPIOptions = {
    // Defaults:
    temperature: 0.7,
    response_format: {type: "text"} as ChatCompletionCreateParams.ResponseFormat,
    max_tokens: -1,
};

export type ChatAPICallReturnType = {success:boolean, message?:string, error?:string};

export type ChatAPICall = (messages: ChatCompletionMessageParam[], streamingCallback?: StreamingCallback, errorCallback?: ChatStreamErrorCallback, options?: ChatAPIOptions, callFunction?:(functionSpec:ChatCompletionMessageToolCall.Function[])=>void) => Promise<ChatAPICallReturnType>;


/* LLM_MODEL_* are the names selectable to the user of each LLM model that can be chosen by the user.
There can be multiple models for just one type of server.*/
export const LLM_MODEL_LOCAL = "Local LM";
export const LLM_MODEL_OPENAI_BEST = "GPT-4o";
export const LLM_MODEL_OPENAI_LIGHT = "GPT-3.5";
export const LLM_MODEL_DEPRECATED_GEMINI_VIA_CLOUDFUNCTIONS = "Deprecated Gemini via cloud functions";
export const LLM_MODEL_GEMINI_VIA_FIREBASE = "Gemini"
export const LLM_MODEL_GROQ = "Groq";

export type LLMModelName = typeof LLM_MODEL_LOCAL | typeof LLM_MODEL_OPENAI_BEST | typeof LLM_MODEL_OPENAI_LIGHT | typeof LLM_MODEL_DEPRECATED_GEMINI_VIA_CLOUDFUNCTIONS | typeof LLM_MODEL_GEMINI_VIA_FIREBASE | typeof LLM_MODEL_GROQ;


export const LLM_SERVERTYPE_OPENAI_DIRECT = "GPT-4o Direct";
export const LLM_SERVERTYPE_FirebaseFunctionsOpenAI = "FirebaseOpenAIChatServer";
export const LLM_SERVERTYPE_FirebaseGroq="FirebaseGroqChatServer";
export const LLM_SERVERTYPE_LMStudio = "LMStudioChatServer";
export const LLM_SERVERTYPE_FirebaseFunctionsVertexAI = "FirebaseFunctionsVertexAIChatServer"; // Deprecated
export const LLM_SERVERTYPE_FirebaseDirectGemini = "FirebaseGeminiChatServer"; // New!


export type ServerEndpointString = typeof LLM_SERVERTYPE_FirebaseFunctionsOpenAI | typeof LLM_SERVERTYPE_FirebaseFunctionsVertexAI | typeof LLM_SERVERTYPE_LMStudio | typeof LLM_SERVERTYPE_FirebaseGroq | typeof LLM_SERVERTYPE_OPENAI_DIRECT | typeof LLM_SERVERTYPE_FirebaseDirectGemini;

export type LMServerType = {
    name: LLMModelName;
    contextLength: number; // In Tokens
    isLocal: boolean;
    serverType: ServerEndpointString;
    defaultTemperature: number;
    supportsStreaming: boolean; // to support streaming, it can't use Firebase Functions, which have a single batch output.
    supportsFunctions: boolean;
    // modelTypeForTokenization?: TiktokenModel; // Best model type for tokenization. Currently only supporting OpenAI tokenization counts in LLMTokenCounter
}

// We've commented out some rarely used ones to make the list shorter, but they should be fully functioning if the servers are available.
export const LM_SERVERS_OBJ = [
    // {name: LLM_MODEL_LOCAL,
    //     contextLength: 32768,
    //     isLocal: true,
    //     serverType: LLM_SERVERTYPE_LMStudio,
    //     defaultTemperature: 0.3,
    //     supportsStreaming: true,
    //     supportsFunctions: false,
    //     /*modelTypeForTokenization: "gpt-4-0125-preview" as TiktokenModel*/},
    {name: LLM_MODEL_OPENAI_BEST,
        contextLength: 131072/*128k*/,
        isLocal: false,
        serverType: "FirebaseOpenAIChatServer",
        defaultTemperature: 0.35, // Hypothesis: Because GPT-4 is a bit smarter than the others, a higher temperature has more variety without as much downside. But at 0.4 I do see some hallucinations.
        supportsStreaming: false,
        supportsFunctions: true,
        /*modelTypeForTokenization: "gpt-4-0125-preview" as TiktokenModel*/},
    {name: LLM_MODEL_OPENAI_LIGHT,
        contextLength: 16384/*16k*/,
        isLocal: false,
        serverType: "FirebaseOpenAIChatServer",
        defaultTemperature: 0.3,
        supportsStreaming: false,
        supportsFunctions: true,
        /*modelTypeForTokenization: "gpt-3.5-turbo-0125" as TiktokenModel*/},
    // {name: LLM_MODEL_DEPRECATED_GEMINI_VIA_CLOUDFUNCTIONS,
    //     contextLength: 32768,
    //     isLocal: false,
    //     defaultTemperature: 0.3,
    //     supportsStreaming: true,
    //     supportsFunctions: false,
    //     serverType: LLM_SERVERTYPE_FirebaseFunctionsVertexAI,
    // },
    // {name: LLM_MODEL_GROQ,
    //     contextLength: 32768,
    //     isLocal: false,
    //     defaultTemperature: 0.3,
    //     supportsStreaming: false,
    //     supportsFunctions: true,
    //     serverType: "FirebaseGroqChatServer",
    // },
    {name: LLM_SERVERTYPE_OPENAI_DIRECT,
        contextLength: 131072/*128k*/,
        isLocal: false,
        serverType: LLM_SERVERTYPE_OPENAI_DIRECT,
        defaultTemperature: 0.7,
        supportsStreaming: true,
        supportsFunctions: true,
        /*modelTypeForTokenization: "gpt-4-0125-preview" as TiktokenModel*/
    },
    {
        name: LLM_MODEL_GEMINI_VIA_FIREBASE,
        contextLength: 2000000, /*2 million*/
        isLocal: false,
        serverType: LLM_SERVERTYPE_FirebaseDirectGemini,
        defaultTemperature: 0.7,
        supportsStreaming: true,
        supportsFunctions: false,
    }
] as LMServerType[];


export function cleanChatMessagesBeforeSendToServer(messages:ChatCompletionMessageParam[]) {
    return messages.map((message:ChatCompletionMessageParam)=>{
        const newMessage = {role: message.role, content: message.content};
        return newMessage;
    });
}