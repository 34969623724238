import { Tooltip } from "antd";
import { useContext, useState } from "react";
import { EditorState } from "draft-js";
import { insertANoteIntoFocusedBlock, insertANoteLinkEntityIntoFocusedBlock, insertAVoiceLinkEntityIntoFocusedBlock } from "../../BlockEditing/LinkToNoteBlockUtils";
import { FileTextOutlined, LinkOutlined, UserOutlined } from "@ant-design/icons";
import { SearchModal } from "../../../../../DecisionGraph/AlgoliaSearch/AlgoliaSearchUIs";
import { NotesContext } from "../../../../Data/NotesContext";
import { ReplicaPitch } from "../../../../../DecisionGraph/Utilities/Sound/ReplicaAPI";
import { SelectVoiceModal } from "../../../../../DecisionGraph/Utilities/Sound/ReplicaVoiceSidebarAndModal";


export function useInsertNote_ToolbarButton(editorState:EditorState, setEditorState:(editorState:EditorState)=>void) {
    const [isSearchModalOpen, setSearchModalOpen] = useState(false);
    function insertHyperlinkFromSearch(doc_id:string,doc_name:string) {
        setEditorState(insertANoteIntoFocusedBlock(editorState, doc_id, doc_name));
        setSearchModalOpen(false);
    }
    function openSearchModal() {
        setSearchModalOpen(true);
    }

    function InsertNote_ToolbarButton(props:any) {
        function onClick() {
            setSearchModalOpen(true);
        }
        // console.log("TodoButton_DraftJS: props=",props);
        return <div className="draftJSButtonWrapper" onMouseDown={(e)=>e.preventDefault()}>
            <Tooltip title = "Insert a note doc (advanced version of linking)">
                <button onClick={onClick} className="draftJSButton">
                <b><FileTextOutlined/></b>
                </button>
            </Tooltip>
        </div>;
    }

    return {
        ToolbarButton: InsertNote_ToolbarButton,
        SearchModal: <SearchModal isSearchModalOpen={isSearchModalOpen} setSearchModalOpen={setSearchModalOpen} callback={insertHyperlinkFromSearch} title="Insert full note" key="insertNoteSearchModal"/>,
        openSearchModal,
    };
}


export function useLinkToNote_ToolbarButton(editorState:EditorState, setEditorState:(editorState:EditorState)=>void) {
    const [isSearchModalOpen, setSearchModalOpen] = useState(false);
    const notesContext = useContext(NotesContext);
    async function insertHyperlinkFromSearch(doc_id:string,doc_name:string) {
        const newEditorState = await insertANoteLinkEntityIntoFocusedBlock(editorState, doc_id, doc_name, notesContext);
        setEditorState(newEditorState);
        setSearchModalOpen(false);
    }
    function openSearchModal() {
        setSearchModalOpen(true);
    }

    function ToolbarButton(props:any) {
        function onClick() {
            setSearchModalOpen(true);
        }
        // console.log("TodoButton_DraftJS: props=",props);
        return <div className="draftJSButtonWrapper" onMouseDown={(e)=>e.preventDefault()}>
            <Tooltip title = "Insert a link to a note">
                <button onClick={onClick} className="draftJSButton">
                <b><LinkOutlined/></b>
                </button>
            </Tooltip>
        </div>;
    }

    return {
        ToolbarButton,
        SearchModal: <SearchModal isSearchModalOpen={isSearchModalOpen} setSearchModalOpen={setSearchModalOpen} callback={insertHyperlinkFromSearch} key="linkToNoteSearchModal"/>,
        openSearchModal,
    };
}


export function useLinkToVoice_ToolbarButton(editorState:EditorState, setEditorState:(editorState:EditorState)=>void) {
    const [isSearchModalOpen, setSearchModalOpen] = useState(false);
    function insertHyperlinkFromSearch(voice_id:string,voice_name:string, pitch:ReplicaPitch) {
        setEditorState(insertAVoiceLinkEntityIntoFocusedBlock(editorState, voice_id, voice_name, pitch));
        setSearchModalOpen(false);
    }
    function openSearchModal() {
        setSearchModalOpen(true);
    }

    function ToolbarButton(props:any) {
        function onClick() {
            setSearchModalOpen(true);
        }
        return <div className="draftJSButtonWrapper" onMouseDown={(e)=>e.preventDefault()}>
            <Tooltip title = "Insert a link to a synthesized voice">
                <button onClick={onClick} className="draftJSButton">
                <b><UserOutlined/></b>
                </button>
            </Tooltip>
        </div>;
    }

    return {
        ToolbarButton: ToolbarButton,
        SearchModal: <SelectVoiceModal isSearchModalOpen={isSearchModalOpen} setSearchModalOpen={setSearchModalOpen} callback={insertHyperlinkFromSearch} key="linkToVoiceSelectModal"/>,
        openSearchModal,
    };
}

