import { ChatAPICall, ChatAPIOptions, ChatStreamErrorCallback, DEFAULT_CHAT_API_OPTIONS, ServerEndpointString, StreamingCallback, cleanChatMessagesBeforeSendToServer } from "./GenericChatServer";
import { ChatCompletionMessageParam } from "openai/resources";
import { getVertexAI, getGenerativeModel, HarmCategory, HarmBlockThreshold } from "firebase/vertexai";
import { MODEL_BEST, ModelType } from "./FirebaseOpenAIChatServer";
import { FIREBASE_APP } from "../../AppBase/App";


const SERVER_TIMEOUT_MINS = 4; // 4 minutes. The source of truth is in our server functions in opeaifunctions.js

const DEBUG = false;

const MAX_OUTPUT_TOKENS = 8192; // Max for the 1.5 models per https://firebase.google.com/docs/vertex-ai/gemini-models#detailed-info

export function useGeminiViaFirebaseChatCommand(lmServerString:ServerEndpointString="FirebaseFunctionsVertexAIChatServer", model: ModelType=MODEL_BEST) {
    // Initialize the Vertex AI service
    const vertexAI = getVertexAI(FIREBASE_APP);

    const callFirebaseGemini:ChatAPICall = async function (messages:ChatCompletionMessageParam[], streamingCallback?:StreamingCallback, errorCallback?:ChatStreamErrorCallback, chatAPIOptions:ChatAPIOptions=DEFAULT_CHAT_API_OPTIONS) {
        const {abortController, ...chatAPIOptionsRest} = chatAPIOptions;
        let modelInstance: ReturnType<typeof getGenerativeModel>;
        const safetySettings = [{category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT, threshold: HarmBlockThreshold.BLOCK_NONE }];
        if (model===MODEL_BEST) {
            /* Per the API video games may need to set the harm category for dangerous content higher.
            We're finding that in this TTRPG context it's getting blocked too frequently even on HIGH.
            TODO adjust this differently based on Extension group so only games get this blocking level.
            */
            modelInstance = getGenerativeModel(vertexAI, { model: "gemini-1.5-pro", safetySettings});
        } else {
            // implied to be model===MODEL_LIGHT
            modelInstance = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash", safetySettings});
        }

        if (DEBUG) console.log("[callFirebaseGemini]>messages: ",messages);

        const startTime = Date.now();

        async function makeTheCall(retries:number=1, attemptsSoFar:number=0):Promise<{success:boolean, message?:string, error?:string}>{
            try {
                if (DEBUG) console.log("[callFirebaseGemini] > sendChatMessage > chat to send: ",messages);
                const cleanMessages = cleanChatMessagesBeforeSendToServer(messages);
                if (!chatAPIOptionsRest.temperature || chatAPIOptionsRest.temperature<=0 || chatAPIOptionsRest.temperature>=2) {
                    throw new Error("[callFirebaseGemini]>Invalid temperature: "+chatAPIOptionsRest.temperature);
                }
                console.log("Temperature: "+chatAPIOptionsRest.temperature);
                const system_instruction = cleanMessages.filter(msg => msg.role === "system").map(msg => msg.content).join("\n");
                let maxOutputTokens = MAX_OUTPUT_TOKENS;
                if (chatAPIOptionsRest.max_tokens && chatAPIOptionsRest.max_tokens>0 && chatAPIOptionsRest.max_tokens<MAX_OUTPUT_TOKENS)
                    maxOutputTokens = chatAPIOptionsRest.max_tokens;

                let history: { role: "user" | "model", parts: { text: string }[] }[] = [];
                let lastRole: "user" | "model" | null = null;
                let lastMessage: { role: "user" | "model", parts: { text: string }[] } | null = null;

                cleanMessages.filter(msg => msg.role !== "function" && msg.role!=="tool" && msg.role!=="system").forEach(function(msg) {
                    let newRole = "user" as "user" | "model";
                    if (msg.role === "assistant") {
                        newRole = "model";
                    }
                    if (newRole === lastRole && newRole === "user") {
                        if (lastMessage) {
                            lastMessage.parts[0].text += " " + msg.content;
                        }
                    } else {
                        lastMessage = {
                            role: newRole,
                            parts: [{ text: msg.content as string }]
                        };
                        history.push(lastMessage);
                        lastRole = newRole;
                    }
                });

                const chat = modelInstance.startChat({
                    history,
                    generationConfig: {
                        maxOutputTokens,
                        temperature: chatAPIOptionsRest.temperature,
                    },
                    systemInstruction: {
                        role: "system",
                        parts: [{text: system_instruction}]
                    },
                    safetySettings
                });

                const lastMessageContent = cleanMessages[cleanMessages.length - 1].content;
                if (lastMessageContent === undefined) {
                    throw new Error("Last message content is undefined");
                }
                const result = await chat.sendMessageStream(lastMessageContent as string);

                let text = '';
                for await (const chunk of result.stream) {
                    const chunkText = chunk.text();
                    if (DEBUG) console.log(chunkText);
                    text += chunkText;
                    if (streamingCallback) streamingCallback(text, chunkText, false);
                }

                const endTime = Date.now();
                const timeTakenMinutes = (endTime-startTime)/1000/60;
                if (DEBUG) console.log("[callFirebaseGemini] > sendChatMessage > Gemini API completed in "+timeTakenMinutes+" minutes.");
                if (streamingCallback) streamingCallback(text, "", true);
                return {success:true, message:text};

            } catch (error) {
                // Check to see if this is a firebase internal error:
                //@ts-ignore
                if (error.name==="FirebaseError" && error.message==="deadline-exceeded") {
                    // We can retry once. It's likely it'll fail again, though...
                    const endTime = Date.now();
                    const timeTakenMinutes = (endTime-startTime)/1000/60;
                    console.error("Error: Gemini API has taken "+timeTakenMinutes+" minutes (the server timeout is "+SERVER_TIMEOUT_MINS+" minutes). That's "+(attemptsSoFar+1)+" attempts at up to "+SERVER_TIMEOUT_MINS+" mins each...");
                    if (retries>0) {
                        console.log("... Retrying "+(retries)+" more times...");
                        return await makeTheCall(retries-1, attemptsSoFar+1);
                    } else {
                        console.error("... We've exceeded retries. Giving up.");
                        alert("Error: The Gemini server is running very slow right now. Please try again later.");
                        if (errorCallback)
                            errorCallback("Error: The Gemini server is running very slow right now. Please try again later.");
                        return {success:false, error:"Error: The Gemini server is running very slow right now. Please try again later."};
                    }
                }
                // We get them when Gemini takes extra long to reply, though we just set the timeout to now be 2 minutes -- but that's really long.
                if (abortController?.signal.aborted) {
                    // Not a problem!
                    return {success:false, error:"Aborted by user."};
                } else {
                    // Is this still an unknown error? If so, let's take a look at it in the debugger.
                    console.error(error);
                    debugger;
                    if (errorCallback)
                        errorCallback("Error: "+error);
                    return {success:false, error:"Error: "+error};
                }
            }
        }
        return await makeTheCall();
   };
    return callFirebaseGemini;
}
