import { JSONSchemaType } from "ajv";
import { ArcheryArrow, ConstructBarricade, DestructExplode, BarbaricAxeSwing, BarbaricSwordSlice, TentacleAttack, SneakyKnifeThrow, HealingTouch, FireWizardryFireDart, IceWizardryIceDart, FightingOneTwoPunch, DragonbornFirebreath, AthleticRun, TelepathMindControl, Rest, DustDevilsVeil } from "../GameCard/ReusableCards";
import { Clogs, Sword, TentacleSpikes, cardAddEnhancements } from "../GameCard/ReusableCardEnhancements";
import { ActionCardEnhancement, DefenseCard, DiscardableCard, ReusableCard, XPAtLeast1 } from "../GameCard/GameCardTypes";
import { DisguiseSkill, InfluenceSkill, OutdoorsSkill, PhysicalSkill, SensesSkill, StealthSkill } from "../GameCard/SkillCards";
import { ExtensionJSONFormsObject } from "../../ExtensionsFramework/ExtensionsList";
import { Layout } from "@jsonforms/core";
import { Encouragement, HealingPotion, EnergyPotion } from "../GameCard/DiscardableCards";
import { SkeletalArmor } from "../GameCard/DefenseCards";
import { BASE_XP, getPlayerReusableCards } from "../GameCard/PlayerFunctions";

const TsuiLetourneauSupporters = "7 Ogrukh (4 berserkers, 3 scouts), Alaric Eldridge Thorne (Master Alchemist of Sylvanholme), Elysia (Lensmaker of Sylvanholme)";

type EvenNumberUpTo50 = 0|2|4|6|8|10|12|14|16|18|20|22|24|26|28|30|32|34|36|38|40|42|44|46|48|50;

// TODO We want to create some sort of way to track the player's own experience playing, so that we can adjust the game and the instructions to their skill level.
// export type PlayerGameExperience = {
//   playerName: string;
//   skillSystem: XPAtLeast0;
//   moveSystem: XPAtLeast0;
//   combatSystem: XPAtLeast0;
// }

// Root type includes everything besides cards. When stored in JSON, we store card names. This can be converted to the cards themselves.
export type PlayerCharacterRootType = {
    playerName:string,
    characterName:string,
    role?:string,

    earnedXP:number,
    earnedGold:number,
    spentGold:number, // Money spent on things excluding cards (unusual items, boats, bribes, etc)
    
    addedLife: EvenNumberUpTo50,
    miscItems?:string,
    supporters?:string,
}
export type PlayerCharacterJSONType = PlayerCharacterRootType & ExtensionJSONFormsObject & {
    reusableCardIDs: {
        cardID:string,
        reusableCardEnhancementIDs: string[],
    }[],
    defenseCardIDs:string[],
};
export type PlayerCharacterInMemoryType = PlayerCharacterRootType & {
    reusableCardFunctions: {func:(level:XPAtLeast1)=>ReusableCard, level:XPAtLeast1, enhancements:ActionCardEnhancement[]}[],
    defenseCards:DefenseCard[],
    discardableCards:DiscardableCard[],
};

export const PlayerCharacterSchema:JSONSchemaType<PlayerCharacterJSONType> = {
    type: "object",
    properties: {
        name: {type: "string", nullable: false},
        id: {type: "string", nullable: false},
        version: {type: "number", nullable: false},

        playerName: {type: "string", nullable: false, description: "The name of the player controlling this character"},
        characterName: {type: "string", nullable: false, description: "The name of the fictional character (PC)"},
        role: {type: "string", nullable: true, description: "Like a class, but optional, and may change over time depending on the character's available actions."},
        earnedXP: {type: "number", nullable: false},
        earnedGold: {type: "number", nullable: false},
        spentGold: {type: "number", nullable: false},
        addedLife: {type: "number", nullable: false},
        reusableCardIDs: {
            type: "array",
            items: {
                type: "object",
                properties: {
                    cardID: {type: "string", nullable: false},
                    reusableCardEnhancementIDs: {
                        type: "array",
                        items: {type: "string"},
                        nullable: false,
                    }
                },
                required: [],
            },
            nullable: false,
        },
        miscItems: {type: "string", nullable: true, description: "Any items the character is carrying that are not cards"},
        supporters: {type: "string", nullable: true, description: "Any NPCs or other characters that have committed to supporting this character"},
        defenseCardIDs: {
            type: "array",
            items: {type: "string"},
            nullable: false,
        }
    },
    required: [],
};
// Uncomment the following to get the JSON schema in the console, in the propert format so that it can be pasted into the JSONForms editor at https://jsonforms-editor.netlify.app/
// console.log("PlayerCharacterSchema:")
// console.log(PlayerCharacterSchema);

/**********
 * UI Schema
 * 
 * The typescript for the "Layout" in json forms is less than optimal.
 * We want to preserve editability in the JSON Forms Editor at https://jsonforms-editor.netlify.app/ so:
 * - We don't use the Layout type in the declaration itself, because that would force extra inline casts due to the unusual typescript written by JSON Forms Editor.
 * - Instead, we cast as unknown as Layout at the end
 * - We keep the double quotes around the "type" values, because that's how the JSON Forms Editor writes them.
 */
export const PlayerCharacterUISchema = {
  "type": "VerticalLayout",
  "elements": [
    {
      "type": "Group",
      "elements": [
        {
          "type": "Control",
          "scope": "#/properties/playerName"
        },
        {
          "type": "Control",
          "scope": "#/properties/characterName"
        }
      ],
      "label": ""
    },
    {
      "type": "Group",
      "elements": [
        {
          "type": "Control",
          "scope": "#/properties/earnedXP"
        },
        {
          "type": "Control",
          "scope": "#/properties/earnedGold"
        }
      ],
      "label": "Earnings"
    },
    {
      "type": "Group",
      "elements": [
        {
          "type": "Control",
          "scope": "#/properties/addedLife"
        },
        {
          "type": "Control",
          "scope": "#/properties/reusableCardIDs",
          "label": "Reusable Cards"
        },
        {
          "type": "Control",
          "scope": "#/properties/defenseCardIDs",
          "label": "Defense Cards"
        },
        {
          "type": "Control",
          "scope": "#/properties/miscItems"
        },
        {
          "type": "Control",
          "scope": "#/properties/supporters"
        }
      ],
      "label": "Growth"
    }
  ]
} as unknown as Layout;

export const AllPlayerCharacters:PlayerCharacterInMemoryType[] = [
  {
      // Template empty player 1
      playerName:"_____________",
      characterName:"____________",
      role: "",
      earnedXP: BASE_XP,
      earnedGold:0,
      spentGold: 0,
      addedLife: 0,
      reusableCardFunctions: [
        {func:Rest, level:1, enhancements:[]},
        {func:SneakyKnifeThrow, level:1, enhancements:[]},
        {func:FightingOneTwoPunch, level:1, enhancements:[]},
        {func:HealingTouch, level:1, enhancements:[]},
        {func:StealthSkill, level:1, enhancements:[]},
      ],
      defenseCards: [],
      discardableCards: [HealingPotion(1)],
      miscItems: "",
      supporters: ""
  },
  {
    // Template empty player 2
    playerName:"_____________",
    characterName:"____________",
    role: "",
    earnedXP: BASE_XP,
    earnedGold:0,
    spentGold: 0,
    addedLife: 0,
    reusableCardFunctions: [
      {func:Rest, level:1, enhancements:[]},
      {func:BarbaricSwordSlice, level:1, enhancements:[{enhancement:Sword, level:1}]},
      {func:IceWizardryIceDart, level:1, enhancements:[]},
      {func:AthleticRun, level:1, enhancements:[]},
      {func:PhysicalSkill, level:1, enhancements:[]},
    ],
    defenseCards: [],
    discardableCards: [HealingPotion(1)],
    miscItems: "",
    supporters: ""
  },{
    // Template empty player 3
    playerName:"_____________",
    characterName:"____________",
    role: "",
    earnedXP: BASE_XP,
    earnedGold:0,
    spentGold: 0,
    addedLife: 0,
    reusableCardFunctions: [
      {func:Rest, level:1, enhancements:[]},
      {func:ArcheryArrow, level:1, enhancements:[]},
      {func:FireWizardryFireDart, level:1, enhancements:[]},
      {func:HealingTouch, level:1, enhancements:[]},
      {func:OutdoorsSkill, level:1, enhancements:[]},
    ],
    defenseCards: [],
    discardableCards: [HealingPotion(1)],
    miscItems: "",
    supporters: ""
  },
  
  /*********************************************************************************************************************
   * Tsui Party (I am real human, Carfaxx Abbey, Rarrr) */
  {
  playerName: "Noah (with Tsuis)",
  characterName: "I am real human",
  role: "Skeletal Warrior",
  earnedXP: BASE_XP+8+1+1+1+2, // same as bronzo
  earnedGold: 25, // same as bronzo
  spentGold: 0,
  addedLife: 6,
  reusableCardFunctions: [
    {func:Rest, level:1, enhancements:[]},
    {func:InfluenceSkill, level:4, enhancements:[]},
    {func:PhysicalSkill, level:2, enhancements:[]},
    {func:BarbaricAxeSwing, level:4, enhancements:[]},
    {func:AthleticRun, level:4, enhancements:[]},
  ],
  defenseCards: [SkeletalArmor(1)],
  discardableCards: [],
  miscItems: "Skeletal Horse",
  supporters: TsuiLetourneauSupporters
},{
    playerName:"Carl Tsui",
    characterName:"Carfaxx Abbey",
    role: "Psionic Monk",        
    earnedXP:BASE_XP+8 /*6/30/2024*/+1 /*9/22/2024*/+1,
    earnedGold:25+4,
    spentGold: 0,
    addedLife: 0,
    reusableCardFunctions: [
      {func:ConstructBarricade, level:3, enhancements:[]},
      {func:AthleticRun, level:3, enhancements:[]},
      {func:Rest, level:1, enhancements:[]},
      {func:InfluenceSkill, level:1, enhancements:[]},
      {func:PhysicalSkill, level:1, enhancements:[]},
      {func:StealthSkill, level:1, enhancements:[]},
      {func:FightingOneTwoPunch, level:3, enhancements:[]},
      {func:TelepathMindControl, level:2, enhancements:[]},
    ],
    defenseCards: [],
    discardableCards: [],
    miscItems: "Horse, unidentified \"Disintegration Marble\", unidentified color-shifting sphere",
    supporters: TsuiLetourneauSupporters
},{
    playerName: "Brandon Tsui",
    characterName: "Rarrr",
    role: "Barbaric Dragonborn",
    earnedXP:BASE_XP+8       /*6/30/2024*/+1 /*9/22/2024*/ +1,
    earnedGold: 25    /*6/30/2024*/+4 /*9/22/2024*/ +4,
    spentGold: 0,
    addedLife: 4,
    reusableCardFunctions: [
      {func:Rest, level:1, enhancements:[]},
      {func:SensesSkill, level:3, enhancements:[]},
      {func:DragonbornFirebreath, level:3, enhancements:[]},
      {func:DestructExplode, level:2, enhancements:[]},
      {func:BarbaricSwordSlice, level:4, enhancements:[Sword(4)]},
    ],
    defenseCards: [],
    discardableCards: [],
    miscItems: "Horse",
    supporters: TsuiLetourneauSupporters
},



/*********************************************************************************************************************
 * LeTourneau party     */        
{
  playerName:"Noah (with LeTourneaus)",
  characterName:"Bronzo",
  role: "Shapechanging Brain In A Jar",
  earnedXP:BASE_XP+8   /*6/30/2024*/+1 /*8/5/2024*/ +1 /*9/22/2024*/+1 /*10/20/2024*/+2 /*1/5/2024*/+2,
  earnedGold:25 /*6/30/2024*/+4 /*8/5/2024*/ +4 /*9/22/2024*/+5 /*10/20/2024*/+5 /*1/5/2024*/+8,
  spentGold: 0,
  addedLife: 6,
  reusableCardFunctions: [
    {func:Rest, level:1, enhancements:[]},

    {func:DisguiseSkill, level:5, enhancements:[]},

    {func:IceWizardryIceDart, level:4, enhancements:[]},
    {func:TentacleAttack, level:5, enhancements:[TentacleSpikes(1)]},
    {func:TelepathMindControl, level:1, enhancements:[]},
    {func:DustDevilsVeil, level:1, enhancements:[]},
  ],
  defenseCards: [],
  discardableCards: [
    Encouragement(1),
  ],
  miscItems: "Small luminescent gemstone",
  supporters: TsuiLetourneauSupporters
},{
    playerName: "Bradley LeTourneau",
    characterName: "Viking Guy",
    role: "Viking",
    earnedXP: BASE_XP+7        /*8/5/2024*/ +1 /*10/20/2024*/+2 /*1/5/2024*/+2,
    earnedGold: 17+4    /*8/5/2024*/ +4 /*10/20/2024*/+5 /*1/5/2024*/+8,
    spentGold: 0,
    addedLife: /*8/5/2024*/2 /*1/5/2024*/+2,
    reusableCardFunctions: [
      {func:Rest, level:1, enhancements:[]},

      {func:PhysicalSkill, level:2, enhancements:[]},
      {func:StealthSkill, level:2, enhancements:[]},
      {func:DisguiseSkill, level:3, enhancements:[]},

      {func:DestructExplode, level:2, enhancements:[Clogs]},
      {func:SneakyKnifeThrow, level:4, enhancements:[Clogs]},
      {func:FightingOneTwoPunch, level:1, enhancements:[Clogs]},
    ],
    defenseCards: [/*Defense_Stealth3_SneakyKnifeThrow3*/],
    discardableCards: [
      EnergyPotion(1),
    ],
    miscItems: "Horse",
    supporters: TsuiLetourneauSupporters,
  },{
    playerName: "Jason LeTourneau",
    characterName: "Wolfgang Von Expo",
    role: "Mystic Healer",
    earnedXP: BASE_XP+7        /*8/5/2024*/ +1 /*10/20/2024*/+2 /*1/5/2024*/+2,
    earnedGold: 17+4    /*8/5/2024*/ +4 /*10/20/2024*/+5 /*1/5/2024*/+8,
    spentGold: 5/*bought boat*/,
    addedLife: 0,
    reusableCardFunctions: [
      {func:Rest, level:1, enhancements:[]},

      {func:HealingTouch, level:3, enhancements:[Clogs]},
      {func:TelepathMindControl, level:4, enhancements:[Clogs]},
      {func:FireWizardryFireDart, level:2, enhancements:[Clogs]},
      {func:ConstructBarricade, level:1, enhancements:[Clogs]},

      {func:StealthSkill, level:2, enhancements:[]},
      {func:OutdoorsSkill, level:2, enhancements:[]},
      {func:InfluenceSkill, level:1, enhancements:[]},
      {func:DisguiseSkill, level:1, enhancements:[]},

    ],
    defenseCards: [],
    discardableCards: [],
    miscItems: "Horse, Boat on the Kingsport Docks",
    supporters: TsuiLetourneauSupporters
  }
] as PlayerCharacterInMemoryType[];