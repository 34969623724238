import React, {useState, useEffect, useImperativeHandle, useContext } from "react"
import { Spin } from 'antd';


import { convertFromRaw, convertToRaw, DraftHandleValue, EditorState } from "draft-js";
import { scrollSelectionIntoViewInAMoment, setBinAndPriority, setBlockKeyPriorityIfNeeded, toggleChecked, useHandleReturn } from "../BlockEditing/EditCheckableItemUtils";
import { KEY_COMMAND_HANDLED, KEY_COMMAND_NOT_HANDLED, SWITCH_TO_SEARCH, useBasicHandleKeyCommand, useBasicKeyBindingFn } from "../DraftKeyboardBindings";
import { BLOCK_RENDER_MAP, blockStyleFn, useBlockRendererFn } from "../BlockEditing/BlockRendererAndStyleFns";

import Editor from '@draft-js-plugins/editor';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import "./editorStyles.css";
import "../WYSIWYGEditor.css";
import { useDraftExtensions } from "./DraftJsToolbarAndPlugins";
import { getFreshUpdatedContentState } from "./GetFreshUpdatedContentState";
import { SelectedJSONFormsContext } from "../../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { useSaveNoteDebounced } from "../../../Data/NoteDBHooks";
import { useStateLSBoolean } from "../../../../DecisionGraph/Utilities/StateWithLocalCache";
import { NotesContext } from "../../../Data/NotesContext";
import { SAVE_DELAY } from "../../../Data/FirestoreNoteClient";
import { isEqual } from "lodash";
import { Note } from "../../../Data/NoteType";
import { NoteHierarchyContext } from "../../NoteInformationComponents/NoteHierarchyProvider";

/******
 * lastEdited Implementation notes:
 * We've tried lastEdited in in local state, but it doesn't get updated fast enough.
 * So it needs to be global.
 * This assumes only one saveable editor per instance! If not, we'll need to do a map from ID->value for each editor.
 */
let lastEdited=0;

const DEBUG_SAVING_VERSION = false;

function SaveableDraftPluginsNoteEditor({className, doc_id, focusSearch}:{className?:string,doc_id:string,focusSearch:any}, ref:any) {
  const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
  const note = selectedJsonFormsContext.note;
  const {extensions} = useContext(NoteHierarchyContext);
  
  const saveNote = useSaveNoteDebounced(doc_id);
  const doc_data = note?.doc_data;
  const doc_version = note?.version;
  const doc_name = note?.doc_name;
  // Doc state:
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [lastLoadedDocId, setLastLoadedDocId] = useState("");
  const [counter, setCounter] = useState(0);
  const [lastSavedVersion, setLastSavedVersion] = useState(doc_version || 0);
  const notesContext = useContext(NotesContext);
  const [editable,setEditable] = useStateLSBoolean("draftEditor_isEditable",true);
  
  
  const basicKeyBindingFn = useBasicKeyBindingFn(editorState);
  const handleReturn = useHandleReturn(editorState, onEditorChange);
  const basicHandleKeyCommand = useBasicHandleKeyCommand(editorState, onEditorChange);
  const blockRenderFn = useBlockRendererFn(onEditorChange, editorState, editable);

  function scrollToBlockKey(blockKey:string) {
      // Bug: if the currently selected note does not contain this block key, nothing happens.
      // This bug will need to be handled in the places calling this.

      const selectionState = editorState.getSelection();
      // console.log("Would scroll to block key",blockKey);
      var updatedSelection = selectionState.merge({
        focusKey: blockKey,
        anchorKey: blockKey,
        focusOffset: 0,
        anchorOffset: 0,
      });
      // selectionState.set("focusKey",blockKey);
      // selectionState.set("anchorKey",blockKey);
      // selectionState.set("anchorOffset",1);
      // selectionState.set("focusOffset",0);
      let newEditorState = setBlockKeyPriorityIfNeeded(editorState, blockKey);
      // This must be after the above.
      newEditorState = EditorState.forceSelection(newEditorState, updatedSelection);
      // Note: the following priority will be set, which enables dnd, but it will not be saved because this doesn't trigger a save.      
      setEditorState(newEditorState);
      scrollSelectionIntoViewInAMoment();
  }
  function markAsComplete(blockKey:string) {
    scrollToBlockKey(blockKey);
    const block = editorState.getCurrentContent().getBlockForKey(blockKey);
    onEditorChange(toggleChecked(editorState, block));
  }
  function changeBinAndPriority(blockKey:string, binName:string, priority:number) {
    scrollToBlockKey(blockKey);
    const block = editorState.getCurrentContent().getBlockForKey(blockKey);
    onEditorChange(setBinAndPriority(editorState, block, binName, priority));
  }
  useImperativeHandle(ref, () => ({
    scrollToBlockKey,
    markAsComplete,
    changeBinAndPriority
  }))

  /*********
   * Act on the loaded data
   * This whole block is rather odd
   * Great, incomplete, explanation about redux & draftjs here https://caffeinecoding.com/react-redux-draftjs/
   * (firestore in react is operating through redux)
   */
  const currentRaw = convertToRaw(editorState.getCurrentContent());
  useEffect(()=> {
    if (DEBUG_SAVING_VERSION) console.log("[SaveableDraftPluginsNoteEditor] > Observed a change in the note data. Counter: "+counter, "Last saved version: "+lastSavedVersion, "Doc version: "+doc_version, "Loaded note: ",doc_data);
    // console.log("checking if we should load...");
    if (counter===0)
      setCounter(1); // so save knows it can save but won't in the very first try
    if (!doc_data) {
      // We haven't loaded anything, so there's nothing to debounce.
      // console.log("firestore/redux's doc_data is blank, so we won't load.")
      return;
    }
    if (isEqual(currentRaw, doc_data)) {
      // console.log("No changes, skipping load")
      return; // no changes.
    }
    // How long should we ignore loads from the server from? That depends on our delay.
    // We got bounce issue at 300ms. Saves are delayed by SAVE_DELAY, so this must be above that number, plus time for the server to respond.
    // It slows down the reaction time, but reduces the risk of issues.
    const LOCAL_ONLY_EDITS_DEBOUNCE=SAVE_DELAY+300;
    // TODO consider another debounce strategy -- perhaps waiting until redux has caught up with the version we sent out? Could be an issue if it gets multiple updates and is out of date.
    if (lastLoadedDocId!==doc_id) {
      // Where should we set the lastLoadedDocName? Only if we load? Or above, if there's no changes?
      // console.log("\tSwitched doc, reloading...");
      //setCounter(0); should we do this?
      setLastLoadedDocId(doc_id);
    } else if (lastEdited>Date.now()-LOCAL_ONLY_EDITS_DEBOUNCE) {
      // console.log("editorState - debouncing -- ignoring what may be a  local edit")
      // At 100ms, still had issues with the cursor bouncing.
      // Assume this is from the edit. We're essentially debouncing here to make the editor work.
      // We could do better here.
      // console.log("\tIgnoring this change, assuming this user edited it...");
      setTimeout(()=>{
        // We do want to force a rerender once the cache is up to speed, so we'll load the latest from the server, if there's a true change...
        setCounter(counter+1); 
      },LOCAL_ONLY_EDITS_DEBOUNCE+1)
      return;
    } else if (doc_version && lastSavedVersion>=doc_version) {
      // Skip it! Don't load an old, or equal, version.
      // console.log("editorState - Not loading an old version that came from the server.");
      return;
    }

    // We've recieved a change from the server. This could be the first load, or a change from another system.
    // Note -- we can reach this state when we do a cut/delete action, it somehow skips onEditorChange. I don't know why or how.

    // This is a good time to update the note and make sure the values in it are fresh.
    // TODO we'd like to do this primarily on first load of the editor.
    // console.log("[SaveableDraftPluginsNoteEditor] > Checking for fresh values in our links to Notes/Music/Voices. EditorState: ",editorState)
    // setEditorState(EditorState.createWithContent(convertFromRaw(doc_data)));
    // const doc_data_fresh = getFreshUpdatedDocData(doc_data, notesContext);
    const editorState_fresh = getFreshUpdatedContentState(convertFromRaw(doc_data), notesContext, extensions);
    setEditorState(EditorState.createWithContent(editorState_fresh));

    if (doc_version)
      setLastSavedVersion(doc_version);
    else
      setLastSavedVersion(0); // start at the beginning if there was none from the server.
    // foce a rerender with the new incoming state from the server. But if it's the same, don't do anything.
    //  setEditorState(EditorState.createWithContent(convertFromRaw(doc_data)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentRaw, doc_data, counter, lastLoadedDocId, doc_id, lastSavedVersion, doc_version]);


  function onEditorChange(newEditorState:EditorState) {
    // console.log("Updating editorState from local edit to ",doc_version, newEditorState)
    // First, make sure we keep updated with the latest state -- e.g. including selection state:
    setEditorState(newEditorState);
    lastEdited=Date.now();
    const doc_data_to_save=convertToRaw(newEditorState.getCurrentContent());
    const old_data = convertToRaw(editorState.getCurrentContent());
    // Avoid writing if the data is the same as the storage:
    if (isEqual(doc_data_to_save, old_data)) {
      return;
    }
    if (counter===0)
      // don't save to firestore the first time, it's our initial load...
      return;
    if (lastLoadedDocId!==doc_id) {
      // TBD what do we do here? Do we skip saving?
      // TBD what we should do on the first time -- when it's new, we should save on the first edit...
    }
    // Firestore:
    // Consider merging into firestore so we keep extra traits. We could then drop "doc_name" here.
    // Track a version so we don't merge in older versions.
    // TODO we also need to debounce saves. Firestore is not recieving them all in order, and sometimes has a slightly out of date version.
    let newVersion = lastSavedVersion+1;
    setLastSavedVersion(newVersion);
    // console.log("Saving to firestore... New version: "+newVersion);
    // The first line of the file is the note name, as long as it doesn't start with a @ or # for now
    // There must always be a first block of text.
    let newDocName = doc_data_to_save.blocks[0].text;
    if (doc_name && (doc_name.startsWith("@") || newDocName.startsWith("@") || doc_name.startsWith("#") || newDocName.startsWith("#"))) {
      // Don't change it, it's an @ doc -- we don't want to touch this yet.
      newDocName=doc_name;
    }
    const noteToSave:Note = {...note, id:doc_id, doc_name: newDocName,doc_data:doc_data_to_save, version:newVersion};
    saveNote(noteToSave);
  }

  const {insertFunctions, headerComponents, plugins, extendedHandleKeyCommand} = useDraftExtensions(onEditorChange,editorState, editable, basicHandleKeyCommand, setEditable, note);


  function handleKeyCommand(command: string): DraftHandleValue {
    const toReturn = extendedHandleKeyCommand(command);
    if (toReturn!==KEY_COMMAND_NOT_HANDLED)
      return toReturn;
    switch (command) {
      case SWITCH_TO_SEARCH:
        focusSearch();
        return KEY_COMMAND_HANDLED;
    }
    return KEY_COMMAND_NOT_HANDLED;
  }


  useEffect(()=> {
    if (selectedJsonFormsContext) {
      selectedJsonFormsContext.noteEditorRef.current = {...insertFunctions, editorState, setEditorState};
    }
    // On unmount, we have to remove the reference, because we can no longer process changes.
    return function cleanup() {
      if (selectedJsonFormsContext) {
        selectedJsonFormsContext.noteEditorRef.current = undefined;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedJsonFormsContext,editorState, onEditorChange]);


  return <div>
    {(!lastLoadedDocId || lastLoadedDocId!==doc_id) && <div style={{alignItems: "center",width:"100%"}}>
      <Spin size="large" style={{alignItems: "center"}}/>
    </div>}
    {(lastLoadedDocId && lastLoadedDocId===doc_id) && <>
      {headerComponents.map((component,i)=><div key={i}>{component}</div>)}
        <div className="editor rdw2-editor-main">
          <Editor
              editorState={editorState}
              onChange={onEditorChange}
              blockRendererFn={blockRenderFn}
              blockRenderMap={BLOCK_RENDER_MAP}
              blockStyleFn={blockStyleFn}
              plugins={plugins}

              handleKeyCommand={handleKeyCommand}
              keyBindingFn={basicKeyBindingFn}
              handleReturn={handleReturn}
              readOnly={!editable}
          />
        </div>
      </>}
  </div>
}

export default React.forwardRef(SaveableDraftPluginsNoteEditor);