import { Select, Tooltip } from "antd";
import { useStateLSString } from "../../Utilities/StateWithLocalCache";
import { MODEL_BEST, MODEL_LIGHT } from "../../../ServerConnection/LLMServer/FirebaseOpenAIChatServer";
import { useVertexViaFirebaseChatCommand as useVertexViaFirebaseFunctionsChatCommand } from "../../../ServerConnection/LLMServer/FirebaseFunctionsVertexAIChatServer";
import { ChatAPICall, LLM_SERVERTYPE_FirebaseGroq, LLM_SERVERTYPE_FirebaseFunctionsOpenAI, LLM_SERVERTYPE_FirebaseFunctionsVertexAI, LLM_SERVERTYPE_LMStudio, LMServerType, LM_SERVERS_OBJ, LLM_MODEL_LOCAL, LLM_MODEL_OPENAI_BEST, LLM_SERVERTYPE_OPENAI_DIRECT, LLM_MODEL_OPENAI_LIGHT, LLM_SERVERTYPE_FirebaseDirectGemini, LLM_MODEL_GEMINI_VIA_FIREBASE } from "../../../ServerConnection/LLMServer/GenericChatServer";
import callLMStudio from "../../../ServerConnection/LLMServer/LMStudioChatServer";
import { useOpenAIViaFirebaseChatCommand as useOpenAIViaFirebaseFunctionsChatCommand } from "../../../ServerConnection/LLMServer/FirebaseOpenAIChatServer";
import { createContext, useContext, useEffect } from "react";
import { GLOBAL_DEBUG_OUTPUT } from "../../../GlobalDebug";
import { callOpenAIDirect_Streaming } from "../../../ServerConnection/LLMServer/OpenAIDirectChatServer";
import { useGeminiViaFirebaseChatCommand } from "../../../ServerConnection/LLMServer/GeminiFirebaseChatServer";

const LM_SERVERS_STRINGS = LM_SERVERS_OBJ.map((server: LMServerType) => server.name) as string[];
const LM_SERVERS_STRINGS_REMOTE = LM_SERVERS_OBJ.filter((server: LMServerType) => !server.isLocal).map((server: LMServerType) => server.name) as string[];

/***********
Gemini via Firebase is the default, because it has ALL of the following:
 + It can stream responses, enabling users to see the response quicker
 + No configuration required by users
Bonus, but not criteria:
 + It's the cheapest.

The Firebase Functions versions are not the default, because:
 - Firebase Functions cannot stream

OpenAI Direct is not the default, because:
 - User has to manually add a key
But it does have a few benefits:
 + Fastest responses (though unlikely users can tell its faster than Gemini via Firebase)

We believe there's no large noticable user difference in performance between Gemini & OpenAI as of 12/2024
 ~ Speed will appear about the same
 ~ Response quality will appear about the same. Sometimes Gemini is better, sometimes OpenAI is better.
 
*/
const DEFAULT_LM_SERVER = LLM_MODEL_GEMINI_VIA_FIREBASE;

export type LLMServerContextType = {
    callChatAPI?: ChatAPICall;
    serverType: LMServerType;
    lmServers: string[];

    // User facing selection name (it's not the same as the server name)
    lmServerString: string;
    setLMServer: (server: string) => void;
}
export const LLMServerContext = createContext({} as LLMServerContextType);
export function LLMServerContextProvider({children}:{children:any}) {
    const isLocalishhost = (window.location.hostname === 'localhost' || window.location.hostname === "192.168.1.164") && window.location.port === "3000";
    const [lmServerSaved, setLMServer] = useStateLSString<string>("LLM_Select_Server_Type", DEFAULT_LM_SERVER);

    let lmServerString = lmServerSaved;
    // Default to OpenAI Best (GPT-4):
    if (!LM_SERVERS_STRINGS.includes(lmServerString))
        lmServerString = DEFAULT_LM_SERVER;
    let modelQuality = MODEL_BEST;   
    if (lmServerString === LLM_MODEL_OPENAI_LIGHT) // TODO add one for gemini flash
        modelQuality = MODEL_LIGHT;

    let serverType = LM_SERVERS_OBJ.find((server: LMServerType) => server.name === lmServerString) as LMServerType;

    const callLMOpenAIViaFirebaseFunctions = useOpenAIViaFirebaseFunctionsChatCommand(serverType.serverType, modelQuality);
    const callLMVertexViaFirebaseFunctions = useVertexViaFirebaseFunctionsChatCommand();
    const callGeminiViaFirebaseDirect = useGeminiViaFirebaseChatCommand(serverType.serverType, modelQuality);

    let lmServers = LM_SERVERS_STRINGS;
    if (!isLocalishhost) {
        lmServers = LM_SERVERS_STRINGS_REMOTE;
        // If we're not on localhost, we can't use the local server:
        if (lmServerString === LLM_MODEL_LOCAL) {
            setLMServer(LLM_MODEL_OPENAI_BEST);
            lmServerString = LLM_MODEL_OPENAI_BEST;
        }
    }

    let callChatAPI;
    if (serverType?.serverType === LLM_SERVERTYPE_FirebaseFunctionsOpenAI || serverType?.serverType === LLM_SERVERTYPE_FirebaseGroq) {
        callChatAPI = callLMOpenAIViaFirebaseFunctions;
    } else if (serverType?.serverType === LLM_SERVERTYPE_FirebaseFunctionsVertexAI) {
        callChatAPI = callLMVertexViaFirebaseFunctions;
    } else if (serverType?.serverType === LLM_SERVERTYPE_LMStudio) {
        callChatAPI = callLMStudio;
    } else if (serverType?.serverType === LLM_SERVERTYPE_OPENAI_DIRECT) {
        // callChatAPI = callOpenAIDirect_NoStreaming;
        callChatAPI = callOpenAIDirect_Streaming;
    } else if (serverType?.serverType === LLM_SERVERTYPE_FirebaseDirectGemini) {
        callChatAPI = callGeminiViaFirebaseDirect;
    } else {
        console.error("[SelectableServerAntdProChat] Bug in our server list: server not in our hard-coded list despite precheck: ", lmServerString, LM_SERVERS_OBJ);
        debugger;
        callChatAPI = callLMOpenAIViaFirebaseFunctions;
    }
    useEffect(() => { // If there's no OpenAI API key stored, prompt for it.
        if (serverType?.serverType === LLM_SERVERTYPE_OPENAI_DIRECT) {
            const openai_api_key = localStorage.getItem("OPENAI_API_KEY");
            if (!openai_api_key) {
                const openai_api_key2 = prompt("Please enter your OpenAI API key to connect directly");
                if (openai_api_key2) {
                    localStorage.setItem("OPENAI_API_KEY", openai_api_key2);
                } else {
                    // Fall back to something else, the default.
                    setLMServer(LLM_MODEL_OPENAI_BEST);
                }
            }            
        }
    }, [serverType]);

    const providerValue = { callChatAPI, serverType, lmServers, lmServerString, setLMServer };
    return <LLMServerContext.Provider value={providerValue}>
        {children}
    </LLMServerContext.Provider>;
}

export function SelectableLLMServerDropdown() {
    const { lmServers, lmServerString, setLMServer } = useContext(LLMServerContext);
    if (lmServers.length <= 1) {
        if (GLOBAL_DEBUG_OUTPUT)
            return <>{lmServers.length} servers found so no selection being shown.</>;
        return <></>; // nothing to select. We don't show the message.
    }

    return <>
        <Tooltip title="Groq is the fastest. GPT-4 is the smartest. The others are in-between.">
            <Select value={lmServerString} onChange={function (value: string) {
            setLMServer(value);
        } } style={{ minWidth: '100px' }}>
            {lmServers.map(option => <Select.Option key={option} value={option}>{option}</Select.Option>)}
            </Select>
        </Tooltip>
        &nbsp;
    </>;
}

