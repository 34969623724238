import { PlayerCharacterInMemoryType } from "../PlayerCharacter/Players";

import { getCardBaseXPCost, getCardGoldCost, ReusableCard, ActionCardEnhancement, getReusableCardCostStr, getReusableCardUpgradeCostStr, getEnhancementCostStr, getCardsAreSame, DiscardableCard, getDiscardCardCostStr, DefenseCard, getDefenseCardCostStr, getDefenseCardUpgradeCostStr, XPAtLeast1, PositiveNumberTo20 } from "./GameCardTypes";
import { AllReusableCardFunctions, getLevelAppropriateCards } from "./ReusableCards";
import { AllEnhancements } from "./ReusableCardEnhancements";
import { AllSkillCardFunctions } from "./SkillCards";
import { getAllDefenseCards } from "./DefenseCards";
import { AllDiscardableCardFunctions } from "./DiscardableCards";

const DEBUG = false;

export const BASE_XP = 5;  /*Each player starts with 5 XP. Ref PlayerFunctions.ts */

export function getPlayerLevel(player: PlayerCharacterInMemoryType): XPAtLeast1 {
    const xp = player.earnedXP;
    const XP_LEVEL = [
        BASE_XP,  // Start at level 1 with BASE_XP
        BASE_XP + 3,  // Level 2
        BASE_XP + 6,  // Level 3
        BASE_XP + 10, // Level 4
        BASE_XP + 15, // Level 5
        BASE_XP + 21, // Level 6
        BASE_XP + 28, // Level 7
        BASE_XP + 36, // Level 8
        BASE_XP + 45, // Level 9
        BASE_XP + 55, // Level 10
        BASE_XP + 66, // Level 11
    ];
    // This works because it matches at index + 1, finding the first index where the level has more XP than the player.
    let level = XP_LEVEL.findIndex(levelXP => xp < levelXP);
    if (level === -1) {
        level = XP_LEVEL.length;
    }
    return level as XPAtLeast1;
}

export function getXPUsedByCards(player: PlayerCharacterInMemoryType): number {
    const reusableCards = getPlayerReusableCards(player, getPlayerLevel(player));
    return reusableCards.reduce((acc, card) => acc + getCardBaseXPCost(card), 0);
}
export function getGoldUsed(player: PlayerCharacterInMemoryType): number {
    const reusableCards = getPlayerReusableCards(player, getPlayerLevel(player));
    return reusableCards.reduce((acc, card) => acc + getCardGoldCost(card), 0) + player.spentGold;
}

export function getXPUsedByLife(player: PlayerCharacterInMemoryType): number {
    // Must be an even number.
    return player.addedLife/2;
}
export function getXPUsed(player: PlayerCharacterInMemoryType): number {
    return getXPUsedByCards(player)
        + getXPUsedByLife(player);
}
export function getPlayerLife(player: PlayerCharacterInMemoryType, playAsLevel?:XPAtLeast1): number {
    if (playAsLevel===undefined)
        playAsLevel = getPlayerLevel(player);
    return 6 + playAsLevel * 2 + player.addedLife;
}

export function getErrors(player: PlayerCharacterInMemoryType): string[] {
    const errors = [];
    const level = getPlayerLevel(player);
    const reusableCards = getPlayerReusableCards(player, level);
    const cardsAboveLevel = reusableCards.filter(card => card.xpCost>level);
    if (cardsAboveLevel.length > 0) {
        errors.push(`Several cards are above character level ${level}: ${cardsAboveLevel.map(card => card.name).join(", ")}`);
    }
    if (player.addedLife/2 > level) {
        errors.push(`Too much added life for level ${level}: Change from ${player.addedLife} to at most ${level*2}`);
    }
    return errors;
}


/*****
 * Upgrade the player at the end of the session.
 * Note: This needs to be tweaked after any spending on gold each session.
 */
export function getUpgradedPlayer(player:PlayerCharacterInMemoryType) {
    const level = getPlayerLevel(player);
    const gainedXP = level>2?2:1;
    const gainedGold = 3+level;

    const upgradedPlayer = {...player, earnedXP:player.earnedXP+gainedXP, earnedGold:player.earnedGold+gainedGold} as PlayerCharacterInMemoryType;
    const newLevel = getPlayerLevel(upgradedPlayer);
    const playerUnusedXP = upgradedPlayer.earnedXP - getXPUsed(player);
    return {
        upgradedPlayer,
        gainedXP,
        gainedGold,
        newLevel,
        playerUnusedXP,
    };
}

// function testPrintStatsPerCharacter() {
//     Players.forEach(player => {
//         const usedXP = player.cards.reduce((acc,card) => acc+card.xpCost,0);
//         console.log(
//         `Player: ${player.playerName}:
//             - Cards (${player.cards.length}): ${player.cards.map(card => card.cardName).join(", ")}
//             - XP: Used ${usedXP} / ${player.earnedXP} (${player.earnedXP-usedXP} remaining)
//             - Earned XP: ${player.earnedXP}
//             - Used XP: 
//             - Earned Gold: ${player.earnedGold}
//             - Used Gold: ${player.cards.reduce((acc,card) => acc+card.goldCost,0)}
//         `);
//     });
// }
// testPrintStatsPerCharacter();

// function getJoinedPlayersCardNames(player: PlayerCharactersType) {
//     // Remove the cards everyone by default (Breather, 0XP, Discipline=Rest)
//     const cards = player.cards.filter((card: GameCardType) => card.name !== "Breather");

//     return player.characterName + ": " +
//         cards.map(card => (card.name + " (" + card.discipline + ")")).join(", ");
// }
// console.log(getJoinedPlayersCardNames(AllPlayerCharacters[4]));

export function findOneLowerLevelReusableCard(player: PlayerCharacterInMemoryType, card: ReusableCard): ReusableCard | undefined {
    const reusableCards = getPlayerReusableCards(player, getPlayerLevel(player));
    return reusableCards.find(
        function(ownedReusableCard:ReusableCard){
            if (ownedReusableCard.discipline!==card.discipline)
                return false;
            if (getCardBaseXPCost(ownedReusableCard) !== card.xpCost - 1)
                return false;
            if (ownedReusableCard.discipline==="Skill" && ownedReusableCard.name !== card.name)
                return false;
            return true;
        });
}
export function findOneLevelLowerDefenseCard(player: PlayerCharacterInMemoryType, card: DefenseCard): DefenseCard | undefined {
    // TODO we want to do name/type matching too, eventually
    if (!player.defenseCards)
        return undefined;
    return player.defenseCards.find(
        function(ownedDefenseCard: DefenseCard){
            if (ownedDefenseCard.xpCost !== card.xpCost - 1)
                return false;
            return true;
        });
}

export function canPlayerAcquireReusableCard(player: PlayerCharacterInMemoryType, card: ReusableCard): boolean {
    const playerUnusedGold = player.earnedGold - getGoldUsed(player);

    const playerUnusedXP = player.earnedXP - getXPUsed(player);
    // const isUpgrade = player.cards.some(playerCard => playerCard.discipline === card.discipline && getCardXPCost(playerCard) === card.xpCost - 1);
    // Always checking against 1, because we can only go up 1 at a time.
    const canAffordXP = 1 <= playerUnusedXP;

    // Only allowed to get XP cards up to the level the player is at:
    const playerLevel = getPlayerLevel(player);
    const cardLevel = getCardBaseXPCost(card);
    const meetsLevelRequirement = cardLevel <= playerLevel;

    // If it's a XP <=1 card, it can always be acquired. If it's higher XP than that, they have to trade up, one at a time.
    // First, check whether we have any cards of the same discipline that are XP-1 compared to card we're trying to acquire.
    const lowerLevelCard = findOneLowerLevelReusableCard(player, card);
    const has1LowerLevelCard = lowerLevelCard!==undefined;
    let canAffordGold;
    if (!has1LowerLevelCard)
        canAffordGold = getCardGoldCost(card) <= playerUnusedGold;
    else 
        canAffordGold = getCardGoldCost(card) - getCardGoldCost(lowerLevelCard!) <= playerUnusedGold;

    const reusableCards = getPlayerReusableCards(player, playerLevel);
    const alreadyHasHigherLevelCard = reusableCards.some(function(playerCard) {
        const isSameDiscipline = playerCard.discipline === card.discipline;
        const playerCardLevel = getCardBaseXPCost(playerCard);
        const isHigherLevel = playerCardLevel > cardLevel;
        if (playerCard.discipline==="Skill") {
            // Check whether this has the same name, too.
            return isSameDiscipline && isHigherLevel && playerCard.name === card.name;
        }
        return isSameDiscipline && isHigherLevel;
    });
    const canTradeUp = cardLevel <= 1 || has1LowerLevelCard;

    // Check if they already have the card.
    const alreadyHasCard = reusableCards.some(playerCard => getCardsAreSame(playerCard, card));

    if (DEBUG) {
        let failingReason = "";
        if (!canAffordGold) failingReason += "Can't afford gold. ";
        if (!canAffordXP) failingReason += "Can't afford XP. ";
        if (!meetsLevelRequirement) failingReason += "Doesn't meet level requirement. ";
        if (!canTradeUp) failingReason += "Can't trade up. ";
        if (alreadyHasCard) failingReason += "Already has card. ";
        if (alreadyHasHigherLevelCard) failingReason += "Already has higher level card. ";
        if (failingReason.length > 0) {
            console.log(`*** ${player.characterName} > ${card.name} ${card.xpCost} *** Fails because: ${failingReason}`);
        } else {
            // if (card.name==="Physical")
                // debugger;
            console.log(`*** ${player.characterName} > ${card.name} ${card.xpCost} *** Passes all checks`);
        }

    }
    // console.log(`*** ${player.characterName} > ${card.name} ${card.xpCost} ***
    // Fails because: 
    // canAffordGold: ${canAffordGold}
    // canAffordXP: ${canAffordXP}
    // meetsLevelRequirement: ${meetsLevelRequirement}
    // canTradeUp: ${canTradeUp}
    // alreadyHasCard: ${alreadyHasCard}
    // `);

    return canAffordGold && canAffordXP && meetsLevelRequirement && canTradeUp && !alreadyHasCard && !alreadyHasHigherLevelCard;
}
export function canPlayerAcquireDefenseCard(player: PlayerCharacterInMemoryType, card: DefenseCard): boolean {
    const alreadyHasCard = player.defenseCards && player.defenseCards.some(playerCard => playerCard.commandString === card.commandString);

    const playerUnusedGold = player.earnedGold - getGoldUsed(player);
    const canAffordGold = card.goldCost <= playerUnusedGold;

    const playerLevel = getPlayerLevel(player);
    const meetsLevelRequirement = card.minLevel <= playerLevel;


    const canAffordXP = 1 <= player.earnedXP - getXPUsed(player);

    const canTradeUp = card.xpCost <= 1 || findOneLevelLowerDefenseCard(player, card)!==undefined;
    const canAcquire = canAffordGold && meetsLevelRequirement && !alreadyHasCard && canAffordXP && canTradeUp;
    return canAcquire;
}

export function canPlayerAcquireEnhancement(player: PlayerCharacterInMemoryType, enhancement: ActionCardEnhancement): boolean {
    const playerUnusedGold = player.earnedGold - getGoldUsed(player);
    const canAffordGold = enhancement.goldCost <= playerUnusedGold;

    const playerLevel = getPlayerLevel(player);    
    const meetsLevelRequirement = enhancement.minLevel <= playerLevel;
    const reusableCards = getPlayerReusableCards(player, playerLevel);
    const meetsDisciplineRequirement = enhancement.applicableToDisciplines === undefined || 
        enhancement.applicableToDisciplines.some(discipline => reusableCards.some(card => discipline===card.discipline && !card.enhancements?.some(enhancementInner => enhancement.name === enhancementInner.name)));

    return canAffordGold && meetsLevelRequirement && meetsDisciplineRequirement;
}
export function canPlayerAcquireDiscard(player: PlayerCharacterInMemoryType, discard: DiscardableCard): boolean {
    const playerUnusedGold = player.earnedGold - getGoldUsed(player);
    const canAffordGold = discard.goldCost <= playerUnusedGold;

    const playerLevel = getPlayerLevel(player);    
    const meetsLevelRequirement = discard.minLevel <= playerLevel;

    return canAffordGold && meetsLevelRequirement;
}

export function canPlayerAcquireLife(player: PlayerCharacterInMemoryType): boolean {
    const playerUnusedXP = player.earnedXP - getXPUsed(player);
    const canAffordXP = 1 <= playerUnusedXP;
    const meetsLevelRequirement = player.addedLife < getPlayerLevel(player);

    return canAffordXP && meetsLevelRequirement;
}

export function getAcquireableReusableCards(player: PlayerCharacterInMemoryType): ReusableCard[] {
    const levelAppropriateReusableCards = 
        getLevelAppropriateCards(AllSkillCardFunctions, getPlayerLevel(player)).concat(
        getLevelAppropriateCards(AllReusableCardFunctions, getPlayerLevel(player)));

    const filtered = levelAppropriateReusableCards.filter(card => canPlayerAcquireReusableCard(player, card));
    return filtered;
}
export function getAcquireableDefenseCards(player: PlayerCharacterInMemoryType): DefenseCard[] {
    const levelAppropriateDefenseCards = getAllDefenseCards(getPlayerLevel(player));

    const filtered = levelAppropriateDefenseCards.filter(card => canPlayerAcquireDefenseCard(player, card));
    return filtered;
}

export function getAcquireableEnhancements(player: PlayerCharacterInMemoryType, allEnhancements: ActionCardEnhancement[]): ActionCardEnhancement[] {
    return allEnhancements.filter(enhancement => canPlayerAcquireEnhancement(player, enhancement));
}
export function getAcquirableDiscardableCards(player: PlayerCharacterInMemoryType): DiscardableCard[] {
    // For each level of the player, check whether they have enough gold, if so, check the next level up
    const acquirableDiscardableCards = [] as DiscardableCard[]; 
    const playerLevel = getPlayerLevel(player);
    const MAX_ITEMS = playerLevel+1;
    // Start at player level then go down, and we'll only print MAX_ITEMS worth.
    for (let level = playerLevel; level >= 1 && acquirableDiscardableCards.length < MAX_ITEMS; level--) {
        const cards = AllDiscardableCardFunctions.map(cardFunction => cardFunction(level as PositiveNumberTo20));
        const filtered = cards.filter(card => canPlayerAcquireDiscard(player, card));
        acquirableDiscardableCards.push(...filtered);
    }
    // If we have too many items, randomize what's available
    if (acquirableDiscardableCards.length > MAX_ITEMS) {
        const randomIndices = [] as number[];
        while (randomIndices.length < MAX_ITEMS) {
            const randomIndex = Math.floor(Math.random() * acquirableDiscardableCards.length);
            if (!randomIndices.includes(randomIndex)) {
                randomIndices.push(randomIndex);
            }
        }
        return randomIndices.map(index => acquirableDiscardableCards[index]);
    }
    return acquirableDiscardableCards;
}

export type AcquireableThingsType = {
    costStr: string,
    reusableCard?: ReusableCard, // bin 1
    defenseCard?: DefenseCard, // bin 1
    isLife?: boolean, // bin 2
    discardableCard?: DiscardableCard, // bin 3
    enhancementCard?: ActionCardEnhancement, // bin 3
    name: string,
};
export function getAcquirableThingsStrings(player: PlayerCharacterInMemoryType): AcquireableThingsType[] {
    const reusableCards = getAcquireableReusableCards(player);
    const enhancements = getAcquireableEnhancements(player, AllEnhancements);
    const acquirableDiscardableCard = getAcquirableDiscardableCards(player);
    const defenseCards = getAcquireableDefenseCards(player);

    const canLife = canPlayerAcquireLife(player);
    const acqThings = [] as AcquireableThingsType[];
    // const upgrades = [] as string[];
    for (const reusableCard of reusableCards) {
        // If the card costs more than 1xp, it requires a trade-up.
        if (reusableCard.xpCost <= 1) {
            acqThings.push({costStr: getReusableCardCostStr(reusableCard), name: reusableCard.name, reusableCard});
            // upgrades.push(getCardCostStr(card)+" ➡ " + card.name);
        } else {
            const lowerLevelCard = findOneLowerLevelReusableCard(player, reusableCard);
            // const lowerLevelCard = player.cards.find(playerCard => playerCard.discipline === card.discipline && getCardXPCost(playerCard) === card.xpCost - 1);
            if (!lowerLevelCard) {
                console.error("Couldn't find a lower level card to trade up for " + reusableCard.name+". This implies a bug in getAcquireableCards.");
                continue;
            }
            acqThings.push({costStr: lowerLevelCard.name + " " + lowerLevelCard.xpCost + " & "+getReusableCardUpgradeCostStr(lowerLevelCard, reusableCard), name: reusableCard.name+" "+reusableCard.xpCost, reusableCard});
            // upgrades.push("Trade in " + lowerLevelCard.name + " and "+getUpgradeCostStr(lowerLevelCard, card)+" ➡ " + card.name);
        }
    }
    for (const defenseCard of defenseCards) {
        if (defenseCard.xpCost <= 1) {
            acqThings.push({costStr: getDefenseCardCostStr(defenseCard), name: defenseCard.name, defenseCard});
            continue;
        }
        const lowerLevelCard = findOneLevelLowerDefenseCard(player, defenseCard);
        if (!lowerLevelCard) {
            console.error("Couldn't find a lower level card to trade up for " + defenseCard.name+". This implies a bug in getAcquireableCards.");
            continue;
        }
        acqThings.push({costStr: lowerLevelCard.name + " & "+getDefenseCardUpgradeCostStr(lowerLevelCard, defenseCard), name: defenseCard.name, defenseCard});
    }

    for (const enhancementCard of enhancements) {
        acqThings.push({costStr: getEnhancementCostStr(enhancementCard), name: enhancementCard.name, enhancementCard});
        // upgrades.push(getEnhancementCostStr(enhancement)+" ➡ " + enhancement.name);
    }
    if (canLife) {
        acqThings.push({costStr: "1XP", name: "❤️❤️", isLife: true});
        // upgrades.push("1XP ➡ ❤️❤️");
    }
    for (const discardableCard of acquirableDiscardableCard) {
        acqThings.push({costStr: getDiscardCardCostStr(discardableCard), name: discardableCard.name, discardableCard});
    }
    // return upgrades;
    return acqThings;
}

export function getPlayerReusableCards(player: PlayerCharacterInMemoryType, playAsLevel: XPAtLeast1): ReusableCard[] {
    const reusableCards = [] as ReusableCard[];
    for (const cardFunction of player.reusableCardFunctions) {
        let cardLevel = cardFunction.level;
        if (cardLevel > playAsLevel) {
            cardLevel = playAsLevel;
        }
        const card = cardFunction.func(cardLevel);
        reusableCards.push(card);
    }
    return reusableCards;
}