import { ChatAPIOptions, ChatStreamErrorCallback } from "./GenericChatServer";
import { StreamingCallback } from "./GenericChatServer";
import { useContext, useMemo, useRef, useState } from 'react';
import { ChatLogContext, ChatMessage2 } from '../../DecisionGraph/ChatEditor/UI/ChatLog';
import { LLMServerContext } from '../../DecisionGraph/ChatEditor/UI/SelectableLLM';
import { ChatCompletionMessageToolCall } from 'openai/resources';
import findLastIndex from 'lodash/findLastIndex';

const DEBUG = false;

const MAX_TOKENS_TO_GENERATE = 16384; /* current max tokens allowed by OpenAI. It's been pretty good in not returning wastefully long and we want it to be able to rewrite anything we give it. */

export type PreProcessUserMessageFuncType = (messages:ChatMessage2[],setTemplateComplete:()=>void,setTopicsComplete:()=>void)=>Promise<PreprocessUserMessageReturnType>;
export type ChatEditorRefFunctions = {
    isWaitingForChat: boolean;
    isWaitingForChatTemplate: boolean;
    isWaitingForChatTopics: boolean;

    // Preprocess before sending to server:
    preProcess_UserMessage: PreProcessUserMessageFuncType;

    // Post-process what comes from the server:
    postProcess_recievedMessage: (completedMessage: string) => void;
};

export type PreprocessUserMessageReturnType = {
    systemPrompt: string;
    noteIDs: string[];
    templateNoteIDs: string[];
    topicNoteIDs: string[];
    selectionNoteIDs: string[];
    includedNoteIDs: string[];
    messageProcessingExtraInfo: any;
    outputOfTopicsThinking: string;
};

export function useSendAnythingToServer(streamingWasCancelled?:()=>void, streamingHasFinished?:(success:boolean, completedMessageOrError:string)=>void) {
    const controllerRef = useRef<ReadableStreamDefaultController<any>>();
    const abortServerFetchController = useMemo(() => new AbortController(), []);
    const {callChatAPI, serverType} = useContext(LLMServerContext);

    function cancelStreaming() {
        streamingWasCancelled?.();
        if (controllerRef.current)
            controllerRef.current.close();
        // Break the connection to the server, too:
        abortServerFetchController.abort();
    }
    function sendToServerWithStreaming(messages: ChatMessage2[], chatAPIOptions?:ChatAPIOptions, callFunction?:(functionSpec:ChatCompletionMessageToolCall.Function[])=>void):Response {
        // console.log('ProChat Messages requested:', messages);
        const readableStream = new ReadableStream({
            async start(controller: ReadableStreamDefaultController<any>) {
                if (!callChatAPI) {
                    // Wait 1 second first, just in case it's still loading:
                    await new Promise(r => setTimeout(r, 1000));
                    if (!callChatAPI) {
                        alert("Sorry, we were unable to set up the server for chat. Please refresh the page and try again, or report this bug.");
                        debugger;
                        return;
                    }
                }
                controllerRef.current = controller;
                // fullOutputPrefix is used to prepend messages that won't come back from the server.
                let fullOutputPrefix = "";
                function addNewOutput(newOutput: string) {
                    if (!newOutput)
                        return;
                    const encoder = new TextEncoder();
                    const newOutputArrayBuffer = encoder.encode(newOutput).buffer;
                    try {
                        controller.enqueue(newOutputArrayBuffer);
                    } catch (e) {
                        console.error("[useSendToServer]>sendToServer> Error adding new output: ", e);
                        debugger;
                    }
                }

                const streamingCallback: StreamingCallback = function (fullOutput: string, newOutput: string, isDone: boolean): void {
                    // Convert newOutput string to an ArrayBuffer:
                    addNewOutput(newOutput);
                    if (isDone) {
                        // For some reason, when we call this, the system doesn't seem to know it's done for a while. Can't figure out why but it's probably an internal bug in ProChat.
                        if (DEBUG) {
                            console.log("[useSendToServer]>sendToServer The server is done! But it might not show in the UI for a bit due to a bug. Full output: ", fullOutputPrefix + fullOutput);
                        }
                            
                        controller.close();
                        streamingHasFinished?.(true, fullOutputPrefix + fullOutput);
                    }
                };
                const errorCallback: ChatStreamErrorCallback = function (error: string) {
                    // We tried "controller.error() on its own, but that doesn't stop nor show the error message. We have to take this into our own hands to render the error.
                    addNewOutput("```" + error + "```");
                    controller.close();
                    controller.error(error);
                    console.error("[useSendToServer]>sendToServer> Error: ", error);
                    streamingHasFinished?.(false, error);
                };
                // if (DEBUG) console.log("[useSendToServer]>sendToServer> Calling chat API with ", openAIMessages);
                // Temperature notes: It might depend on the Chat API. 0.3 seems to be good for Gemini & ChatGPT3.5 but seems too low with GPT-4.
                const newChatAPIOptions = {abortController: abortServerFetchController, temperature: serverType.defaultTemperature, max_tokens: MAX_TOKENS_TO_GENERATE, ...chatAPIOptions} as ChatAPIOptions;
                    // { temperature: serverType.defaultTemperature, max_tokens: MAX_TOKENS_TO_GENERATE, abortController: abortServerFetchController });
                /*await*/ callChatAPI(messages, streamingCallback, errorCallback, newChatAPIOptions, callFunction);
            },
        });
        return new Response(readableStream);
    }
    return { sendToServerWithStreaming, cancelStreaming, serverType };
}

/**************
 * TODO refactor this to use the above function call, to better separate out the concerns.
 * 
 * This would only use the special ChatEditorRefFunctions and then pass the rest to the above function.
 * 
 */
export function useSendToServerFromChatEditor(
    chatEditorRef: React.MutableRefObject<ChatEditorRefFunctions>,
    setLastSystemPrompt?: (systemPrompt: string) => void
) {
    const {setSelectedChatMessages} = useContext(ChatLogContext);

    function setInProgress(inProgress: boolean) {
        if (chatEditorRef.current) {
            chatEditorRef.current.isWaitingForChat = inProgress;

            // It just started or stopped, so reset these:
            chatEditorRef.current.isWaitingForChatTemplate = true;
            chatEditorRef.current.isWaitingForChatTopics = true;
        }
    }
    function streamingWasCancelled() {
        setInProgress(false);
    }
    function streamingHasFinished(succeeded:boolean, completedMessageOrError:string) {
        if (succeeded) {
            if (chatEditorRef.current.postProcess_recievedMessage)
                chatEditorRef.current.postProcess_recievedMessage(completedMessageOrError);    
        } else {
            // Failed! TODO pass the error back somehow for display to the user.
        }
        setInProgress(false);
    }
    const {cancelStreaming, sendToServerWithStreaming:sendAnythingToServer} = useSendAnythingToServer(streamingWasCancelled, streamingHasFinished);

    function addPreProcessingExtra(messages:ChatMessage2[], preprocessedUserMessage: PreprocessUserMessageReturnType) {
        const {systemPrompt, ...extra} = preprocessedUserMessage;
        // Because there's only one message, the following adds the extra to the user message instead of the system message. This isn't terrible because we could use it to skip checking next time, but it doesn't make it easy to render along with the next assistant message.
        // AND the big bug, when we call setSelectedChatMessages we somehow end up losing the one that's loading.
        const newChatMessages = [...messages];
        const systemMessageIndex = newChatMessages.findIndex((message) => message.role === 'system' && message.content);
        if (systemMessageIndex===-1) {
            // Add the system message in using the prompt:
            newChatMessages.unshift({role: 'system', content: systemPrompt, userHasMadeEdits:false});
        } else {
            // Replace the system message:
            newChatMessages[systemMessageIndex] = {role: 'system', content: systemPrompt, userHasMadeEdits:false};
        }
        const lastUserMessageIndex = findLastIndex(newChatMessages, (message) => message.role === 'user' && !!message.content);
        if (lastUserMessageIndex === -1) {
            console.error("[useSendToServer]>sendToServer> Error: Couldn't find the last user message in the chat log. This is a bug, seemingly corrupted chat messages. ", newChatMessages);
            debugger;
            return messages;
        }
        const lastUserMessage = newChatMessages[lastUserMessageIndex];
        const newLastUserMessage = { ...lastUserMessage, extra: { ...lastUserMessage.extra, ...extra} } as ChatMessage2;
        newChatMessages[lastUserMessageIndex] = newLastUserMessage;
        if (DEBUG) console.log("[useSendToServer]>sendToServer>addPreProcessingInfo Added prefix message: ", newChatMessages);
        setSelectedChatMessages(newChatMessages);
        return newChatMessages;
    }
    async function getOpenAIMessages(messages: ChatMessage2[]) {
        // Convert AntD ProChat messages to OpenAI messages. Sometimes this requires a bit of interpretation by the AI layer, e.g. if we want to test topic detection without forcing the user to type the whole input, hence the optional mapProChatMessagesToOpenAI
        let newChatMessages = messages;
        

        if (chatEditorRef.current.preProcess_UserMessage) {
            const preprocessedUserMessage = await chatEditorRef.current.preProcess_UserMessage(newChatMessages,
                function setTemplateComplete(){
                    if (chatEditorRef.current) chatEditorRef.current.isWaitingForChatTemplate=false;
                },
                function setTopicsComplete(){
                    if (chatEditorRef.current) chatEditorRef.current.isWaitingForChatTopics=false;
                }
            );
            if (setLastSystemPrompt)
                setLastSystemPrompt(preprocessedUserMessage.systemPrompt);
            if (DEBUG) console.log("[useSendToServer]>sendToServer> Will populate system prompt ", preprocessedUserMessage);

            const hasASystemMessage = newChatMessages.length > 0 && newChatMessages[0].role === 'system';
            if (!hasASystemMessage)
                newChatMessages.unshift({ role: 'system', content: preprocessedUserMessage.systemPrompt, userHasMadeEdits:false});
            else
                newChatMessages[0].content = preprocessedUserMessage.systemPrompt;

            newChatMessages = addPreProcessingExtra(newChatMessages, preprocessedUserMessage);
        } else {
            // if (DEBUG) console.log("[useSendToServer]>sendToServer> No system prompt changes happening. ", openAIMessages.length, " messages: ", openAIMessages);
        }
        return {newChatMessages};
    }

    async function sendToServerWithStreaming(messages: ChatMessage2[], chatAPIOptions?:ChatAPIOptions, callFunction?:(functionSpec:ChatCompletionMessageToolCall.Function[])=>void): Promise<{response:Response,newChatMessages:ChatMessage2[]}> {
        setInProgress(true);
        const {newChatMessages} = await getOpenAIMessages(messages);
        // if (DEBUG) console.log("[useSendToServer]>sendToServer> Calling chat API with ", openAIMessages);
        return {response:sendAnythingToServer(newChatMessages, chatAPIOptions, callFunction), newChatMessages};
    }
    return { sendToServerWithStreaming, cancelStreaming };
}


export async function getStringFromResponse(response: Response):Promise<string> {
    if (!response.body) {
        console.error("[ChatWithDraft]>submitUserMessage> Response has no body.");
        return "";
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    // Create the new assistant message:
    // const newAssistantMessage = {role: "assistant", content:""} as ChatMessage2;
    let newMessageContent = "";
    while (true) {
        const {done, value} = await reader.read();
        if (done) {
            break;
        }
        const newChunk = decoder.decode(value);
        newMessageContent += newChunk;
    }
    return newMessageContent;
}
