import { useContext, useEffect, useMemo, useState } from "react"

import { Alert, Button, Col, Drawer, Input, Row, Select, Spin, Steps } from 'antd';
import TextArea from "antd/lib/input/TextArea";
import { httpsCallable } from "firebase/functions";
import SelectNoteType from "../../Notes/SelectNoteType";
import { useAllTypeNotes, useNote } from "../../Notes/Data/NoteDBHooks";
import { Note, PartialJSONFormsObject } from "../../Notes/Data/NoteType";

import { createAjvValidator, ValidationError } from "vanilla-jsoneditor";
import { validateBulk } from "../../Notes/Data/Actions/ValidateNewNotes";
import _ from "lodash";
import SvelteJSONEditor from "../../JSONEditing/RawJSONEditing/SvelteJSONEditor";
import { getDuplicatesErrorMessage } from "../../JSONEditing/JSONSchemaBasedEditors/JSONSchemaHelpers";
import { JSONFormsObjectDiff, uploadDiffs } from "../../Notes/Data/Actions/JSONFormsObject/DiffJsonFormsObject";
import { NotesContext } from "../../Notes/Data/NotesContext";
import { useParams } from "react-router-dom";
import { useNavigateToNote } from "../Utilities/NavigateTo";
import { useCallIdeaGeneratorAI } from "../../Notes/Types/GetTypeAI";
import { useJSONFormsSchema } from "../../Notes/Data/Actions/JSONFormsObject/LoadAndSaveJSONSchemaObject";
import { FIREBASE_FIRESTORE, FIREBASE_FUNCTIONS } from "../../AppBase/App";


const { Option } = Select;
const { Step } = Steps;

const DEBUG = false;

function checkForTypes(commandStr:string, types:string[], setSelectedType:any) {
    var regex = /\w+/g;
    const ideaTextWords = commandStr.toLowerCase().match(regex);
    if (ideaTextWords)
        for (const type of types) {
            if (ideaTextWords.includes(type.toLowerCase())) {
                setSelectedType(type);
                return true;
            }
    }
    return false;
}

export function SelectTreeLocation({selected_note_id, selectedTypeNote,
    type, parent, setParent}:
    {selectedTypeNote:Note, selected_note_id:string, type:string, parent:string, setParent:any}) {
    // Get the name of the selected note:
    const note = useNote(selected_note_id);
    const selected_note_name = note?.doc_name;
    const type_id = selectedTypeNote.id;

    return <>
    {note && selectedTypeNote && /*wait until the note names are loaded until we show the choice*/
    <Select placeholder="" style={{ minWidth: '200px' }}
            value={parent}
            onChange={function(value:string){
                if (type===value) {
                    // console.log("Got event, but no change");
                    return;
                }
                setParent(value);
            }} >
            <Option key={type_id} value={type_id}>{type}</Option>
            <Option key={selected_note_id} value={selected_note_id}>{selected_note_name}</Option>
            </Select>}
    </>
}

export default function CommandResult({
    closeNowFunc,commandType,defaultCommand=""}:
    {closeNowFunc:any,commandType:string,defaultCommand?:string}) {

    const [currentStepIndex, setCurrentStepIndex] = useState(0);    

    // For Ideas:
    const [ideaText, setIdeaText] = useState("");
    const [isWaitingForIdeas, setIsWaitingForIdeas] = useState(false);
    const [isWaitingForEditCommand, setIsWaitingForEditCommand] = useState(false);
    const [editIdeasCommandText, setEditIdeasCommandText] = useState(defaultCommand);
    const remoteCommandEditText = httpsCallable(FIREBASE_FUNCTIONS, "openaifunctions_callableCommandEditText");
    const callIdeaGeneratorAI = useCallIdeaGeneratorAI();
    
    // Pick a smart default type, if possible.
    const typeNotes = useAllTypeNotes();
    const types = typeNotes && typeNotes.map(function(note:Note){return note.doc_name});
    const [selectedType, setSelectedType] = useState("");
    const selectedTypeNote = useMemo(function(){
        if (!typeNotes)
            return;
        const selectedTypeNotes = typeNotes.filter(function(note:Note){return note.doc_name===selectedType});
        if (selectedTypeNotes.length>0)
            return selectedTypeNotes[0];
        return null;
    },[typeNotes,selectedType]);

    // For validation:
    const jfsDoc = useJSONFormsSchema(selectedTypeNote?.id || "default-ignore-me-still-loading");
    const schema = jfsDoc?.['type-schema'];

    const schemaJSON = useMemo(function parseAndEnhanceSchema(){
        if (!schema)
            return null;
        const schemaJSON = JSON.parse(schema);
        // Enhance it by requiring NAME. This is important to let the AI know we need it, and for validation.
        if (!schemaJSON.properties) {
            // Is this an incomplete schema? It will crash here.
            // We should point the user towards finishing the schema.
            debugger;
        }
        if (schemaJSON.required && !schemaJSON.required.includes("name"))
            schemaJSON.required.push("name");
        if (!schemaJSON.properties.hasOwnProperty("name"))
            schemaJSON.properties["name"]={description:"Unique name for this "+selectedType,type:"string"};
        return schemaJSON;
    },[schema,selectedType]);

    // For conversion:
    const [isWaitingForConversion,setIsWaitingForConversion] = useState(false);
    const [convertedObjectStr,setConvertedObjectStr] = useState("");


    // For async validation:
    const [lastAsyncValidatedJSON, setLastAsyncValidatedJSON] = useState({});
    const [validatedDiffs, setValidatedDiffs] = useState([] as JSONFormsObjectDiff[]);
    const [uploadMessage, setUploadMessage] = useState("Uploading...");
    const [isAsynchValidatingJSON, setIsAsynchValidatingJSON] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");
    // For asking where to parent the notes:
    //@ts-ignore
    const { doc_id }:{doc_id:string} = useParams();
    const [selectedParentId, setSelectedParentId] = useState("");


    // For uploading:
    const [isUploading, setIsUploading] = useState(false);
    const notesContext = useContext(NotesContext);
    const navigateToNote = useNavigateToNote();
    
  


    useMemo(function setDefaultNoteType() {
        if (selectedType || types.length===0)
            return;
        if (editIdeasCommandText && checkForTypes(editIdeasCommandText, types, setSelectedType))
            return;
        if (checkForTypes(ideaText, types, setSelectedType))
            return;
    },[types,editIdeasCommandText,ideaText, selectedType/*If there was a place to ignore a dependency, selectedType would be in it.*/]);


    async function generateIdeas() {
        setIsWaitingForIdeas(true);

        const text = await callIdeaGeneratorAI(selectedType, editIdeasCommandText);
        if (text!=null)
            setIdeaText(text);
        setIsWaitingForIdeas(false);
    }

    
    // Handle the incoming command just once, the first time we render.
    useEffect(function handleIncomingCommand() {
        // For some reason, this is getting triggered twice... :(
        // I think React is somehow creating two copies of this component. Nothing else fits the bill.
        // Unfortunately, that means that we trigger the generateIdeas command twice, which could cost more on the server.
        // To debug this, add a debugger line in here or watch for the results.

        if (defaultCommand) {
            // If there's an incoming command, generate ideas:
            if (commandType==="Ideas" && !ideaText && !isWaitingForIdeas)
                generateIdeas();
            else if (commandType==="BulkUpload") {
                // ...
            }
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    },[defaultCommand]);


    function onClose() {
        closeNowFunc();
    };


    async function handleModifyIdeasButtonClicked() {
        setIsWaitingForEditCommand(true);
        const commandResponse = await remoteCommandEditText({
            object:ideaText,
            instruction:editIdeasCommandText});
        console.log("Result:");
        console.log(commandResponse.data);
        //@ts-ignore
        if (commandResponse.data.choices.length>0) {
            //@ts-ignore
            const text = commandResponse.data.choices[0].text as string;
            console.log(text);
            setIdeaText(text);
        }
        setIsWaitingForEditCommand(false);
    }
    async function handleUseIdeaButtonClicked() {
        setIsWaitingForConversion(true);
        setCurrentStepIndex(1); // going to the next view.

        const instruction = "Reformat into JSON using this JSON schema: "+JSON.stringify(schemaJSON);
        // Without training, this fails a high percentage of attempts. It sometimes works perfectly.
        // n=6 and n=7 seem decent.
        // This is a clear sign we would benefit from fine tuning because it would cost much less to run.
        const commandResponse = await remoteCommandEditText({object:ideaText, instruction,n:7});
        console.log("Result:");
        console.log(commandResponse.data);
        //@ts-ignore
        if (commandResponse.data.choices.length>0) {
            //@ts-ignore
            const validator = createAjvValidator({schema:schemaJSON});
            let minNumErrors = 99999999;
            let bestMatchText = null;
            //@ts-ignore
            for (const choice of commandResponse.data.choices) {
                //@ts-ignore
                const text = choice.text as string;
                // TODO validate as JSON. Repeat if it's not valid JSON nor a valid implementation of the schema.
                // TODO we should pass in an N or some sort of template to improve the chances of this working... it does work perfectly sometimes, but not others.
                // TODO store it & display it
                try {
                    const convertedObject = JSON.parse(text);
                    // Compare to the schema... if it has a lot of overlap, that's a sign it didn't convert.
                    let numSchemaProperties = 0;
                    for (const property in schemaJSON) {
                        if (convertedObject[property] && JSON.stringify(schemaJSON[property])===JSON.stringify(convertedObject[property])) {
                            numSchemaProperties++;
                        }
                    }
                    if (numSchemaProperties>=3) {
                        // No good, check another.
                        continue;
                    }
                    let errors = validator(convertedObject);
                    if (errors.length<minNumErrors) {
                        // It's got the fewest validation errors so far.
                        bestMatchText = text;
                        minNumErrors = errors.length;
                    } else if (errors.length===minNumErrors && bestMatchText/*for typescript*/ && text.length>bestMatchText.length) {
                        // It's more descriptive. More likely to be interesting/useful/more traits.
                        bestMatchText = text;
                    }
                    // TODO could check for number of properties probably redundant with length of text though...
                } catch {
                    // no good, check another choice.
                }
            }
            if (bestMatchText) {
                console.log("We found one!");
                console.log(bestMatchText);
                // We got one! Here's the best one, the least errors.
                setConvertedObjectStr(bestMatchText);
                setIsWaitingForConversion(false);
                return;
            }
            
            // Nothing found.
            setConvertedObjectStr("");
            if (window.confirm("The AI struggled to process this. Do you want to try again?")) {
                handleUseIdeaButtonClicked();
                return;
            }
        }
        setIsWaitingForConversion(false);
    }
    async function handleUploadButtonClicked() {
        setCurrentStepIndex(steps.length-1);
        setIsUploading(true);

        function setIntermediateProgress(soFar:number,total:number) {
            setUploadMessage("Uploaded "+(soFar)+"/"+total);
          }
          function setUploadDone(parentID:string,newNotesCreated:Note[], noteIDsChanged:string[]) {
            //   setIsModalOpen(false);
              // Reset any intermediate things so it can be re-opened in the initial state:
            //   setStep("Setup");
              // TODO need to empty out the object too -- but the json editor isn't controlled yet so this won't have an effect:
            //   setObjArray(null);
            setIsUploading(false);
            setUploadMessage("Upload complete!");

            onClose();
            const totalEdited = newNotesCreated.length+noteIDsChanged.length;
            if (totalEdited>1) {
                if (newNotesCreated.length>0) {
                    // We created notes. So, go to the parent of multiple notes.
                    // TODO go to the list view since we don't want to go to the actual parent...
                    navigateToNote(parentID);
                } else {
                    // Multiple notes were edited and they could be anywhere in the tree. Not sure, what do we do here?
                }
            } else {
                // One note edited/created. Go to it.
                if (newNotesCreated.length>0) {
                    navigateToNote(newNotesCreated[0].id);
                } else {
                    navigateToNote(noteIDsChanged[0]);
                }
            }
          }
          //@ts-ignore
          uploadDiffs(FIREBASE_FIRESTORE, selectedParentId || selectedTypeNote?.id, validatedDiffs, selectedType, notesContext, setIntermediateProgress,setUploadDone);
    }

  
    // TODO this doesn't seem to be using the cache of notes. Not sure why.
    // As a result, it's much slower than it needs to be. We'll accept that for now.
    async function asyncValidation(json:PartialJSONFormsObject[]) {
      // console.log("Asynch validation triggered...")
      // This takes a while -- so benefits from some lots of extra protections.
      // TODO this would go faster if we were accessing the local cache, if we know if everything's loaded...
      // But we don't really know if everything's loaded, so this is the future-proof technque, and must be implemented either way. The above would be an optimization.
      if (isAsynchValidatingJSON) {
        return;
      }
      if (_.isEqual(lastAsyncValidatedJSON,json)) {
        // don't revalidate.
        return;
      }
      setValidatedDiffs([]);
      setIsAsynchValidatingJSON(true);
      setLastAsyncValidatedJSON(json);
      // console.log("Starting checks!")
  
      setInfoMessage("..."); // already says it in the title.
      // TODO block submission here until checks are complete.
  
      const results = await validateBulk(FIREBASE_FIRESTORE, json, setInfoMessage);
      setIsAsynchValidatingJSON(false);
      if (results.hasError) {
        setErrorMessage(results.message);
      } else {
        setInfoMessage(results.message);
        setIsAsynchValidatingJSON(false);
        if (!results.hasContent || !results.diffs/*just for TypeScript*/) {
          // Nothing to do though.
        } else {
          setValidatedDiffs(results.diffs);
        }
      }
    }
    const asyncValidationDebounced = _.debounce(asyncValidation,1000);

    /* TODO look up selectable items of certain types:
        (a) the same type as this one (e.g. previous passages, ordered)
        (b) selectable types in this schema (e.g. characters used in the passage)
            TODO find the same place we pull the list in -- in GenericObjectEditor & ...

    */
   if (DEBUG) console.log("[CommandResult] schemaJSON=",schemaJSON);


    const steps = [];
    if (commandType==="Ideas") {
        steps.push({
            title: 'Ideas',
            disabled: isUploading,
            content: <>
            {!isWaitingForIdeas && !isWaitingForEditCommand && !isWaitingForConversion && <>
            <Input.Group size="large">
                <Row gutter={8}>
                    <Col span={3}></Col>
                    <Col span={18}>
                        TODO selectable list of items depending on schema here
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <Row gutter={8}>
                    <Col span={3}>📝 Change ideas</Col>
                    <Col span={18}>
                        <TextArea placeholder="Change the ..." autoSize
                            style={{ width: '100%' }} value={editIdeasCommandText}
                            onPressEnter={handleModifyIdeasButtonClicked}
                            onChange={function(e:any) {
                                const newString = e.target.value;
                                setEditIdeasCommandText(newString);}}
                        />
                    </Col>
                    <Col span={3}><Button type="default" disabled={editIdeasCommandText.length===0} onClick={handleModifyIdeasButtonClicked}>Change them</Button></Col>
                </Row>
                <Row gutter={8}>
                    <Col span={3}>💡 1 Idea </Col>
                    <Col span={21}>
                    <TextArea rows={10}
                        style={{ width: '100%' }}
                        value={ideaText} onChange={function(e:any) {
                        const newString = e.target.value;
                        setIdeaText(newString);}} />
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={3}>Type to create</Col>
                    <Col span={21}>
                        <SelectNoteType onChange={setSelectedType} defaultValue={selectedType} includePlainNote={true}/>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={3}>{/*Tips: Edit down to one idea*/}</Col>
                    <Col span={21}>
                        <br/>
                        <Button type={convertedObjectStr?"default":"primary"} onClick={handleUseIdeaButtonClicked} disabled={!selectedType || !schema}>Next: Convert to JSON</Button>
                        {!selectedType && <>Select a type.</>}
                        {selectedType && !schema && <>Loading schema...</>}
                        <br/>
                    </Col>
                </Row>
                </Input.Group>
            </>}
  
            </>,
        });
    }
    // Other steps happen in all versions of edit & upload
    let willCreateNewItems = false;
    if (validatedDiffs && validatedDiffs.length>0) {
        for (const diff of validatedDiffs) {
            if (!diff.note_id) {
                willCreateNewItems=true;
                break;
            }
        }
    }
    steps.push({
        title: "Validate",
        disabled: (isUploading  || (commandType==="Ideas" && !convertedObjectStr) || !schema),
        content: <>
                {/* If we start on this page, we need to ask for the type */}
                {commandType==="BulkUpload" && <Row gutter={8}>
                    <Col span={3}>Type to create</Col>
                    <Col span={21}>
                        <SelectNoteType onChange={setSelectedType} defaultValue={selectedType} includePlainNote={true}/>
                    </Col>
                </Row>}
                {!isWaitingForConversion && (commandType==="BulkUpload" || (commandType==="Ideas" && convertedObjectStr)) && schema && <>
                    <div className="my-svelte-editor">
                        <SvelteJSONEditor
                            content={{text:convertedObjectStr}}
                            onChange={function onJSONChange(obj:any){
                                // TODO could we skip this onChange and just do everything in validation below?
                                // It would be more streamlined, but the function names wouldn't be quite right so it's
                                // a tiny bit sketchy.
                                // Validation: check if it's an array, check if the items match the schema
                                // let jsonObj;
                                if (obj.text) {
                                    setConvertedObjectStr(obj.text);
                                    // Error message should be included in the box I think.
                                    try {
                                        /*jsonObj = */JSON.parse(obj.text);
                                    } catch {
                                        // This error is shown by the editor, no need for a duplicate.
                                        setErrorMessage("");
                                        setInfoMessage("");
                                        return;
                                    }
                                } else {
                                    setConvertedObjectStr(JSON.stringify(obj.json));
                                    // jsonObj=obj.json;
                                }
                                // TODO check if this works using the validation below instead.
                                return;
                            }}
                        validator={function onValidation (json: any): ValidationError[]{
                            // We can do some validation, even without a schema.
                            if (!json) {
                                return []; // hopefully alreayd displayed?
                            }
                            if (!Array.isArray(json)) {
                                // Well, we can see if it's a single valid object later...
                                json = [json];
                                // setErrorMessage("JSON must be an array.");
                                // setInfoMessage("");
                                // return [];
                            }
                            if (json.length===0) {
                                // Nothing to save
                                setErrorMessage("Add at least one item.");
                                setInfoMessage("");
                                return [];
                            }
                            if (!schema) {
                                // No additional validation we can do right now
                                setErrorMessage("Before using this dialog, you need to create an object schema in the type '"+selectedType+"'.");
                                return [];
                            }
                            const validator = createAjvValidator({schema:JSON.parse(schema)});
                            let errors = [] as ValidationError[];
                            for (let i=0; i<json.length; i++) {
                                const item = json[i];
                                // TODO the error message doesn't give a hint as to which item is broken.
                                // We need to add that in, perhaps in our own error message.
                            
                                //@ts-ignore
                                errors = errors.concat(validator(item));
                                if (errors.length>0) {
                                    // just report this first one.
                                    setErrorMessage("The error above is for item index="+(i)+".");
                                    return errors;
                                }
                            }
                            // Confirmed from the above:
                            json = json as PartialJSONFormsObject[];
                            const duplicatesMessage=getDuplicatesErrorMessage(json.map(function(item:PartialJSONFormsObject){return item.name}),"Before you can upload, you must fix duplicate names");
                            if (duplicatesMessage.length>0) {
                                setErrorMessage(duplicatesMessage);
                                return [];
                            }
                            setErrorMessage(""); // we checked, there's nothing wrong.
                            asyncValidationDebounced(json);
                            return errors;
                        }}
                        />
                        {/* @ts-ignore */}
                        </div>
                        {errorMessage && <Alert
                            message="Error"
                            description={errorMessage}
                            type="error"
                            showIcon
                            closable
                            />}
                        {isAsynchValidatingJSON && <Alert
                            message="Checking for changes"
                            description={infoMessage}
                            type="info"
                            icon={<Spin/>}
                            showIcon
                            closable
                            />}
                        {!isAsynchValidatingJSON && infoMessage && <Alert
                            message="What will be changed"
                            description={infoMessage}
                            type="info"
                            showIcon
                            closable
                            />}
                        <br/>
                        {willCreateNewItems && selectedType && doc_id && selectedTypeNote && selectedTypeNote.id!==doc_id && /*we don't offer a choice unless there is a choice:*/
                            <>&nbsp; &nbsp; &nbsp; Place in the tree under: 
                                <SelectTreeLocation selectedTypeNote={selectedTypeNote} selected_note_id={doc_id} type={selectedType}
                                    parent={selectedParentId} setParent={setSelectedParentId}/> &nbsp;
                            </>
                        }
                        <Button key="submit" type="primary" onClick={handleUploadButtonClicked}
                            disabled={!selectedType || errorMessage!=="" || !convertedObjectStr || validatedDiffs.length===0}>
                            Upload
                            </Button>
                        </>}</>,
    });
    steps.push({
        title: 'Upload',
        disabled: true,
        content: <>
            {uploadMessage}
        </>
    });


  return (
    <>
      <Drawer
        title={<Steps
            type="navigation"
            size="small"
            current={currentStepIndex}
            onChange={setCurrentStepIndex}
            className="site-navigation-steps"
            >
            {steps.map(item => (
                <Step key={item.title} title={item.title} disabled={item.disabled}/>
            ))}
        </Steps>
}
        placement="right"
        width="98%"
        onClose={onClose}
        open={true}
        maskClosable={false}
      >
        <Spin size="large" spinning={isWaitingForIdeas || isWaitingForEditCommand || isWaitingForConversion || isUploading}>
            {steps[currentStepIndex].content}
        </Spin>
    </Drawer>
    </>
  );
};