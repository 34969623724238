import { PreprocessUserMessageReturnType, useSendAnythingToServer } from '../../../ServerConnection/LLMServer/SendChatToServerHook';
import { ChatEditorRefFunctions } from '../../../ServerConnection/LLMServer/SendChatToServerHook';
import { useContext } from "react";
import { uniq } from "lodash";
import { NotesContext } from "../../../Notes/Data/NotesContext";
import { Note } from "../../../Notes/Data/NoteType";
import { useLinkToNote_ForEditor } from "../../Utilities/NavigateTo";
import { convertNoteToMarkdown } from "../../../ServerConnection/LLMServer/MarkdownConverter";
import { estimateTokensFromString } from "../../../ServerConnection/LLMServer/LLMTokenCounter";
import useTopicDetectionV7Command, { getTopicInputV7FromMentionables, getTopicInputV7FromNotes } from "../../../ServerConnection/LLMServer/TopicDetectionAIV7";
import { ChatMessage2 } from "./ChatLog";
import { LLMServerContext } from "./SelectableLLM";
import { ChatWithDraft } from "./ChatWithDraft";
import { detectTemplate } from "../../../ServerConnection/LLMServer/DetectTemplateV8";
import { getNoteTypeWithSchemaFor } from "../../../Extensions/ExtensionsFramework/ExtensionsSchemas";
import { NoteHierarchyContext } from "../../../Notes/UIs/NoteInformationComponents/NoteHierarchyProvider";
import { extractNoteIDsFromDocData, NoteHierarchy, startLoadingNoteHierarchy, TopLevelNoteHierarchy } from "../../../Notes/UIs/NoteInformationComponents/NoteHierarchy";
import { isATemplateOrType } from "../../../Extensions/ExtensionsFramework/IsATemplate";
import { PinnedJSONFormsContext, SelectedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { ExtensionNoteTemplateType } from '../../../Extensions/ExtensionsFramework/ExtensionsList';
import { getJSONFormsObject } from '../../../Notes/Data/Actions/JSONFormsObject/LoadAndSaveJSONFormsObject';
import { getNoteNameAndEmoji } from '../../../Notes/NotesTree/TreeUtilities/CreateTagTreeNodes';

const DEBUG = false;
const USE_TEMPLATE_DETECTION_V8 = true;

type NotePrompt = {
    // From note:
    doc_name: string;
    doc_id: string;
    type: string;
    emoji: string;

    // Converted from note:
    markdownPrompt: string;

    // Context about how it was mentioned:
    isTemplate: boolean;
}

function getLastUserMessage(selectedChatMessages: ChatMessage2[]) {
    const userMessages = selectedChatMessages.filter((message) => message.role === "user" && message.content);
    if (userMessages.length === 0) {
        console.error("[ChatEditorV2OnNotePage]>useLastUserMessage> BUG: No user messages found. This should not happen.");
        debugger;
    }
    const lastUserChatMessage = userMessages[userMessages.length - 1];
    const lastUserMessage = lastUserChatMessage.content as string;    
    if (!lastUserMessage) {
        console.error("[ChatEditorV2OnNotePage]>useLastUserMessage> BUG: No user input found. This should not happen.");
        debugger;
    }
    // console.log("[ChatEditorV2OnNotePage]>useLastUserMessage> lastUserMessage is \"", lastUserMessage, "\" from ", lastUserChatMessage, " in ", selectedChatMessages);

    const noteIDsMentionedInChat = [] as string[];
    if (lastUserChatMessage.draftRawContent) {
        extractNoteIDsFromDocData(lastUserChatMessage.draftRawContent, (type, noteId) => {
            noteIDsMentionedInChat.push(noteId);
        });
    }
    // Add in the note IDs from the previous messages, pulled from ChatMessage2.extra.noteIDs
    let noteIDsFromPreviousChats = [] as string[];
    for (const message of selectedChatMessages) {
        if (message.extra && message.extra.noteIDs) {
            noteIDsFromPreviousChats.push(...message.extra.noteIDs);
        }
    }
    // Make the list unique, after removing noteIDsMentionedInChat:
    noteIDsFromPreviousChats = noteIDsFromPreviousChats.filter((noteID) => !noteIDsMentionedInChat.includes(noteID));
    noteIDsFromPreviousChats = uniq(noteIDsFromPreviousChats);

    // Get the last mentioned template(s) -- it may be in an earlier user message, but we should take the last message that contains a template.
    let templateNoteIDsFromPreviousChats = [] as string[];
    for (const message of selectedChatMessages) {
        if (message.extra && message.extra.templateNoteIDs) {
            templateNoteIDsFromPreviousChats = message.extra.templateNoteIDs;
        }
    }
    return {lastUserMessage, noteIDsMentionedInChat, noteIDsFromPreviousChats, templateNoteIDsFromPreviousChats};
}

function getUniqMarkdownSystemPrompt(systemPrompts: NotePrompt[]) {
    return uniq(systemPrompts.map(({ markdownPrompt }) => markdownPrompt)).join("\n\n").trim();
}

function useLoadAndReturnNoteMarkdown() {
    const notesContext = useContext(NotesContext);
    const linkToNote = useLinkToNote_ForEditor();
    const {extensions} = useContext(NoteHierarchyContext);

    async function getNotePrompt(note: Note, isTemplate: boolean, templatesWithSchemas:ExtensionNoteTemplateType<any>[], numObjsWithTemplateSchema:number[]): Promise<NotePrompt> {
        let markdownPrompt = await convertNoteToMarkdown(note, notesContext, linkToNote, extensions);
        if (isTemplate) {
            const noteTemplateType = getNoteTypeWithSchemaFor(extensions, note);
            if (noteTemplateType && noteTemplateType.schema) {
                templatesWithSchemas.push(noteTemplateType);
                numObjsWithTemplateSchema.push(0);
                const schema = noteTemplateType?.schema;
                const schemaString = "# Schema for " + note.doc_name + "\n\n```json\n" + JSON.stringify(schema) + "\n```";
                if (markdownPrompt.trim().length > 0)
                    markdownPrompt = markdownPrompt + "\n\n" + schemaString;
                else
                    markdownPrompt = schemaString;
            }
        } else if (note.template_doc_id) {
            // Check if this note has a template that's in our list of templates with schemas:
            const templateIndex = templatesWithSchemas.findIndex((template: ExtensionNoteTemplateType<any>) => template.template_doc_ids?.includes(note.template_doc_id!));
            const template = templatesWithSchemas[templateIndex];
            if (template) {
                // Include the JSON from this note in the markdown.
                const obj = await getJSONFormsObject( note.id);
                if (obj) {
                    numObjsWithTemplateSchema[templateIndex]++;
                    const schemaString = "\n\n```json\n" + JSON.stringify(obj) + "\n```";
                    if (markdownPrompt.trim().length > 0)
                        markdownPrompt = markdownPrompt + "\n\n" + schemaString;
                    else
                        markdownPrompt = schemaString;
                }
            }
        }
        return { doc_name: note.doc_name, markdownPrompt, doc_id: note.id, emoji: note.emoji, isTemplate, type: note.type } as NotePrompt;
    }

    return getNotePrompt;
}

function organizeNotesInTopLevelNoteHierarchy(topLevelNoteHierarchy:TopLevelNoteHierarchy, noteIDsAlreadyIncluded:string[]) {
    // Parse the hierarchy to get the list of always included, mentionables, and templates.
    let alwaysIncludedIDs = [] as string[];
    let mentionableTopicsIDs = [] as string[];
    let mentionableTemplatesIDs = [] as string[];
    function parseNode(noteHierarchy:NoteHierarchy, isAlwaysIncluded:boolean) {
        // if (noteHierarchy.note.id==="3939b5b7-e532-40fb-ac8e-313336d7f170")
            // debugger;
        if (!topLevelNoteHierarchy)
            throw new Error("This line is not possible to reach, but TypeScript seems to require it.");
        // if (noteIDsAlreadyIncluded.includes(noteHierarchy.note.id)) {
        //     return;
        // }
        if (isAlwaysIncluded) {
            alwaysIncludedIDs.push(noteHierarchy.note.id);
            for (const noteID of noteHierarchy.fullyIncludedNoteIDs) {
                const nextNoteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[noteID];
                if (!nextNoteHierarchy) {
                    debugger;
                    continue;
                }
                parseNode(nextNoteHierarchy, true);
            }
            for (const noteID of noteHierarchy.linkedNoteIDs) {
                const nextNoteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[noteID];
                if (!nextNoteHierarchy) {
                    debugger;
                    continue;
                }
                parseNode(nextNoteHierarchy, false);
            }
            for (const noteID of noteHierarchy.editContextNotes) {
                const nextNoteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[noteID];
                parseNode(nextNoteHierarchy, false);
            }
        } else if (isATemplateOrType(noteHierarchy.note))
            mentionableTemplatesIDs.push(noteHierarchy.note.id);
        else
            mentionableTopicsIDs.push(noteHierarchy.note.id);
    }
    for (const noteHierarchy of topLevelNoteHierarchy.topNoteHierarchies) {
        parseNode(noteHierarchy, true);
    }
    // It's possible to get duplicates.
    // Case 1: a note became top level that was previously mentionable
    // Case 2: a note in noteIDsAlreadyIncluded will need to get included in alwaysIncludedIDs so we can what's referred to within it
    alwaysIncludedIDs = uniq(alwaysIncludedIDs);
    mentionableTopicsIDs = uniq(mentionableTopicsIDs).filter((id:string) => !alwaysIncludedIDs.includes(id));
    mentionableTemplatesIDs = uniq(mentionableTemplatesIDs).filter((id:string) => !alwaysIncludedIDs.includes(id));
    return {alwaysIncludedIDs, mentionableTopicsIDs, mentionableTemplatesIDs};
}

function useGetTemplateFrom() {
    const {serverType} = useContext(LLMServerContext);
    const {sendToServerWithStreaming} = useSendAnythingToServer();
    const {topLevelNoteHierarchy} = useContext(NoteHierarchyContext);
    const getNotePrompt = useLoadAndReturnNoteMarkdown();


    async function getTemplateFrom(mentionableTemplatesIDs:string[], lastUserMessage:string, templatesWithSchemas:ExtensionNoteTemplateType<any>[], numObjsWithTemplateSchema:number[], templateNoteIDsFromPreviousChats:string[]): Promise<{templateNote:Note|undefined, isEditingTemplate:boolean, templateMarkdownPromise:Promise<NotePrompt>|null}> {
        if (!topLevelNoteHierarchy)
            return {templateNote:undefined, isEditingTemplate:false, templateMarkdownPromise:null};
        if (!serverType) {
            console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: Server type not found. This should not happen.");
            debugger;
            return {templateNote:undefined, isEditingTemplate:false, templateMarkdownPromise:null};
        }
        const mentionableTemplates = mentionableTemplatesIDs.map((id:string) => topLevelNoteHierarchy.noteHierarchiesById[id].note);
        if (!serverType.supportsFunctions || !USE_TEMPLATE_DETECTION_V8)
            return {templateNote:undefined, isEditingTemplate:false, templateMarkdownPromise:null};
        const templateDetectionPromise = detectTemplate(mentionableTemplates, lastUserMessage, sendToServerWithStreaming);
        if (DEBUG) console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> calling template detection V8 API with ",mentionableTemplates, lastUserMessage);

        ///////////////////////////////////////
        // Finish template detection
        // (finish first because it will usually execute faster)
        let templateNote = undefined as Note|undefined;
        let isEditingTemplate = false;
        const templateDetected = await templateDetectionPromise;
        // console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> template v8 detection templateDetected is ",templateDetected);
        let templateMarkdownPromise = null as Promise<NotePrompt>|null;
        if (templateDetected && templateDetected.type!=="none") {
            // Make sure it's a real note:
            templateNote = mentionableTemplates.find((note:Note) => note.doc_name === templateDetected?.template);
            if (!templateNote) {
                console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: Template detected, but the note wasn't found in the mentionable notes. This should not happen.");
                // debugger;
            } else {
                // Put the template first.
                templateMarkdownPromise = getNotePrompt(templateNote,true,templatesWithSchemas,numObjsWithTemplateSchema);
                if (templateDetected.type==="edit")
                    isEditingTemplate=true;
            }
        }
        if (templateNoteIDsFromPreviousChats.length>0 && !templateNote) {
            // TODO This pulls one template from the previous chat. TODO we want to eventually support multiple templates so don't change the incoming variable, just the output of this function.
            templateNote = mentionableTemplates.find((note:Note) => note.id === templateNoteIDsFromPreviousChats[0]);
            if (!templateNote) {
                console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: Template from previous chat not found in mentionable notes. This should not happen.");
                // debugger;
            } else
                templateMarkdownPromise = getNotePrompt(templateNote, true, templatesWithSchemas, numObjsWithTemplateSchema);
        }
        return {templateNote, isEditingTemplate, templateMarkdownPromise} as {templateNote:Note|undefined, isEditingTemplate:boolean, templateMarkdownPromise:Promise<NotePrompt>|null};
    }
    return getTemplateFrom;
}

function useGetTopicsFrom() {
    const getNotePrompt = useLoadAndReturnNoteMarkdown();
    const topicDetectionV7Command = useTopicDetectionV7Command();
    const jsonFormsContext = useContext(PinnedJSONFormsContext);


    async function getTopicsFrom(mentionableTopicsIDs:string[], lastUserMessage:string, templatesWithSchemas:ExtensionNoteTemplateType<any>[], numObjsWithTemplateSchema:number[], topLevelNoteHierarchy:TopLevelNoteHierarchy): Promise<{promptPromises:Promise<NotePrompt>[],outputOfTopicsThinking:string}> {
        const promptPromises = [] as Promise<NotePrompt>[];
        let outputOfTopicsThinking = "";
        if (!topLevelNoteHierarchy)
            return {promptPromises, outputOfTopicsThinking};
        const mentionableTopicNotes = mentionableTopicsIDs.map(function (id:string) {
            if (topLevelNoteHierarchy.noteHierarchiesById[id])
                return topLevelNoteHierarchy.noteHierarchiesById[id].note;
            else {
                console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: mentionable topic note not found in hierarchy. This probably means it was in the other hierarchy used for notes included in chats, we should merge them..");
                return undefined;
            }
        }).filter((note:Note|undefined) => note!==undefined) as Note[];
        let topicsInputV7 = getTopicInputV7FromNotes(jsonFormsContext.note, jsonFormsContext.jsonSchemaNote);
        topicsInputV7 = getTopicInputV7FromMentionables(mentionableTopicNotes, topicsInputV7);
        topicsInputV7.userInput = lastUserMessage;
        // console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> calling topics detection V7 API with ",topicsInputV7);
        const topicsOutputV7 = await topicDetectionV7Command(topicsInputV7);

        if (!topicsOutputV7.succeeded) {
            debugger;
        } else if (topicsInputV7.topics) {
            if (DEBUG) console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> topicsOutputV7 is ",topicsOutputV7);
            for (const topic of topicsOutputV7.topics as string[]) {
                let notes = mentionableTopicNotes.filter((note:Note) => note.doc_name === topic);
                if (notes.length===0) {
                    // It seems common that it gives us topics with extra spaces in the name before and after.
                    notes = mentionableTopicNotes.filter((note:Note) => note.doc_name === topic.trim());
                    if (notes.length===0) {
                        // TODO we might check the full hierarchy for this topic that the user mentioned.
                        console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> Warning: No notes found for topic: '"+topic+"'.");
                        continue;
                    }
                }
                // This is in the notesMentionable list, and it's a topic. So we load it and process it.
                const promptPromise = getNotePrompt(notes[0], false, templatesWithSchemas, numObjsWithTemplateSchema);
                promptPromises.push(promptPromise);
            }
            outputOfTopicsThinking+=" "+topicsOutputV7.logic;
        }
        return {promptPromises, outputOfTopicsThinking};
    }
    return getTopicsFrom;
}

export default function ChatEditorWithNotePreprocessing({chatEditorRef}:{chatEditorRef:React.MutableRefObject<ChatEditorRefFunctions>}) {
    const notesContext = useContext(NotesContext);
    const {serverType} = useContext(LLMServerContext);

    const getTemplateFrom = useGetTemplateFrom();
    const getTopicsFrom = useGetTopicsFrom();
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const pinnedJSONFormsContext = useContext(PinnedJSONFormsContext);
    let jsonFormsContext;
    if (!pinnedJSONFormsContext.hasNote) {
        jsonFormsContext = selectedJsonFormsContext;
    } else {
        jsonFormsContext = pinnedJSONFormsContext;
    }
    const getNotePrompt = useLoadAndReturnNoteMarkdown();

    const {topLevelNoteHierarchy, extensions} = useContext(NoteHierarchyContext);
    async function preprocessUserMessageV9(messages: ChatMessage2[], setTemplateComplete: () => void, setTopicsComplete: () => void): Promise<PreprocessUserMessageReturnType> {
        if (!topLevelNoteHierarchy)
            return {} as PreprocessUserMessageReturnType;

        const { lastUserMessage, noteIDsMentionedInChat, noteIDsFromPreviousChats, templateNoteIDsFromPreviousChats } = getLastUserMessage(messages);

        // Finish loading before adding on the new stuff. Slower, but more reliable because it avoids race conditions. (TODO we might be able to optimize this into a single wait for the second one, potentially)
        if (!topLevelNoteHierarchy.isLoaded)
            await topLevelNoteHierarchy.finishLoadingPromise;
        const topLevelNoteHierarchy2 = startLoadingNoteHierarchy(noteIDsMentionedInChat, notesContext, undefined, topLevelNoteHierarchy);
        // console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> lastUserMessage is ",lastUserMessage);
        const templatesWithSchemas = [] as ExtensionNoteTemplateType<any>[];
        const numObjsWithTemplateSchema = [] as number[];

        const noteIDsAlreadyIncluded = [...noteIDsMentionedInChat, ...noteIDsFromPreviousChats];
        const { alwaysIncludedIDs, mentionableTopicsIDs, mentionableTemplatesIDs } = organizeNotesInTopLevelNoteHierarchy(topLevelNoteHierarchy2, noteIDsAlreadyIncluded);

        ///////////////////////////////////////
        // Template detection - Start parallel processing
        const getTemplateFromPromise = getTemplateFrom(mentionableTemplatesIDs, lastUserMessage, templatesWithSchemas, numObjsWithTemplateSchema, templateNoteIDsFromPreviousChats);

        ///////////////////////////////////////
        // Topic detection - Start parallel processing
        const getTopicsFromPromise = getTopicsFrom(mentionableTopicsIDs, lastUserMessage, templatesWithSchemas, numObjsWithTemplateSchema, topLevelNoteHierarchy2);

        ///////////////////////////////////////
        // Get ready to finish up the system prompts
        const notePromptPromises = [] as Promise<NotePrompt>[];

        // TODO reorder these so that the ones explicitly mentioned are first, and that way we can remove the last ones if we run out of space.
        // That means the order should be: noteIDsMentionedInChat, templateNote, alwaysIncludedIDs, noteIDsFromPreviousChats, promptPromisesFromTopics

        ///////////////////////////////////////
        // Add alwaysIncludedIDs to the promptPromises
        for (const noteID of alwaysIncludedIDs) {
            let note;
            if (topLevelNoteHierarchy2.noteHierarchiesById[noteID])
                note = topLevelNoteHierarchy2.noteHierarchiesById[noteID].note;
            else {
                console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: noteID ",noteID," not found in either hierarchy. This should not happen.");
                continue;
            }
            const promptPromise = getNotePrompt(note, false, templatesWithSchemas, numObjsWithTemplateSchema);
            notePromptPromises.push(promptPromise);
        }
        // Add noteIDsMentionedInChat to the promptPromises
        for (const noteID of noteIDsMentionedInChat) {
            const loadNoteMentioned = async ()=>{
                const note = await notesContext.loadNoteID(noteID,true);
                const notePrompt = await getNotePrompt(note, false, templatesWithSchemas, numObjsWithTemplateSchema);
                return notePrompt;
            };
            const notePromptPromise = loadNoteMentioned();
            notePromptPromises.push(notePromptPromise);
        }
        
        // Add noteIDsFromPreviousChats
        for (const noteID of noteIDsFromPreviousChats) {
            const loadNoteFromPreviousChat = async () => {
                const note = await notesContext.loadNoteID(noteID, true);
                const notePrompt = await getNotePrompt(note, false, templatesWithSchemas, numObjsWithTemplateSchema);
                return notePrompt;
            };
            const notePromptPromise = loadNoteFromPreviousChat();
            notePromptPromises.push(notePromptPromise);
        }

        ///////////////////////////////////////
        // Finish template detection
        // Finish first because it will usually execute faster, and we want to make the template the first in the list
        const { templateNote, isEditingTemplate, templateMarkdownPromise } = await getTemplateFromPromise;
        if (templateMarkdownPromise) {
            notePromptPromises.unshift(templateMarkdownPromise!);
        }
        setTemplateComplete();
        const templateNotMentionedNoteIDs = mentionableTemplatesIDs.filter((id: string) => id !== templateNote?.id);

        ///////////////////////////////////////
        // Finish topic detection
        const {promptPromises:promptPromisesFromTopics, outputOfTopicsThinking} = await getTopicsFromPromise;
        notePromptPromises.push(...promptPromisesFromTopics);
        
        ///////////////////////////////////////
        // Assemble prompts
        // Wait for and add all the prompts to the systemPrompts
        const notePrompts = [] as NotePrompt[];
        const promptResults = (await Promise.all(notePromptPromises)).filter((result:NotePrompt|null) => result!==null) as NotePrompt[];
        setTopicsComplete(); // this is where we're sure we're finished loading the topics.
        const topicNoteIDs = uniq((await Promise.all(promptPromisesFromTopics)).map(({doc_id}) => doc_id));
        const topicNotMentionedNoteIDs = mentionableTopicsIDs.filter((id:string) => topicNoteIDs.indexOf(id)<0);

        promptResults.forEach((prompt:NotePrompt, index:number) => {
            if (prompt && notePrompts.findIndex((notePrompt:NotePrompt) => notePrompt.doc_id === prompt.doc_id)<0)
                notePrompts.push(prompt);
        });


        // If there's too much in the context, 
        let tokensUsed = estimateTokensFromString(getUniqMarkdownSystemPrompt(notePrompts));
        while (tokensUsed>serverType.contextLength-5000) {
            // Remove system prompts from last to first, until we have some space left.
            const removedPrompt = notePrompts.pop();
            if (!removedPrompt) {
                console.error("[ChatEditorV2OnNotePage]>preProcess_UserMessage> BUG: We ran out of prompts to remove, but we still have too many tokens.");
                break;
            }
            tokensUsed = estimateTokensFromString(getUniqMarkdownSystemPrompt(notePrompts));
        }

        let noteIDs = uniq(notePrompts.map(({doc_id}) => doc_id));
        if (templateNote) {
            // If there's a template, make that first in the list of references.
            noteIDs = noteIDs.filter((id:string) => id!==templateNote.id);
            noteIDs.unshift(templateNote.id);
            
            for (let i=0; i<templatesWithSchemas.length; i++) {
                const numObs = numObjsWithTemplateSchema[i];
                const template = templatesWithSchemas[i];
                
                if (numObs<3 && tokensUsed<serverType.contextLength-5500) {
                    console.log("[ChatEditorV2OnNotePage]>preProcess_UserMessage> We have only ",numObjsWithTemplateSchema[0]," examples objects for '"+template.name+"'. We should load more.");
                    // First check if there are any more of these in memory already. We can check notesContext.notes.
                    const loadedNotes = notesContext.getLoadedNotesOfTemplate(template)
                    let addedNotes = 0;
                    for (const note of loadedNotes) {
                        if (notePrompts.filter((notePrompt:NotePrompt) => notePrompt.doc_id === note.id).length>0)
                            continue;
                        if (tokensUsed>serverType.contextLength-5000)
                            break;
                        notePrompts.push(await getNotePrompt(note, false, templatesWithSchemas, numObjsWithTemplateSchema));
                        tokensUsed = estimateTokensFromString(getUniqMarkdownSystemPrompt(notePrompts));
                        addedNotes++;
                        if (addedNotes>=3)
                            break;    
                    }
                }
            }
        }
        // Add one more system prompt with links to all the documents that are included or could be included here.
        // List of links from the notes that are included in the context:
        // iterate over topLevelNoteHierarchy.noteHierarchiesById
        let linksStr = "# Links to all notes\nWhenever you refer to any of these topics, use the link instead of plain text.\n";
        for (const [noteID, noteHierarchy] of Object.entries(topLevelNoteHierarchy.noteHierarchiesById)) {
            // TODO this is a hard coded URL to the server.
            linksStr += "["+getNoteNameAndEmoji(noteHierarchy.note, notesContext, extensions)+"](</note/"+noteID+">) ";
        }
        const linkPrompt = {
            doc_name: "Links to all notes",
            doc_id: "links",
            type: "link",
            emoji: "🔗",
            markdownPrompt: linksStr,
            isTemplate: false
        } as NotePrompt;
        notePrompts.push(linkPrompt);        
        let systemPrompt = getUniqMarkdownSystemPrompt(notePrompts);
        if (isEditingTemplate) {
            const EDIT_INCLUDE = "When editing an object's data: (1) Begin with the title using H1 (# Object Title). (2) Reprint the full object's JSON. Do not print changes only, but rather, include the full JSON.";
            systemPrompt = EDIT_INCLUDE+"\n\n"+systemPrompt;
        }
        
        return {systemPrompt,
            messageProcessingExtraInfo:[],
            outputOfTopicsThinking,
            noteIDs,
            templateNoteIDs:templateNote?[templateNote?.id]:[],
            templateNotMentionedNoteIDs,
            topicNoteIDs,
            topicNotMentionedNoteIDs,
            selectionNoteIDs:alwaysIncludedIDs,
            includedNoteIDs:[],
            noteIDsMentionedInChat,
            noteIDsFromPreviousChats
        } as PreprocessUserMessageReturnType;
    }

    
    chatEditorRef.current.preProcess_UserMessage = preprocessUserMessageV9;

    return <>
        <div id="chatWithDraftHolder" style={{overflowY:"scroll",height:"calc(100vh - 45px)"}}>
            <ChatWithDraft chatEditorRef={chatEditorRef}/>
        </div>
    </>;
}