import {  Input, Select, Spin, Tooltip } from 'antd';
import { useContext } from 'react';
import NoteMentionPrompt from './NoteSimplePrompts/NoteMentionPrompt';
import { Note } from '../../Data/NoteType';
import { useAllTypeNotes, useSaveNoteDebounced } from '../../Data/NoteDBHooks';
import { saveRootDocAnswer, saveRootDocAnswers } from '../../Data/FirestoreJsonFormsClient';
import { SelectedJSONFormsContext } from '../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext';
import { TypeEditor } from '../../JSONFormsInNote/TypeEditor';
import OldGenericObjectEditor, { ExtensionSupportedObjectEditor } from '../../JSONFormsInNote/GenericObjectEditor';
import { NoteHierarchyContext } from './NoteHierarchyProvider';
import { ExtensionNoteTemplateType, getTemplateTypes } from '../../../Extensions/ExtensionsFramework/ExtensionsList';
import { NotesContext } from '../../Data/NotesContext';
import { Battle_NoteType } from '../../../Extensions/TTRPG/TTRPGExtension';


const DEBUG = false;

const { Option } = Select;


function MultipleChoiceQuestionPrompt({questionText, questionLabel, answersArray, note}:{questionText:string, questionLabel:string, answersArray:any[],note:Note}) {
    const saveNoteCallback = useSaveNoteDebounced(note.id);
    //@ts-ignore
    const currentValue=note?note[questionLabel]:[];
    // TODO updates are delayed from the debounce below, including firestore. We want updates to be instant.
    // To do that, we may need a temporary local cache managed with a state & memo.
    // That may need to be done at a higher level, e.g. via redux, because otherwise only the dropdown changes.

    // console.log(questionLabel,"->",currentValue);
    return <div>
        {questionText}
        
        <Select placeholder={questionLabel} style={{ minWidth: '200px' }}
            showSearch
            value={currentValue}
            onChange={function(value:string){
                if (currentValue===value) {
                    // console.log("Got event, but no change");
                    return;
                }
                saveRootDocAnswer(questionLabel, value, note, saveNoteCallback);
            }} >
            {answersArray.map(function(answer:string) {
                return <Option key={answer} value={answer}>{answer}</Option>
            })}
        </Select>
    </div>;
}

export function useNoteTemplateList() {
    const {extensions, isLoaded} = useContext(NoteHierarchyContext);
    const templateTypes = getTemplateTypes(extensions);
    // console.log("newNoteTypes",newNoteTypes);
    const notesContext = useContext(NotesContext);

    const templatesList = [] as Note[];
    let anythingNotLoaded=!isLoaded || false;
    for (let i=0;i<templateTypes.length;i++) {
        const templateType = templateTypes[i];
        if (!templateType.template_doc_ids) continue;
        for (let j=0;j<templateType.template_doc_ids.length;j++) {
            const template_doc_id = templateType.template_doc_ids[j];
            const note = notesContext.getLoadedNote(template_doc_id,true);
            if (note) {
                templatesList.push(note);
            } else {
                anythingNotLoaded=true;
            }
        }
    }
    return {templatesList, anythingNotLoaded, templateTypes};
}

function useNoteTemplateType(note:Note) {
    const {templatesList, anythingNotLoaded, templateTypes} = useNoteTemplateList();

    const currentValue=note?note.template_doc_id:"";
    const hasCurrentValue = (currentValue&&currentValue.length>0)?true:false;
    let currentNoteType = undefined as ExtensionNoteTemplateType<any>|undefined;
    // Find the currentNoteType:
    if (currentValue) {
        for (let i=0;i<templateTypes.length;i++) {
            const templateType = templateTypes[i];
            if (templateType.template_doc_ids && templateType.template_doc_ids.includes(currentValue)) {
                currentNoteType = templateType;
                break;
            }
        }
    }
    return {templatesList, anythingNotLoaded, currentValue, hasCurrentValue, currentNoteType};
}

function NoteTemplateTypeQuestionPrompt({note,noteTemplateType}:{note:Note,noteTemplateType:{templatesList:Note[],anythingNotLoaded:boolean,currentValue:string | undefined,hasCurrentValue:boolean}}) {
    const {templatesList, anythingNotLoaded, currentValue} = noteTemplateType;

    const saveNoteCallback = useSaveNoteDebounced(note.id);

    const options = templatesList.map(function(template:Note) {
        return {value:template.id,
            label:template.doc_name};
    });
    options.push({value:"",label:"None"});

    return <div>
        Template:
        {anythingNotLoaded && <Spin size='small'/>}
        {!anythingNotLoaded && <Select placeholder="Template" style={{ minWidth: '200px' }}
            showSearch
            listHeight={400} /*show more than the default*/
            value={currentValue}
            onChange={function(value:string){
                if (currentValue===value) {
                    // console.log("Got event, but no change");
                    return;
                }
                // What's the name for this template_doc_id?
                const template = options.filter(function(option) {
                    return option.value===value;
                });
                // console.log("[NoteTemplateTypeQuestionPrompt] Saving new type using template",template);
                saveRootDocAnswers({"template_doc_id":value,type:template[0].label},note,saveNoteCallback);
                // saveRootDocAnswer("template_doc_id", value, note, saveNoteCallback);
                // Until we have made this obsolete, keep setting it:
                // saveRootDocAnswer("type", value, note, saveNoteCallback);
            }}
            options={options}
            filterOption={(input, option)=>(option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />}
    </div>;
}

function SingleCharacterQuestionPrompt({questionText, questionLabel, note, tooltipText}:{questionText:string, questionLabel:string, note:Note, tooltipText?:string}) {
    const saveNoteCallback = useSaveNoteDebounced(note.id);
    //@ts-ignore
    const currentValue=note?note[questionLabel]:[];
    function onChange(event:any) {
        let { value }:{value:string} = event.target;

        if (currentValue===value) {
            // console.log("Got event, but no change");
            return;
        }
        saveRootDocAnswer(questionLabel, value, note, saveNoteCallback);
    }
    return <div>
        <Tooltip title={tooltipText}>
        {questionText}<Input placeholder={questionLabel} style={{ minWidth: '50px', maxWidth:'50px' }}
            value={currentValue}
            onChange={onChange} >
        </Input>
        </Tooltip>
    </div>;
}

const BasicNoteTypes = ["Note","Type"];



export default function DataTabTypeAndEditor() {
    const typeNotes = useAllTypeNotes();
    const types = typeNotes && typeNotes.map(function(note:Note){return note.doc_name});
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const note = selectedJsonFormsContext.note;
    const doc_name = note?.doc_name;

    const noteTemplateType = useNoteTemplateType(note);

    if (!note)
        return <></>;
    const type = note?.type;

    let noteTypesOld;
    if (types)
        noteTypesOld = [...BasicNoteTypes, ...types]; 
    else
        noteTypesOld = BasicNoteTypes; // still loading the notes.

    let includedEditor;

    const OLD_TYPES_THAT_STILL_NEED_EDITORS = [
        Battle_NoteType.template_doc_ids?.[0] // Needs an editor until we fix the lookups so you can look up the right type of note in the editor
        // , Enemy_NoteType.template_doc_ids?.[0]
    ];
    const displayOldTypeBecauseNoNewType = (!noteTemplateType.hasCurrentValue && note.type && note.type!=="None");
    const displayOldTypeBecauseNeedsOldEditor = (noteTemplateType.hasCurrentValue && OLD_TYPES_THAT_STILL_NEED_EDITORS.includes(noteTemplateType.currentValue));
    const displayOldType = displayOldTypeBecauseNoNewType || displayOldTypeBecauseNeedsOldEditor;

    if (displayOldType) {
        const promptContextTab = <>Examples of prompts that do, or don't, mention this type of note<br/><NoteMentionPrompt note={note}/></>
        console.log("[DataTabTypeAndEditor] Displaying old type editor because "+(displayOldTypeBecauseNoNewType?"no new type":"needs old editor"));
        if (type==="Type")
            includedEditor = <TypeEditor promptContextTab={promptContextTab}/>;
        else if (types && typeNotes && type && type!=="Type" && doc_name && types.includes(type))
            includedEditor = <OldGenericObjectEditor key={note?.id} promptContextTab={promptContextTab}/>;
        else
            // TODO add a title here
            includedEditor = <>{promptContextTab}</>;
    } else {
        if (noteTemplateType.anythingNotLoaded) {
            includedEditor = <>&nbsp;&nbsp;&nbsp;<Spin size='large'/></>;
        } else {
            includedEditor = <ExtensionSupportedObjectEditor key={note?.id}/>;
        }
    }

    return <>
        <div style={{"display":"flex"}}>
            <NoteTemplateTypeQuestionPrompt note={note} noteTemplateType={noteTemplateType}/>&nbsp;&nbsp;&nbsp;
            {displayOldType && <><MultipleChoiceQuestionPrompt questionText="Note Type [old]: " questionLabel="type"
                answersArray={noteTypesOld}
                note={note}/>&nbsp;&nbsp;&nbsp;</>
            }
            {/* Bug: this tooltip does not display. I don't know why not. */}
            <SingleCharacterQuestionPrompt questionText="Emoji: " questionLabel="emoji"
                tooltipText="Optional: select an emoji that visually represents this type."
                note={note}/>
        </div>
        {DEBUG && <div>
            <h3>Debug info</h3>
            note.type={type}<br/>
            note.template_doc_id={note.template_doc_id}<br/>
            <br/>

            displayOldType={displayOldType.toString()}<br/>
            
            noteTemplateType.currentValue={noteTemplateType.currentValue}<br/>
            noteTemplateType.hasCurrentValue={noteTemplateType.hasCurrentValue.toString()}<br/>
            noteTemplateType.anythingNotLoaded={noteTemplateType.anythingNotLoaded.toString()}<br/>
            noteTemplateType.templatesList={noteTemplateType.templatesList.map((note:Note)=>note.doc_name).join(", ")}<br/>
            noteTemplateType.currentNoteType={noteTemplateType.currentNoteType?.name}<br/>
        </div>}

        {includedEditor}
    </>;
}