import React, { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import StoriesPage from '../Stories/StoriesPage';

// Auth:
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { Button, Spin, Layout } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import TestPages from '../TestPages/TestPages';
import { useUserData } from '../User/Data/UserDBHooks';
import NotesContextProvider from '../Notes/Data/NotesContext';
import NotePageContext from '../Notes/UIs/NotePageAndHigher/NotePageContext';

const { Content, Footer } = Layout;

const DEBUG = true;

export default function FirebaseLogin() {
  const [haveShownPopup,setHaveShownPopup] = useState(false);
  const {isLoading, hasAuthenticationId, internetIsDown, isMember} = useUserData();

  function doSignInPopup() {
    if (DEBUG) console.log("doSignInPopup()");
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        if (DEBUG) console.log("doSignInPopup() success",result);
        // Yay!
      }).catch((error) => {
        console.log("[FirebaseLogin] doSignInPopup() failure",error);
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }  

  // signin checks:
  if (isLoading)
    return <><Spin /></>;
  if (internetIsDown) {
    return <Layout style={{ minHeight: '100vh' }}>
      <Layout className="site-layout">
        <PageHeader
          title="AI Got This"
          className="site-page-header"
          />
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            &nbsp;&nbsp;The internet may be down -- please check again in a few minutes. We were unable to reach the server.
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}></Footer>
      </Layout>
    </Layout>
  }
  if (!hasAuthenticationId) {
    // Auto-show the popup only once:
    if (!haveShownPopup) {
      if (DEBUG) console.log("signInCheckResult.signedIn === false, so we'll cal the popup once");
      doSignInPopup();
      setHaveShownPopup(true);
    }
    return  <Layout style={{ minHeight: '100vh' }}>
      <Layout className="site-layout">
        <PageHeader
          title="AI Got This"
          className="site-page-header"
          // subTitle="Hey I am "
          // tags={<Tag color="purple">not yet signed in</Tag>}
          // avatar={{ src: 'https://avatars1.githubusercontent.com/u/8186664?s=460&v=4' }}
          />
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            &nbsp;&nbsp;<Button onClick={doSignInPopup} type='primary'>Sign in with Google</Button>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}></Footer>
      </Layout>
    </Layout>       
  }
  if (!isMember) {
    console.log("[FirebaseLogin] logged in but not as a valid member.");
    return <Layout style={{ minHeight: '100vh' }}>
      <Layout className="site-layout">
        <PageHeader
          title="AI Got This"
          className="site-page-header"
          />
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            &nbsp;&nbsp;You are not yet a member: <a href="mailto:aaronpowers@gmail.com?subject=I want to join AI Got This">Email us to request early access</a>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}></Footer>
      </Layout>
    </Layout>
  }

  return <NotesContextProvider>
    <BrowserRouter>
      <Routes>
        {/* One reason the "Routes" is here is so the route can be found on NotePage -- without this, the route can't be parsed and :doc_id nor :hashtag_id can be parsed out. */}
          <Route path="/note/:doc_id/*" element={<NotePageContext/>}/>
          <Route path="/stories" element={<StoriesPage/>}/>
          <Route path="/tests/*" element={<TestPages/>}/>
          <Route path="*" element={<NotePageContext/>}/>
      </Routes>
    </BrowserRouter>
  </NotesContextProvider>
}