import { ContentState, convertToRaw, EditorState, KeyBindingUtil } from "draft-js";
import Editor from '@draft-js-plugins/editor';
import { useMemo, useState, useRef, useEffect } from "react";
import { useBasicHandleKeyCommand, useBasicKeyBindingFn } from "../../../Notes/UIs/DraftJSEditor/DraftKeyboardBindings";
import { BLOCK_RENDER_MAP, blockStyleFn, useBlockRendererFn } from "../../../Notes/UIs/DraftJSEditor/BlockEditing/BlockRendererAndStyleFns";
import { useDraftExtensions } from "../../../Notes/UIs/DraftJSEditor/DraftJSPluginsEditor/DraftJsToolbarAndPlugins";


export default function OneLineDraftEditor({initialContent, onSubmit, onChange, autoFocus, readOnly}:{initialContent?:ContentState, onSubmit:(contentState:ContentState)=>void, onChange?:(contentState:ContentState)=>void, autoFocus?:boolean, readOnly?:boolean}) {
    const [editorState, setEditorState] = useState(function() {
      if (!initialContent)
        return EditorState.createEmpty();
      // For a one-liner, if there's content already, we want the cursor to be at the end.
      return EditorState.moveFocusToEnd(EditorState.createWithContent(initialContent));
    });
    const editorRef = useRef<Editor | null>(null);

    useEffect(() => {
        if (autoFocus && editorRef.current) {
          setTimeout(()=>editorRef.current?.focus(),0);
        }
    }, []); // Add an empty dependency array to run this effect only once

    const basicKeyBindingFn = useBasicKeyBindingFn(editorState);
    const basicHandleKeyCommand = useBasicHandleKeyCommand(editorState, setEditorState);
    const blockRenderFn = useBlockRendererFn(setEditorState, editorState, !readOnly);

    const {headerComponents, plugins, extendedHandleKeyCommand} = useDraftExtensions(setEditorState,editorState, !readOnly, basicHandleKeyCommand, undefined, undefined, false);

    function submitOnReturnKeyBindingFn(e: React.KeyboardEvent<Element>): string | null {
        if (!KeyBindingUtil.hasCommandModifier(e) && e.key==="Enter") {
            e.preventDefault();
            // Can we handle the submit here? Or do we need to build out a handleKeyCommand?
            onSubmit(editorState.getCurrentContent());
            return "submit";
        }
        const toReturn = basicKeyBindingFn(e);
        return toReturn;
      }

    function handleChange(newEditorState: EditorState) {
        setEditorState(newEditorState);
        if (onChange) {
            onChange(newEditorState.getCurrentContent());
        }
    }

    // Print block map, if needed, e.g. to edit and save
    // useMemo(()=>{
    //   const doc_data_to_save=convertToRaw(editorState.getCurrentContent());
    //   console.log("[DraftJSPluginsTestPage] Editor state changed to ",doc_data_to_save);
    // },
    // [editorState]);

    return <div>
          {headerComponents.map((component,i)=><div key={i}>{component}</div>)}
        <div className="editor inline-editor-main">
        <Editor
            ref={editorRef}
            editorState={editorState}
            onChange={handleChange}
            blockRendererFn={blockRenderFn}
            blockRenderMap={BLOCK_RENDER_MAP}
            blockStyleFn={blockStyleFn}
            plugins={plugins}

            handleKeyCommand={extendedHandleKeyCommand}
            keyBindingFn={submitOnReturnKeyBindingFn}
            readOnly={readOnly}
        />
        </div>
        </div>
}