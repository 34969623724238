// React & state:
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ContentState, EditorState, RawDraftContentBlock, RawDraftContentState, convertFromRaw, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import "../../../Notes/UIs/DraftJSEditor/WYSIWYGEditor.css";
import { getAuth } from 'firebase/auth';
import { Avatar, Button, Collapse, Flex, Image, Modal, Spin, Tag, Tooltip } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { DeleteOutlined, EditOutlined, MergeCellsOutlined, SaveOutlined } from '@ant-design/icons';
import { ChatEditorRefFunctions, useSendToServerFromChatEditor } from '../../../ServerConnection/LLMServer/SendChatToServerHook';
import { ChatListDropdown, ChatLogContext, ChatMessage2 } from './ChatLog';
import { SelectableLLMServerDropdown } from './SelectableLLM';
import { findLastIndex, isEqual, uniq } from 'lodash';
import { convertDraftJSToMarkdown, extractJSONFromMarkdown, removeJSONFromMarkdown } from '../../../ServerConnection/LLMServer/MarkdownConverter';
import { JSONFormsObject, getJSONFormsObject, saveJSONFormsObject } from '../../../Notes/Data/Actions/JSONFormsObject/LoadAndSaveJSONFormsObject';
import { NotesContext } from '../../../Notes/Data/NotesContext';
import { useLinkToNote_ForEditor, useNavigateToNote } from '../../Utilities/NavigateTo';
import { Note } from '../../../Notes/Data/NoteType';
import { SelectedJSONFormsContext } from '../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext';
import { newNote, useSaveNote } from '../../../Notes/Data/NoteDBHooks';
import { addNoteToParent } from '../../../Notes/Data/Actions/Tree/ManipulateTree';
//@ts-ignore
import ReactJsonViewCompare from 'react-json-view-compare';
import { getNoteNameAndEmoji } from '../../../Notes/NotesTree/TreeUtilities/CreateTagTreeNodes';
import { convertMarkdownToDraftJSRaw } from '../../../Notes/UIs/NoteInformationComponents/DraftJSFromHTML';
import { useBasicHandleKeyCommand, useBasicKeyBindingFn } from '../../../Notes/UIs/DraftJSEditor/DraftKeyboardBindings';
import { BLOCK_RENDER_MAP, blockStyleFn, useBlockRendererFn } from '../../../Notes/UIs/DraftJSEditor/BlockEditing/BlockRendererAndStyleFns';
import { useDraftExtensions } from '../../../Notes/UIs/DraftJSEditor/DraftJSPluginsEditor/DraftJsToolbarAndPlugins';
import { SELECTED_TAB_IS_DATA, SELECTED_TAB_IS_NOTE } from '../../../Notes/UIs/NotePageAndHigher/NotePage';
import { NoteHierarchyContext } from '../../../Notes/UIs/NoteInformationComponents/NoteHierarchyProvider';
import { estimateTokensFromString } from '../../../ServerConnection/LLMServer/LLMTokenCounter';
import { useIsSmallScreen } from '../../../Notes/UIs/NotePageAndHigher/IsSmallScreen';
import PluginEditor from '@draft-js-plugins/editor/lib/Editor';
import OneLineDraftEditor from './OneLineDraftJS';
import { FIREBASE_FIRESTORE } from '../../../AppBase/App';

const DEBUG = false;
const DEBUG_SHOW_SYSTEM_PROMPT = true;
const DEBUG_SEND_TO_SERVER_WITH_STREAMING = false;
const DEBUG_SHOW_UNUSED_NOTES = true;

const USE_DRAFTJS_USER_INPUT = true; // Not yet implemented

const DELAY_BETWEEN_CLIENT_CHAT_UPDATES = 400;

const SHOW_TOKENS = true;

function SystemMessageViewer({message}:{message:ChatMessage2}) {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  // This will have only the assistant & system messages, nothing from the regular. 
  const [tokensUsed, setTokensUsed] = useState(0);
  useEffect(() => {
    if (message.role !== 'system') {
      console.error("SystemMessageViewer only accepts system messages. Got:",message.role);
      debugger;
      return;
    }
    const draftJSMessage = convertMarkdownToDraftJSRaw(message.content as string);
    const editorStateNew = EditorState.createWithContent(convertFromRaw(draftJSMessage));
    setEditorState(editorStateNew);
    const tokensSystemPrompt = estimateTokensFromString(message.content as string);
    setTokensUsed(tokensSystemPrompt);
  },[message]);
  const basicKeyBindingFn = useBasicKeyBindingFn(editorState);
  const basicHandleKeyCommand = useBasicHandleKeyCommand(editorState, setEditorState);
  const blockRenderFn = useBlockRendererFn(setEditorState, editorState, false);
  const {plugins, extendedHandleKeyCommand} = useDraftExtensions(setEditorState,editorState, false, basicHandleKeyCommand, (a:boolean)=>{});

  let label = "System Prompt";
  if (SHOW_TOKENS && tokensUsed>0)
    label += " ("+Math.round((tokensUsed)/1000)+"k tokens)";

  return <Collapse accordion={true} items={[
        {
            key: "System Prompt",
            label,
            children: <div className="editor">
                <Button type='default' onClick={()=>navigator.clipboard.writeText(message.content as string)}>Copy system prompt</Button>
                <Editor
                    editorState={editorState}
                    onChange={setEditorState}
                    blockRendererFn={blockRenderFn}
                    blockRenderMap={BLOCK_RENDER_MAP}
                    blockStyleFn={blockStyleFn}
                    plugins={plugins}
            
                    handleKeyCommand={extendedHandleKeyCommand}
                    keyBindingFn={basicKeyBindingFn}
                    readOnly={true}
                />
            </div>
      }]}/>
}

function InlineDraftJSViewer({draftJSMessage}:{draftJSMessage:RawDraftContentState}) {
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(convertFromRaw(draftJSMessage)));
    // How can we make this more efficient?
    useEffect(() => {
        setEditorState(EditorState.createWithContent(convertFromRaw(draftJSMessage)));
    },[draftJSMessage]);
    
    // const basicKeyBinding = useBasicKeyBindingFn(editorState);
    const basicHandleKeyCommand = useBasicHandleKeyCommand(editorState, setEditorState);
    const blockRenderFn = useBlockRendererFn(setEditorState, editorState, false);
    const {plugins} = useDraftExtensions(setEditorState,editorState, false, basicHandleKeyCommand);
    return <div className="editor inline-editor-main">
      <Editor
        editorState={editorState}
        onChange={setEditorState} // this has to be present for links to be rendered, not sure why.
        blockRendererFn={blockRenderFn}
        blockRenderMap={BLOCK_RENDER_MAP}
        blockStyleFn={blockStyleFn}
        plugins={plugins}
        readOnly={true}
    />
    </div>;
}
function InlineDraftJSEditor({draftRawContent, setDraftJSMessage, ref}:{draftRawContent:RawDraftContentState, setDraftJSMessage:(message:RawDraftContentState)=>void, ref?:React.Ref<PluginEditor>}) {
    const [editorState, setEditorStateLocal] = useState(() => EditorState.createWithContent(convertFromRaw(draftRawContent)));
    function setEditorState(newEditorState:EditorState) {
        setEditorStateLocal(newEditorState);
        setDraftJSMessage(convertToRaw(newEditorState.getCurrentContent()));
    }
    const basicKeyBindingFn = useBasicKeyBindingFn(editorState);
    const basicHandleKeyCommand = useBasicHandleKeyCommand(editorState, setEditorState);
    const blockRenderFn = useBlockRendererFn(setEditorState, editorState, false);
    const {headerComponents, plugins, extendedHandleKeyCommand} = useDraftExtensions(setEditorState,editorState, true, basicHandleKeyCommand);
  
    return <div className="editor inline-editor-main">
        {headerComponents}
        <Editor
            editorState={editorState}
            onChange={setEditorState}
            blockRendererFn={blockRenderFn}
            blockRenderMap={BLOCK_RENDER_MAP}
            blockStyleFn={blockStyleFn}
            plugins={plugins}
            handleKeyCommand={extendedHandleKeyCommand}
            keyBindingFn={basicKeyBindingFn}
            readOnly={false}
            ref={ref}
        />
    </div>;
}

function OverwriteNoteModal({loadedNote, loadedJSON, isModalOpen, setModalIsOpen, message, setMessage}:{loadedNote:Note, loadedJSON:JSONFormsObject|null, isModalOpen:boolean, setModalIsOpen:(open:boolean)=>void, message:ChatMessage2, setMessage:(message:ChatMessage2)=>void}) {
    const navigateToNote = useNavigateToNote();
    const saveNote = useSaveNote();

    const {draftDiffers, jsonDiffers, newNote, newMessage} = useMemo(()=>{
        const {newNote, newMessage, hasDraftJSContent} = getSaveableNoteFrom(loadedNote.doc_name, message);

        const draftIsSame = isEqual(loadedNote.doc_data, newNote.doc_data);
        const draftDiffers = hasDraftJSContent && !draftIsSame;
        const jsonDiffers = !isEqual(loadedJSON, message.jsonContent);
        // console.log("[OverwriteNoteModal]>useMemo> hasDraftJSContent: ",hasDraftJSContent, "  draftDiffers:",draftDiffers);
        return {draftDiffers, jsonDiffers, newNote:{...newNote, id:loadedNote.id} as Note, newMessage};
    },[loadedNote, loadedJSON, message]);

    async function submitChanges() {
        // TODO keep it open but replace it with a spinner and remove the buttons. (Or just close it and show a spinner on the main page).
        setModalIsOpen(false);
        if (jsonDiffers) {
            await saveJSONFormsObject(FIREBASE_FIRESTORE, loadedNote.id, message.jsonContent);
            // Bug: If (and only if) the note was the current editor open, this does not refresh the JSON editor. We need to do that manually somehow, perhaps via the current note context.
        }
        if (draftDiffers) {
            saveNote(newNote);
            if (newMessage)
                setMessage(newMessage);
        }
        // Sleep for a moment before going to that page... Giving it a moment to catch up seems to allow it to become fresh.
        await new Promise(r => setTimeout(r, 100));
        if (jsonDiffers && !draftDiffers)
            navigateToNote(newNote.id, SELECTED_TAB_IS_DATA);
        else 
            navigateToNote(newNote.id, SELECTED_TAB_IS_NOTE);
        if (jsonDiffers && (!loadedNote.type || loadedNote.type==="Note")) {
            alert("Please select a type for this note manually. (Until then, you won't be able to see the JSON).");
        }
    }


    // TODO Use a visual diff for the draftjs instead of a JSON viewer.

    return <Modal
        title="Overwrite Note?"
        closable={true}
        open={isModalOpen}
        onOk={submitChanges}
        onCancel={()=>{setModalIsOpen(false)}}
        okText="Overwrite"
        cancelText="Cancel"
        width="100vw" style={{ top: 20, minHeight: "100vh"}}
        footer={[
            <Button key="back" type="default" onClick={()=>{setModalIsOpen(false)}}>Cancel</Button>,
            <Button key="submit" type="primary" onClick={submitChanges}>Overwrite</Button>,
        ]}
        >
            {draftDiffers && <>
                <h3>Note Text</h3>
                <ReactJsonViewCompare oldData={loadedNote?.doc_data} newData={message.draftRawContent}/>
            </>}
            {jsonDiffers && <>
                <h3>Data</h3>
                <ReactJsonViewCompare oldData={loadedJSON} newData={message.jsonContent}/>
            </>}
       <p>Are you sure you want to overwrite the note?</p>
       
    </Modal>;
}

const HEADER_TYPES = ["header-one","header-two","header-three"];

function getFirstHeader(doc_data:RawDraftContentState) {
    // Remove any lines before the first H1 (sometimes the bot creates a description saying what it's creating)
    // And mark whether there are additional contents below the H1.
    for (let i=0;i<doc_data.blocks.length;i++) {
        // Sometimes it generats the first header as being another type besides H1.
        if (HEADER_TYPES.includes(doc_data.blocks[i].type)) {
            doc_data.blocks[i].type="header-one"; // make it header-one if it was a lower level header.
            return {found: true, doc_name: doc_data.blocks[i].text, index:i};
        }
    }
    return {found:false, index: null};
}

function getSaveableNoteFrom(doc_name:string, message:ChatMessage2) {
    // Create a new note
    const newNote = {doc_name:doc_name} as Note;
    let newMessage = null as ChatMessage2|null;
    let hasDraftJSContent = false;
    let needsAType = false;
    if (message.draftRawContent) {
        let doc_data = message.draftRawContent;
        // Remove any lines before the first H1 (sometimes the bot creates a description saying what it's creating)
        // And mark whether there are additional contents below the H1.
        const {index: indexOfFirstBlockWithHeader} = getFirstHeader(doc_data);
        // let indexOfFirstBlockWithHeader = null as number|null;
        // for (let i=0;i<doc_data.blocks.length;i++) {
        //     // Sometimes it generats the first header as being another type besides H1.
        //     if (HEADER_TYPES.includes(doc_data.blocks[i].type)) {
        //         indexOfFirstBlockWithHeader = i;
        //         doc_data.blocks[i].type="header-one"; // make it header-one if it was a lower level header.
        //         break;
        //     }
        // }
        if (indexOfFirstBlockWithHeader===null) {
            // Add the title block in as the first block.
            const titleBlock = {text:doc_name,type:"header-one",key:"HeaderBlock"+Math.random()} as unknown as RawDraftContentBlock;
            doc_data.blocks = [titleBlock, ...doc_data.blocks];
        } else if (indexOfFirstBlockWithHeader>0) {
            // Trim blocks before the header.
            doc_data.blocks = doc_data.blocks.slice(indexOfFirstBlockWithHeader);                
        }
        if (doc_data.blocks.length>1)
            hasDraftJSContent = true;
        newNote.doc_data=doc_data;
        newMessage = {...message, draftRawContent: doc_data} as ChatMessage2;
    }
    if (message.jsonContent) {
        // We also need to know the note type. Figure it out from the JSON.
        // TODO JSONFormsObject should have a type field, and we should use that in other places.
        if (message.jsonContent.type) {
            newNote.type=message.jsonContent.type;
        } else {
            // TODO pass this back
            needsAType = true;
            // alert("Please select the type for this note manually, it was not inclueded in the JSON.");
        }
    }
    return {newNote, newMessage, hasDraftJSContent, needsAType};
}

function AssistantMessage({message:messageIncoming, deleteSelectedMessage, setNextMessage, setMessage, editing, setEditing, scrollToRef, linkToNote, extensions}:{message:ChatMessage2, deleteSelectedMessage?:()=>void, setNextMessage:(nextMessage:string)=>void, setMessage:(message:ChatMessage2)=>void, editing: boolean, setEditing:(editing:boolean)=>void, scrollToRef?:React.Ref<HTMLBRElement>, linkToNote: any, extensions: any[]}) {
    const notesContext = useContext(NotesContext);
    const navigateToNote = useNavigateToNote();
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const [isOverwriteModalOpen, setOverwriteModalOpen] = useState(false);
    const message = useMemo(()=>{
        // Convert the incoming message from markdown to draftjs & json.
        if (messageIncoming.role!== 'assistant' && messageIncoming.role !== 'system') {
            console.error("   AssistantMessage only accepts assistant and system messages. Got:",messageIncoming.role);
            debugger;
            return messageIncoming;
        }
        if (messageIncoming.userHasMadeEdits) {
            // console.log("   AssistantMessage>useMemo> userHasMadeEdits is true. Not converting the message from markdown.");
            return messageIncoming; // already converted.
        }
        // console.log("   AssistantMessage>useMemo> converting the incoming message from markdown:",messageIncoming);
        let markdownText = (messageIncoming.content as string);
        if (markdownText)
            markdownText = markdownText.trim();
        else
            markdownText = "";
        const jsonContent = extractJSONFromMarkdown(markdownText) as JSONFormsObject | null;
        if (jsonContent) {
            markdownText = removeJSONFromMarkdown(markdownText).trim();
        }
        const draftJSMessage = convertMarkdownToDraftJSRaw(markdownText);
        return {...messageIncoming, draftRawContent: draftJSMessage, jsonContent} as ChatMessage2;
    },[messageIncoming, messageIncoming.content]);
    const doc_name = useMemo(() => {
        if (!message.draftRawContent)
            return null;
        const {doc_name, index: blockWithH1} = getFirstHeader(message.draftRawContent as RawDraftContentState);
        if (blockWithH1 !== null) {
            return doc_name;
        }
        // console.log("[AssistantMessage]>useMemo> doc_name:",doc_name," hasNoteTitle:",hasNoteTitle," draftRawContent:",message.draftRawContent);
        return null;
    },[message.draftRawContent]);
    const [loadedNote, setLoadedNote] = useState(null as Note|null);
    const [loadedJson, setLoadedJson] = useState(null as JSONFormsObject|null);
    const [noteIsDifferent, setNoteIsDifferent] = useState(false);
    const [errorNotesWithSameName, setErrorNotesWithSameName] = useState([] as Note[]);
    useEffect(() => { // Load note by this name
        if (!doc_name) {
            setLoadedNote(null);
            return;
        }
        async function checkIfNoteExists() {
            if (DEBUG) console.log("[AssistantMessage]>useEffect> Checking if note exists for doc_name:",doc_name);
            const notes = await notesContext.getNotesOfName(doc_name as string);
            if (notes.length===0) {
                setLoadedNote(null);
                setLoadedJson(null);
                setErrorNotesWithSameName([]);
                return;
            } else if (notes.length>1) {
                setLoadedNote(null);
                setLoadedJson(null);
                setErrorNotesWithSameName(notes);
                return;
            } else if (notes.length===1) {
                setLoadedNote(notes[0]);
                const jsonFormsObject = await getJSONFormsObject( notes[0].id);
                setLoadedJson(jsonFormsObject);
                setErrorNotesWithSameName([]);
            }
        }
        checkIfNoteExists();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[doc_name]);
    useEffect(()=> { // Check for changes vs the note.
        if (!loadedNote || !message.draftRawContent) {
            setNoteIsDifferent(false);
            return;
        }
        const draftIsSame = isEqual(loadedNote.doc_data, message.draftRawContent);
        const jsonIsSame = isEqual(loadedJson, message.jsonContent);
        setNoteIsDifferent(!draftIsSame || !jsonIsSame);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loadedNote,message.draftRawContent]);
    async function saveAsNewNote(doc_name:string) {
        if (!doc_name) {
            alert("Unfortunately, there was a bug. The system is having trouble processing the doc name.");
            if (DEBUG) console.error("[AssistantMessage]>saveAsNewNote> doc_name is blank. saveAsNewNote should not be callable in this case.");
            debugger;
            return;
        }
        // Create a new note
        const {newNote: newNoteDoc, newMessage, hasDraftJSContent} = getSaveableNoteFrom(doc_name, message);
        if (newMessage)
            setMessage(newMessage);
        const parentNote = selectedJsonFormsContext.note;
        if (parentNote)
            newNoteDoc.parent=parentNote.id;
        const createdNote = await newNote(FIREBASE_FIRESTORE, doc_name, newNoteDoc);
        notesContext.notesHaveBeenLoaded([createdNote]);
        if (message.jsonContent)
            await saveJSONFormsObject(FIREBASE_FIRESTORE, createdNote.id, message.jsonContent);
        if (parentNote) {
            await addNoteToParent(FIREBASE_FIRESTORE, createdNote, parentNote.id, null, notesContext);
        }
        if (message.jsonContent && !hasDraftJSContent)
            navigateToNote(createdNote.id, SELECTED_TAB_IS_DATA);
        else 
            navigateToNote(createdNote.id, SELECTED_TAB_IS_NOTE);
        setLoadedNote(createdNote);
        setNoteIsDifferent(false);
        return;
    }
    function saveAsAskForName() {
        const doc_name = prompt("Please enter a name for this note.");
        if (!doc_name) {            
            return;
        }
        saveAsNewNote(doc_name);
    }
    
    let noteAction = <></>;
    if (!doc_name) {
        noteAction = <Tooltip title="Choose a name and save as a new note">
            <Button type="text" onClick={saveAsAskForName} icon={<SaveOutlined />}/>
        </Tooltip>;
    } else { // doc_name exists: there is a note title.
        if (errorNotesWithSameName.length>1) {
            noteAction = <div style={{color:"red"}}>There are multiple notes with the same name. Please delete one of them before trying to save this: {errorNotesWithSameName.map((note:Note,index:number) => <div key={note.id}>
                <Button type="link" onClick={()=>{navigateToNote(note.id)}} key={note.id}>"{note.doc_name}" ({index+1})</Button>
                </div>)}
            </div>;
        } else if (loadedNote!==null) {
            if (noteIsDifferent) {
                noteAction = <Tooltip title="Update the existing note. This will save over it">
                    <Button type="link" onClick={()=>{navigateToNote(loadedNote.id)}}>Go to "{doc_name}"</Button>
                    <Button type="text" onClick={()=>{setOverwriteModalOpen(true)}} icon={<MergeCellsOutlined />}>Overwrite "{doc_name}"</Button>
                </Tooltip>;
            } else {
                noteAction = <Tooltip title="Note exists and is the same.">
                    <Button type="link" onClick={()=>{navigateToNote(loadedNote.id)}}>Go to "{doc_name}"</Button>
                </Tooltip>;
            }
        } else {
            // Note doesn't exist yet
            noteAction = <Tooltip title={"Will create a new note named \""+doc_name+"\""}>
                <Button type="text" onClick={()=>saveAsNewNote(doc_name)} icon={<SaveOutlined />}/>
            </Tooltip>;
        }
    }
    
    // console.log("[AssistantMessage]>render> loadedNote:",loadedNote," loadedJson:",loadedJson," errorNotesWithSameName:",errorNotesWithSameName," noteIsDifferent:",noteIsDifferent)
    
    let nextLinks = null as string[]|null;
    if (message.jsonContent && message.jsonContent.next && Array.isArray(message.jsonContent.next)
        && message.jsonContent.next.every((nextLink:any)=>typeof nextLink==="string")) {
        nextLinks = message.jsonContent.next as string[];
    }

    const setDraftJSMessage = async (draftRawContent: RawDraftContentState) => {
        const markdownContent = await convertDraftJSToMarkdown(draftRawContent, notesContext, linkToNote, extensions);
        setMessage({...message, draftRawContent: draftRawContent, content: markdownContent, userHasMadeEdits: true} as ChatMessage2);
    };

    return <div>
        {/* {message.extra && <div>There is some extra on the assistant message. It's not being rendered.</div>} */}
        {noteIsDifferent && loadedNote && errorNotesWithSameName.length===0 &&
            <OverwriteNoteModal
                loadedNote={loadedNote}
                loadedJSON={loadedJson}
                isModalOpen={isOverwriteModalOpen} setModalIsOpen={setOverwriteModalOpen} message={message} setMessage={setMessage}/>
        }
        {!editing && message.draftRawContent && <>
            <InlineDraftJSViewer draftJSMessage={message.draftRawContent}/>
            {nextLinks && <div>{nextLinks.map((nextLink:string)=><Button key={nextLink} onClick={()=>{setNextMessage(nextLink)}} type='link'>{nextLink}</Button>)}</div>}
            {!nextLinks && message.jsonContent && <div>JSON: {JSON.stringify(message.jsonContent)}</div>}
            <Tooltip title="Edit this response">
                <Button type="text" onClick={()=>{setEditing(true)}} icon={<EditOutlined/>}/>
            </Tooltip>&nbsp;&nbsp;
            <Tooltip title="Delete this response">
                <Button type="text" onClick={deleteSelectedMessage} icon={<DeleteOutlined />}/>
            </Tooltip>&nbsp;&nbsp;
            {noteAction}
        </>}
        {editing && message.draftRawContent && <>
            <InlineDraftJSEditor
                draftRawContent={message.draftRawContent}
                setDraftJSMessage={setDraftJSMessage}
                />
            {message.jsonContent && <div>JSON: {JSON.stringify(message.jsonContent)}</div>}
            <Tooltip title="Stop editing">
                <Button type="default" onClick={()=>{setEditing(false)}}>Done</Button>
            </Tooltip>&nbsp;&nbsp;
            {noteAction}
        </>}
        <br ref={scrollToRef}/>
    </div>;
}

export function ChatWithDraft({chatEditorRef}:{chatEditorRef:React.MutableRefObject<ChatEditorRefFunctions>}) {
    const { allChats, selectedChatIndex, setSelectedChatMessages } = useContext(ChatLogContext);
    const selectedChatMessages = allChats[selectedChatIndex]?.messages || [] as ChatMessage2[];
    const [editingMessageIndex, setEditingMessageIndexState] = useState(-1);
    const [currentMessageHasChanged, setCurrentMessageHasChanged] = useState(false);
    const {sendToServerWithStreaming} = useSendToServerFromChatEditor(chatEditorRef);
    const isWaitingForChat = chatEditorRef.current?.isWaitingForChat || false;
    const notesContext = useContext(NotesContext);
    const linkToNote = useLinkToNote_ForEditor();
    let {extensions} = useContext(NoteHierarchyContext);
    if (!extensions)
        extensions = []; // This is being run on the test page where there's no NoteHierarchyContext in the tree.
    
    const navigateToNote = useNavigateToNote();


    function deleteMessage(index:number) {
        // TODO someday we'd like to implement undo. (We won't ask the user for confirmation because this is usually a lightweight operation).
        const newMessages = selectedChatMessages;
        let numToDelete = 1;
        // If this was an assistant message, we also check to see whether there was an empty user message after it.
        if (newMessages[index].role === 'assistant' && index+1<newMessages.length && newMessages[index+1].role === 'user' && newMessages[index+1].content === "")
            numToDelete = 2;
        newMessages.splice(index,numToDelete);
        setSelectedChatMessages(newMessages);
        // Update the editing message to make it the last user message.
        const lastUserMessageIndex = findLastIndex(newMessages,(message)=>message.role === 'user');
        if (lastUserMessageIndex>-1)
            setEditingMessageIndex(lastUserMessageIndex);
    }
    function setMessage(index:number, message:ChatMessage2) {
        const newMessages = selectedChatMessages;
        newMessages[index] = message;
        setSelectedChatMessages(newMessages);
        if (index === editingMessageIndex)
            setCurrentMessageHasChanged(true);
    }
    function setCurrentUserMessageTo(message:string) {
        const newMessages = selectedChatMessages;
        const newMessage = {role:'user',content:message, createAt: new Date().getTime(), userHasMadeEdits:false} as ChatMessage2;
        newMessages[editingMessageIndex] = newMessage;
        setSelectedChatMessages(newMessages);
        setCurrentMessageHasChanged(true);
    }

    async function setCurrentUserMessageToContentState(contentState: ContentState, submit:boolean) {
        const rawContentState = convertToRaw(contentState);
        let markdownContent = await convertDraftJSToMarkdown(rawContentState, notesContext, linkToNote, extensions);
        const newMessage = {role:'user',content: markdownContent, createAt: new Date().getTime(), userHasMadeEdits:false, draftRawContent:rawContentState} as ChatMessage2;
        const newMessages = selectedChatMessages;
        newMessages[editingMessageIndex] = newMessage;
        setSelectedChatMessages(newMessages);
        setCurrentMessageHasChanged(true);
        if (submit) {
            await submitUserMessage(editingMessageIndex);
        }
    }

    useEffect(() => { // Anytime someone selects a different chat, we cancel any edits.
        setEditingMessageIndex(-1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedChatIndex]);
    useEffect(() => { // Adjust messages & selection to match
        if (DEBUG) console.log("[ChatWithDraft]>useEffect> Starting checks:");
        // Check if there's no user chat at the end, and we're not editing a message, in which case, we should add a user message at the end.
        if (chatEditorRef.current.isWaitingForChat)
            return;
        // Check if editingMessageIndex is now invalid, if so, we need to change it to a valid one. This happens when the chat is changed, or we create a new chat.
        if (editingMessageIndex>-1) {
            if (editingMessageIndex<selectedChatMessages.length) {
                // it already exists, so don't change anything.
                // However, if we just deleted a message, it won't be focused by default.
                // TODO enhance this so we can get autofocus.
                if (DEBUG) console.log("[ChatWithDraft]>useEffect> Editing message index is already valid, not changing.");
                return;
            }
            // The message doesn't exist yet:
            //if (editingMessageIndex>=selectedChatMessages.length) {
            if (DEBUG) console.log("[ChatWithDraft]>useEffect> Editing message index is invalid, changing to -1.");
            setEditingMessageIndex(-1);
            // This useEffect will be called again with the new editingMessageIndex, so, still return:
            // }
            return;
        }
        if (selectedChatMessages.length===0 || selectedChatMessages[selectedChatMessages.length-1].role !== 'user') {
            // We need to add a user message at the end.
            if (DEBUG) console.log("[ChatWithDraft]>useEffect> Automatically adding a user message to the end.");
            const newMessages = selectedChatMessages;
            newMessages.push({role:'user',content:"", createAt: new Date().getTime(), userHasMadeEdits:false} as ChatMessage2);
            setSelectedChatMessages(newMessages);
        }
        if (DEBUG) console.log("[ChatWithDraft]>useEffect> Automatically editing the last message.");
        setEditingMessageIndex(selectedChatMessages.length-1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedChatMessages, editingMessageIndex, isWaitingForChat]);

    function setEditingMessageIndex(index:number) {
        if (index>-1) {
            DEBUG && console.log("[ChatWithDraft]>setEditingMessageIndex> Going to start editing message index:",index);
        }
        setEditingMessageIndexState(index);
    }
    async function submitUserMessage(doneEditingIndex:number) {
        if (!currentMessageHasChanged && selectedChatMessages.length>doneEditingIndex && selectedChatMessages[doneEditingIndex+1]?.role === 'assistant') {
            // Nothing has changed, and there's already an answer, so just stop editing.
            //   cancelEditingUserMessage(doneEditingIndex);
            setEditingMessageIndex(-1);
            return;
        }
        // User has submitted a message to process. Clean up, by removing any messages below this index, and send the message to the server.
        const messagesToSendToServer = selectedChatMessages.slice(0,editingMessageIndex+1);
        setSelectedChatMessages(messagesToSendToServer);
        chatEditorRef.current.isWaitingForChat=true;
        setEditingMessageIndex(-1); // Do this before the await, so that it will display as submitted.
        if (DEBUG_SEND_TO_SERVER_WITH_STREAMING) console.log("[ChatWithDraft]>submitUserMessage> Sending to server with streaming.");
        // What temperature should we set?
        // TODO could we set the temperature more dynamically depending on how factual the question is, perhaps using extensions?
        // The default is 0.3 but that ends up having fairly repetitive name generation and scenarios, where we're seeing a lot of repetition (haha).
        // So we'll try 0.5
        const {response, newChatMessages} = await sendToServerWithStreaming(messagesToSendToServer, {temperature:0.7});
        // Confirm that newChatMessages ends with a user message:
        if (newChatMessages.length>0 && newChatMessages[newChatMessages.length-1].role !== 'user') {
            console.error("[ChatWithDraft]>submitUserMessage> The processed messages did not end with a user message.");
            debugger;
        }
        // Read and incrementally add the messages to the chat, until it's done:
        if (!response.body) {
            console.error("[ChatWithDraft]>submitUserMessage> Response has no body.");
            return;
        }
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        // Create the new assistant message:
        const newAssistantMessage = {role: "assistant", content:"", userHasMadeEdits: false} as ChatMessage2;
        let lastAddition = new Date().getTime();
        while (true) {
            const {done, value} = await reader.read();
            if (done) {
                break;
            }
            const newChunk = decoder.decode(value);
            // Add the message to the chat.
            newAssistantMessage.content += newChunk;
            if (DEBUG_SEND_TO_SERVER_WITH_STREAMING) console.log("[ChatWithDraft]>submitUserMessage> Message so far:",newAssistantMessage.content);
            let latestAddition = new Date().getTime();
            if (latestAddition - lastAddition > DELAY_BETWEEN_CLIENT_CHAT_UPDATES) {
                // console.log("[ChatWithDraft]>submitUserMessage> Maxing message update");
                // Add the message to the chat, but only once per second, to avoid overloading react. (There might be another way)
                const newChatMessages2 = [...newChatMessages, newAssistantMessage];
                setSelectedChatMessages(newChatMessages2);
                lastAddition = latestAddition;
            }
        }
        const newChatMessages2 = [...newChatMessages, newAssistantMessage];
        setSelectedChatMessages(newChatMessages2);
        setCurrentMessageHasChanged(false);
        chatEditorRef.current.isWaitingForChat=false;
    }
    //   function cancelEditingUserMessage(cancelEditingIndex:number) {
    //     // Just stop editing. Revert the message to its original state.
    //     // Special case -- if it's the last message, we don't stop editing (until the user has selected another message)
    //     // This special case is required in order to make sure we get the events to start editing the next message -- I think the order is not onBlur then click or something like that, because we don't get the start editing message.
    //     if (cancelEditingIndex === selectedChatMessages.length-1) {
    //       if (DEBUG) console.log("[ChatWithDraft]>cancelEditingUserMessage> Ignoring onBlur to stop editing the last message.");
    //       return;
    //     }
    //     if (DEBUG) console.log("[ChatWithDraft]>cancelEditingUserMessage> User cancelled editing message at index:",editingMessageIndex);
    //     const newMessages = [...selectedChatMessages];
    //     newMessages[editingMessageIndex].content = uneditedMessage;
    //     setSelectedChatMessages(newMessages);
    //     setEditingMessageIndex(-1);
    //   }

    const auth = getAuth();
    const photoURL = auth.currentUser?.photoURL;
    const avatar = <Avatar
            src={photoURL && <Image src={photoURL}/>}
        />;

    function setNextMessage(nextMessage:string){
        setCurrentUserMessageTo(nextMessage);
        submitUserMessage(selectedChatMessages.length-1);
    }

    const isSmallScreen = useIsSmallScreen();
    // useMemo(()=>{
    //     console.log("[ChatWithDraft]> selectedChatMessages changed:",selectedChatMessages);
    // },[selectedChatMessages]);

    const lastAssistantMessageRef = useRef<HTMLBRElement>(null);
    useEffect(()=>{
        // When the chat messages change, we want to scroll to the bottom of the chat.
        // TODO a better form of this would be to scroll just once as soon as the user submits -- this would mean showing some blank space where the message will appear.
        // Check to make sure it's loading, because otherwise we don't want to scroll:
        if (!isWaitingForChat) {
            return;
        }
        if (lastAssistantMessageRef.current) {
            lastAssistantMessageRef.current.scrollIntoView({behavior: "smooth"});
        }
    },[selectedChatMessages,lastAssistantMessageRef.current,isWaitingForChat]);

    const autoFocus = !isSmallScreen || editingMessageIndex===0; // On mobile, the autofocus brings up the keyboard on screen, which takes a ton of space. We don't want autofocus when there's text to read, but we do want it on a new chat.

    return <>
        <SelectableLLMServerDropdown/>
        <ChatListDropdown/><br/><br/>
        <Spin size='large' spinning={isWaitingForChat}>
        {selectedChatMessages.map((message:ChatMessage2, index) => {
            const isEditingThis = index === editingMessageIndex;
            if (message.role === 'user') {
                if (isEditingThis) {
                    return <div key={index}>{avatar}&nbsp;&nbsp;&nbsp;
                        <div style={{display:'inline-block', width:'calc(100% - 80px)',paddingTop:"10px"}}>
                            {USE_DRAFTJS_USER_INPUT && <OneLineDraftEditor
                                key={selectedChatIndex+"_"+index} // Necessary when switching chats to make sure the editor is reset.
                                onSubmit={(contentState:ContentState)=>{setCurrentUserMessageToContentState(contentState, true)}}
                                onChange={(contentState:ContentState)=>{setCurrentUserMessageToContentState(contentState, false)}}
                                autoFocus={autoFocus}
                                initialContent={message && message.draftRawContent && convertFromRaw(message.draftRawContent as RawDraftContentState)}
                            />}
                            {!USE_DRAFTJS_USER_INPUT && <TextArea
                                autoFocus={autoFocus}
                                autoSize
                                value={message.content as string}
                                style={{width:'100%',verticalAlign:"middle"}}
                                onChange={(e)=>{setCurrentUserMessageTo(e.target.value)}}
                                onPressEnter={()=>submitUserMessage(index)}
                                //   onBlur={()=>cancelEditingUserMessage(index)}
                                onFocus={(e)=>{
                                    // When autofocused at page load, set the cursor to the end of the text area.
                                    const element = e.currentTarget as HTMLTextAreaElement;
                                    element.setSelectionRange(element.value.length,element.value.length);
                                }}
                            />}
                        </div>
                    </div>;
                } else {
                    const canUseDraftJSViewer =  USE_DRAFTJS_USER_INPUT && message && message.draftRawContent;
                    
                    if (USE_DRAFTJS_USER_INPUT && !canUseDraftJSViewer) {
                        console.error("The message does not have the draft content -- this is a bug");
                    }
                    const isLastUserMessage = index===selectedChatMessages.length-1;
                    return <div key={index}>
                        {<Button type="text" key={index} className="chat-with-draft-user-message"
                            onClick={()=>{setEditingMessageIndex(index)}}>
                                {avatar}&nbsp;&nbsp;&nbsp;
                                <div style={{display:"inline-block", width: 'calc(100% - 100px)', verticalAlign: "top"}}>
                                    {canUseDraftJSViewer && <OneLineDraftEditor
                                        key={selectedChatIndex+"_"+index} // Necessary when switching chats to make sure the editor is reset.
                                        initialContent={message && message.draftRawContent && convertFromRaw(message.draftRawContent as RawDraftContentState)}
                                        onSubmit={(contentState:ContentState)=>{/*not submittable*/}}
                                        readOnly={true}
                                    />}
                                    {!canUseDraftJSViewer && <>{message.content as string}<br/><br/></>}
                                </div>
                        </Button>}
                        {isLastUserMessage && chatEditorRef.current.isWaitingForChat && <>
                            {chatEditorRef.current.isWaitingForChatTemplate && <center>1/4 Checking for templates</center>}
                            {!chatEditorRef.current.isWaitingForChatTemplate && chatEditorRef.current.isWaitingForChatTopics && <center>2/4 Checking for topics</center>}
                            {!chatEditorRef.current.isWaitingForChatTopics && <center>3/4 Loading notes</center>}
                            <br/>
                            <br ref={lastAssistantMessageRef}/>
                            </>
                        }
                        {!!message.extra?.noteIDsMentionedInChat?.length && <div className="chat-item-title-ref2">
                            Mentioned in chat: &nbsp;
                            {(uniq(message.extra.noteIDsMentionedInChat)).map((note_id:string,index:number)=>{
                                const note = notesContext.getLoadedNote(note_id,true);
                                if (!note) // it may still be loading.
                                    return <Tag color="#f50" key={note_id}>Loading</Tag>;
                                return <Tag color="blue" key={note_id} style={{cursor:"pointer"}} onClick={()=>{navigateToNote(note_id)}}>{getNoteNameAndEmoji(note, notesContext, extensions)}</Tag>;
                            })}
                        </div>}
                        {!!message.extra?.templateNoteIDs?.length && <div className="chat-item-title-ref2">
                            Detected template{message.extra.templateNoteIDs.length>1?"s":""}: &nbsp;
                            {/* @ts-ignore */}
                            {(uniq(message.extra.templateNoteIDs)).map((template_id:string,index:number)=>{
                                const template = notesContext.getLoadedNote(template_id,true);
                                if (!template) // it may still be loading.
                                    return <Tag color="#f50" key={template_id}>Loading</Tag>;
                                return <Tag color="blue" key={template_id} style={{cursor:"pointer"}} onClick={()=>{navigateToNote(template_id)}}>{getNoteNameAndEmoji(template, notesContext, extensions)}</Tag>;
                            })}
                        </div>}
                        {!!message.extra?.topicNoteIDs?.length && <div className="chat-item-title-ref2">
                            Possibly related topic{message.extra.topicNoteIDs.length>1?"s":""}: &nbsp;
                            {(uniq(message.extra.topicNoteIDs)).map((topic_id:string,index:number)=>{
                                const topic = notesContext.getLoadedNote(topic_id,true);
                                if (!topic) // it may still be loading.
                                    return <Tag color="#f50" key={topic_id}>Loading</Tag>;
                                return <Tag color="blue" key={topic_id} style={{cursor:"pointer"}} onClick={()=>{navigateToNote(topic_id)}}>{getNoteNameAndEmoji(topic, notesContext, extensions)}</Tag>;
                            })}
                        </div>}
                        {!!message.extra?.selectionNoteIDs?.length && <div className="chat-item-title-ref2">
                            Selected, pinned, or included: &nbsp;
                            {(uniq(message.extra.selectionNoteIDs)).map((selection_id:string,index:number)=>{
                                const selection = notesContext.getLoadedNote(selection_id,true);
                                if (!selection) // it may still be loading.
                                    return <Tag color="#f50" key={selection_id}>Loading</Tag>;
                                return <Tag color="blue" key={selection_id} style={{cursor:"pointer"}} onClick={()=>{navigateToNote(selection_id)}}>{getNoteNameAndEmoji(selection, notesContext, extensions)}</Tag>;
                            })}
                        </div>}
                        {!!message.extra?.noteIDsFromPreviousChats?.length && <div className="chat-item-title-ref2">
                            <Tooltip title={(uniq(message.extra.noteIDsFromPreviousChats)).map((topic_id:string,index:number)=>{
                                const topic = notesContext.getLoadedNote(topic_id,true);
                                if (!topic) // it may still be loading.
                                    return <Tag color="#f50" key={topic_id}>Loading</Tag>;
                                return <Tag color="blue" key={topic_id} style={{cursor:"pointer"}} onClick={()=>{navigateToNote(topic_id)}}>{getNoteNameAndEmoji(topic, notesContext, extensions)}</Tag>;
                            })}>
                                <Tag color='lime'>
                                    Includes {message.extra.noteIDsFromPreviousChats.length} notes listed above
                                </Tag>
                            </Tooltip>
                        </div>}

                        
                        {/* {message.extra && message.extra.noteIDs && message.extra.noteIDs.length>0 && <div className="chat-item-title-ref2">References:
                            {(uniq(message.extra.noteIDs) as string[]).map((note_id:string,index:number)=>{
                                const note = notesContext.getLoadedNote(note_id,true);
                                if (!note) // it may still be loading.
                                    return <div key={note_id}></div>;
                                return <Button className="chat-item-title-ref-button" key={note_id} type="link" onClick={()=>{navigateToNote(note_id)}}>{getNoteNameAndEmoji(note, notesContext, extensions)}</Button>;
                            })}
                        </div>} */}
                        {DEBUG_SHOW_UNUSED_NOTES && message.extra && !!message.extra?.templateNotMentionedNoteIDs?.length && !!message.extra?.topicNotMentionedNoteIDs?.length &&
                        <div style={{marginLeft:"40px"}}>
                        <Collapse accordion={true} defaultActiveKey={[]} style={{width:"calc(100% - 60px)"}} size='small'
                            items={[{
                                key: 'moreInfo',
                                label: "Notes that weren't included",
                                children: <div>
                                    {!!message.extra?.templateNotMentionedNoteIDs?.length && <>
                                        Template{message.extra.templateNotMentionedNoteIDs.length>1?"s":""} that weren't mentioned:&nbsp;
                                        {(uniq(message.extra.templateNotMentionedNoteIDs) as string[]).map((template_id:string,index:number)=>{
                                            const template = notesContext.getLoadedNote(template_id,true);
                                            if (!template) // it may still be loading.
                                                return <Tag color="#f50" key={template_id}>Loading</Tag>;
                                            return <Tag color="magenta" key={template_id} style={{cursor:"pointer"}} onClick={()=>{navigateToNote(template_id)}}>{getNoteNameAndEmoji(template, notesContext, extensions)}</Tag>;
                                        })}
                                    </>}
                                    <br/>
                                    {!!message.extra?.topicNotMentionedNoteIDs?.length && <>
                                        Topic{message.extra.topicNotMentionedNoteIDs.length>1?"s":""} that weren't mentioned:&nbsp;
                                        {(uniq(message.extra.topicNotMentionedNoteIDs) as string[]).map((topic_id:string,index:number)=>{
                                            const topic = notesContext.getLoadedNote(topic_id,true);
                                            if (!topic) // it may still be loading.
                                                return <Tag color="#f50" key={topic_id}>Loading</Tag>;
                                            return <Tag color="magenta" key={topic_id} style={{cursor:"pointer"}} onClick={()=>{navigateToNote(topic_id)}}>{getNoteNameAndEmoji(topic, notesContext, extensions)}</Tag>;
                                        })}
                                        Because: <i>{message.extra.outputOfTopicsThinking}</i>
                                    </>}
                                </div>
                            }]}/><br/></div>
                        }
                    </div>
                }
            }
            if (message.role === 'system' && DEBUG_SHOW_SYSTEM_PROMPT) {
                // Do we want to show these?
                // return <div key={index}>{message.content}<br/><br/></div>;
                return <div key={index}><SystemMessageViewer message={message}/><br/><br/></div>;
                // return <div key={index}></div>;
            }
            if (message.role === 'assistant') {
                const isLast = index===selectedChatMessages.length-1;
                const refIfLast = isLast?lastAssistantMessageRef:undefined;
                return <AssistantMessage key={index} message={message}
                        deleteSelectedMessage={()=>deleteMessage(index)}
                        setNextMessage={setNextMessage}
                        setMessage={(message:ChatMessage2)=>setMessage(index,message)}
                        editing={isEditingThis}
                        setEditing={(editing:boolean)=>setEditingMessageIndex(editing?index:-1)}
                        scrollToRef={refIfLast}
                        linkToNote={linkToNote}
                        extensions={extensions}
                    />;
            }
            // console.error("Corrupted message role: ", message.role);
            return <div key={index}>Loading? Unknown message role: {message.role}<br/><br/></div>;
        })}
        </Spin>
    </>;
}