import { useEffect, useState } from "react";
import { Note } from "../Notes/Data/NoteType";
import { getRef_NoteID } from "../Notes/Data/NoteDBHooks";
import { onSnapshot } from "firebase/firestore";
import { Input, Spin } from "antd";
import { FIREBASE_FIRESTORE } from "../AppBase/App";

export function FirestoreTestPage() {
    const [note_id, setNoteId] = useState<string>("06030f33-3a9c-447c-8d74-be262f7c7a9a"); // The battle note as of 12/2024. If this note is ever deleted, or if security is implemented without sharing it to all developers, we recommend updating this ID.    
    const [note, setNote] = useState<Note|null>(null);
    useEffect(() => {
        console.log("[FirestoreTestPage]>useEffect>Called -- assuming note ID is new, or has changed.");
        // The note has not loaded, make sure we aren't displaying it:
        if (note)
            setNote(null);
        if (!note_id) {
            return;
        }
        const noteRef = getRef_NoteID(FIREBASE_FIRESTORE, note_id);

        // BUG: The following onSnapshot is never returning. This means we never get server side changes, nor even the first update.
        // Not sure why.
        console.log("[FirestoreTestPage]>useEffect Starting to load note ",note_id," from server, using ", noteRef);
        const unsub = onSnapshot(noteRef,
            /*onNext*/(snapshot) => {
            console.log("[FirestoreTestPage]>onSnapshot got something from server for ",note_id," from server.");
            if (!snapshot.exists()) {
                console.log("[FirestoreTestPage]>onSnapshot Error, we tried to load a note id from the server that does not exist on the server.");
                return;
            }
            const loadedNote = snapshot.data() as Note;
            console.log("[FirestoreTestPage]>onSnapshot Current data: ", snapshot.data());
            setNote({...loadedNote, id:note_id});
        }, /*error*/(error) => {
            console.log("[FirestoreTestPage]>onSnapshot Error getting document:", error);
        }, /*onCompletion*/() => {
            console.log("[FirestoreTestPage]>onSnapshot Completed loading note ",note_id," from server.");
        });
        return () => {
            console.log("[FirestoreTestPage]>useEffect>cleanup: unsubscribing from old note_id ",note_id);
            unsub();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [note_id]);

    useEffect(()=>{
        console.log("[FirestoreTestPage]>useEffect2>note changed, got ",note);
    },[note]);

    return <>
        <h1>Firestore Test Page</h1>
        {note &&
            <div>
                Loaded note:<br/>
                Title: {note.doc_name}<br/>
                Version: {note.version}
            </div>
        }
        {!note && <Spin/>}
        <Input value={note_id} onChange={(e)=>{console.log("Note ID changed to ",e.target.value); setNoteId(e.target.value);}}/>
    </>
    
}